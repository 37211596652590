import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles/generalData';
import withSettings from '../../../utils/withSettings';
import CustomTitle from './CustomTitle';
import { NUMBER_LOOKUP_DATALIMIT_KEYS } from '../../../consts/initialValues';
import Optional from '../../../utils/optional';
import { mapValue } from '../actions/dataActions';
import checkNumberData from '../../../utils/checkNumberData';
import calculateMbToGb from '../../../utils/calculateMbToGb';
import DatalimitMenu from './DatalimitMenu';

function DatalimitData({
  classes,
  datalimitData = {},
  fromRightPanel,
  rightPanelData = {},
  intl
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: datalimitData,
      rightPanelData,
      fromRightPanel
    });
  }, [datalimitData, rightPanelData, fromRightPanel]);

  return (
    <div className={classes.root}>
      <CustomTitle
        title="NUMBER.DATALIMIT_DATA"
        isDataExist={isDataExist}
        dotsMenu={true}
        menuComponent={
          <DatalimitMenu datalimit={data} fromRightPanel={fromRightPanel}>
            <i className={clsx('fas fa-ellipsis-h', classes.dotsIcon)} />
          </DatalimitMenu>
        }
      >
        {isDataExist &&
          NUMBER_LOOKUP_DATALIMIT_KEYS.map((el, i) => {
            const value = mapValue(data[el.value]);
            const label = Optional(el.intlKey)
              .map(intlKey =>
                intl.formatMessage({
                  id: intlKey
                })
              )
              .or(el.value);

            switch (el.value) {
              case 'block_limit_mb': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{`${label} GB`}</div>

                    <div>{calculateMbToGb(Number(value), true)}</div>
                  </div>
                );
              }
              case 'fair_usage_limit': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{`${label} GB`}</div>

                    <div>{calculateMbToGb(Number(value), true)}</div>
                  </div>
                );
              }
              default:
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{value}</div>
                  </div>
                );
            }
          })}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.datalimitData,
    datalimitData: checkNumber.datalimitData
  };
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(DatalimitData);
