import React, { useState, cloneElement, useMemo, useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import Select from 'react-select';

import styles, {
  customStyles,
  customSelectStyles
} from '../styles/activityExpiration';
import PrimaryButton from '../../customButtons/PrimaryButton';
import SecondaryButton from '../../customButtons/SecondaryButton';
import CustomDialogTitle from '../../customDialogTitle/CustomDialogTitle';
import SelectRow from '../../dataRows/SelectRow';
import DraggableDialog from '../../DraggableDialog';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import InputRow from '../../dataRows/InputRow';
import {
  createDurationTypeOptions,
  trueFalseSelectOptions
} from '../../../consts/selectsOptions';
import { iconSelectsOptions } from '../actions/iconSelectsOptions';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import updateShortcut from '../actions/updateShortcut';
import validateSchema from '../actions/validateSchema';
import CheckboxRow from '../../dataRows/CheckboxRow';
import ConfirmDialog from '../../confirmationDialog/ConfirmDialog';
import CustomIconButton from '../../tableCustomButtons/CustomIconButton';
import deleteShortcut from '../actions/deleteShortcut';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

const EMPTY_SHORTCUT = {
  activityId: 'available',
  applyDiversionNumber: false,
  available: true,
  diversionPhoneNumber: '',
  durationMinutes: 0,
  durationType: 'MINUTES',
  imageId: 'ic_shortcut_big_green',
  name: ''
};

export function EditShortcuts({
  closeDialog,
  classes,
  intl,
  children,
  activities,
  shortcuts,
  setShortcuts,
  settings,
  closeMenuOnDialogOpen = () => {}
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(shortcuts[0]);
  const [isEdit, setIsEdit] = useState(true);

  const onOpenDialog = () => {
    closeMenuOnDialogOpen();
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirm = async values => {
    const responseShortcut = await updateShortcut(values, isEdit);

    if (isEdit) {
      setShortcuts(
        shortcuts.map(shortcut => {
          if (shortcut.id === responseShortcut.id) {
            return responseShortcut;
          } else {
            return shortcut;
          }
        })
      );
    } else {
      setShortcuts([...shortcuts, responseShortcut]);
      setIsEdit(true);
    }

    return setSelected(responseShortcut);
  };

  const handleShortcutChange = selected => {
    setSelected(selected);
  };

  const availabilityOptions = useMemo(() => {
    return trueFalseSelectOptions({
      trueLabel: intl.formatMessage({
        id: 'AVAILABLE'
      }),
      falseLabel: intl.formatMessage({
        id: 'BUSY'
      })
    });
  }, [intl]);

  const durationTypeOptions = useMemo(() => {
    return createDurationTypeOptions();
  }, []);

  const iconOptions = useMemo(() => {
    return iconSelectsOptions();
  }, []);

  const onConfirmDeleteClick = useCallback(async () => {
    const isDeleted = await deleteShortcut(selected.id);

    if (isDeleted) {
      const filteredShortcuts = shortcuts.filter(
        shortcut => shortcut.id !== selected.id
      );
      setSelected(Optional(filteredShortcuts[0]).or(''));
      setShortcuts(filteredShortcuts);
    }
    // eslint-disable-next-line
  }, [selected.id]);

  const onAddClick = () => {
    setIsEdit(false);
    setSelected(EMPTY_SHORTCUT);
  };

  const onCreateCancel = useCallback(async () => {
    setIsEdit(true);
    setSelected(shortcuts[0]);

    // eslint-disable-next-line
  }, [shortcuts]);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      {open && (
        <Dialog
          maxWidth="md"
          classes={{
            paper: classes.root
          }}
          PaperComponent={DraggableDialog}
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              onCloseDialog();
            }
          }}
        >
          <CustomDialogTitle onCloseClick={onCloseDialog}>
            {intl.formatMessage({
              id: 'USER.EDIT_SHORTCUTS'
            })}
          </CustomDialogTitle>
          <div className={classes.shortcutSelectContainer}>
            <div className={classes.shortcutSelectLabel}>
              {isEdit
                ? intl.formatMessage({
                    id: 'USER.SELECT_SHORTCUT'
                  })
                : intl.formatMessage({
                    id: 'USER.CREATE_SHORTCUT'
                  })}
              <Tooltip
                title={intl.formatMessage({
                  id: 'USER.ADD_NEW_SHORTCUT'
                })}
              >
                <div
                  onClick={onAddClick}
                  className={classes.addButton}
                  style={{
                    backgroundColor: settings.colors.buttons,
                    color: settings.colors.buttons_text,
                    visibility: isEdit ? 'visible' : 'hidden'
                  }}
                >
                  <i className={'fas fa-plus'}></i>
                </div>
              </Tooltip>
            </div>
            <div
              className={classes.shortcutSelectRow}
              style={{ visibility: isEdit ? 'visible' : 'hidden' }}
            >
              <Select
                className={classes.fieldValue}
                styles={reactSelectDefaultStyles}
                customStyles={customSelectStyles}
                options={shortcuts}
                value={selected}
                onChange={handleShortcutChange}
              />
              <ConfirmDialog
                onConfirmClick={onConfirmDeleteClick}
                dialogTitleIntlId={'TOOLTIP.DELETE_SELECTED_SHORTCUT'}
                dialogTextIntlId={'USER.DELETE_SHORTCUT_TEXT'}
              >
                <CustomIconButton
                  iconTooltipIntlKey="TOOLTIP.DELETE_SELECTED_SHORTCUT"
                  iconClassName="far fa-trash-alt"
                  customStyles={{ paddingTop: '10px' }}
                />
              </ConfirmDialog>
            </div>
          </div>
          <Formik
            initialValues={{
              ...selected,
              durationMinutes: selected?.durationMinutes?.toString() || ''
            }}
            onSubmit={handleConfirm}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize
            validationSchema={validateSchema()}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              handleBlur,
              handleChange,
              dirty
            }) => {
              const fieldProps = {
                setFieldValue,
                values,
                handleBlur,
                handleChange,
                columnDirection: true
              };

              return (
                <Form className={classes.dialogContent} onSubmit={handleSubmit}>
                  <InputRow
                    label="NAME"
                    name="name"
                    customStyles={customStyles.inputRow}
                    {...fieldProps}
                  />
                  <SelectRow
                    name="activityId"
                    label="ACTIVITY"
                    options={activities}
                    isEditModal={false}
                    customStyles={customStyles.selectRow}
                    {...fieldProps}
                  />
                  <SelectRow
                    name="available"
                    label="AVAILABILITY"
                    options={availabilityOptions}
                    isEditModal={false}
                    customStyles={customStyles.selectRow}
                    {...fieldProps}
                  />
                  <SelectRow
                    name="durationType"
                    label="SCHEDULES.DURATION"
                    options={durationTypeOptions}
                    isEditModal={false}
                    customStyles={customStyles.selectRow}
                    {...fieldProps}
                  />
                  <InputRow
                    name="durationMinutes"
                    labelText={capitaliseFirstLetter(
                      intl.formatMessage({
                        id: 'MINUTES'
                      })
                    )}
                    customStyles={customStyles.inputRow}
                    disabled={values.durationType !== 'MINUTES'}
                    fieldType="number"
                    maxValue={1000}
                    {...fieldProps}
                  />
                  <CheckboxRow
                    label="NUMBERS.FORWARD_NUMBERS"
                    name="applyDiversionNumber"
                    customStyles={customStyles.checkboxRow}
                    {...fieldProps}
                  />
                  <InputRow
                    label="TABLE.NUMBER"
                    name="diversionPhoneNumber"
                    customStyles={customStyles.inputRow}
                    disabled={!values.applyDiversionNumber}
                    {...fieldProps}
                  />
                  <SelectRow
                    name="imageId"
                    label="ICON"
                    options={iconOptions}
                    isEditModal={false}
                    customStyles={{
                      ...customStyles.selectRow,
                      ...customStyles.iconSelect
                    }}
                    menuPlacement='top'
                    {...fieldProps}
                  />

                  <footer className={classes.footer}>
                    <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
                      {isEdit
                        ? intl.formatMessage({
                            id: 'BUTTON.CONFIRM'
                          })
                        : intl.formatMessage({
                            id: 'BUTTON.ADD'
                          })}
                    </PrimaryButton>
                    <SecondaryButton
                      onClick={isEdit ? onCloseDialog : onCreateCancel}
                    >
                      {intl.formatMessage({
                        id: 'BUTTON.CANCEL'
                      })}
                    </SecondaryButton>
                  </footer>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(EditShortcuts);
