import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_LICENSES = 'SET_LICENSES';
const RESET_LICENSES = 'RESET_LICENSES';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('LICENSES', [
  SET_LICENSES,
  RESET_LICENSES,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  licenses: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_LICENSES]: {
      return { ...state, licenses: [...action.licenses] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_LICENSES]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setLicenses: licenses => ({
    type: actionTypes[SET_LICENSES],
    licenses
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetPermissions: () => ({ type: actionTypes[RESET_LICENSES] })
};
