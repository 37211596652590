import React from 'react';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { translate } from '../../../i18n/I18nProvider';

const ActionsButtons = ({ onCloseClick, handleSubmit, dirty }) => {
  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {translate('BUTTON.SAVE')}
      </PrimaryButton>
      <ConfirmDialog
        onConfirmClick={onCloseClick}
        dialogTextIntlId={'NOT_SAVED'}
        dialogTitleIntlId={'WARNING'}
        valueToCheck={dirty}
        isWarning={true}
      >
        <SecondaryButton>{translate('BUTTON.CANCEL')}</SecondaryButton>
      </ConfirmDialog>
    </>
  );
};

export default ActionsButtons;
