const styles = theme => {
  return {};
};

export default styles;

export const CUSTOM_STYLES = {
  checkboxRow: {
    root: { width: '350px', alignItems: 'unset', paddingLeft: '15px' },
    label: { width: 'fit-content' }
  },
  textFieldRow: {
    label: {
      width: '195px'
    },
    root: {
      width: '650px'
    },
    textfield: {
      width: '410px'
    }
  }
};
