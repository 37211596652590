import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import { getConnections } from './actions/getConnections';
import ConnectionsTable from './components/ConnectionsTable';
import SelectAccount from './components/SelectAccount';
import styles from './styles';
import { actions as reduxActions } from './reducers';

const Connections = ({
  getConnections,
  clientId,
  classes,
  resetConnections
}) => {
  useEffect(() => {
    getConnections({ clientId });

    return resetConnections;
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <div>
      <div className={classes.root}>
        <SelectAccount />
      </div>
      <ConnectionsTable />
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  resetConnections: reduxActions.resetConnections,
  getConnections,
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Connections);
