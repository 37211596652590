import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import styles from '../styles/vacations';
import { isDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';

const Vacations = ({
  classes,
  arrayHelpers: {
    form: {
      values: { vacations = [] }
    },
    remove,
    push
  },
  options
}) => {
  const availableOptions = useMemo(
    () => [...mapOptions(options, vacations)],
    // eslint-disable-next-line
    [options, vacations]
  );

  return (
    <div>
      <div className={classes.label}>{translate('MENU.VACATIONS')}</div>
      <div className={classes.vacationsRoot}>
        <div className={classes.groupColumn}>
          <div
            className={clsx(classes.rolesContainer, classes.vacationsContainer)}
          >
            {vacations.map((vacation, i) => (
              <div className={classes.roleContainer} key={vacation.id}>
                {vacation.label}
                <i
                  className={clsx('far fa-times-circle', classes.icon)}
                  onClick={() => remove(i)}
                ></i>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.groupColumn}>
          <div
            className={clsx(classes.rolesContainer, classes.vacationsContainer)}
          >
            {availableOptions
              .filter(vacation => !vacation.isVacationSelected)
              .map(vacation => (
                <div className={classes.roleContainer} key={vacation.id}>
                  <i
                    className={clsx('far fa-chevron-double-left', classes.icon)}
                    onClick={() => push(vacation)}
                  ></i>
                  {vacation.label}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Vacations);

const mapOptions = (options, vacations) => {
  return options.map(option => {
    const isVacationSelected = vacations.find(
      vacation => vacation.label === option.label
    );
    return { ...option, isVacationSelected: isDefined(isVacationSelected) };
  });
};
