import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import checkNumberData from '../../../utils/checkNumberData';
import styles from '../styles/userData';
import CustomTitle from './CustomTitle';
import { createValueLabel, mapValue } from '../actions/dataActions';

function UserData({ classes, user = {}, fromRightPanel, rightPanelData = {} }) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: user,
      rightPanelData,
      fromRightPanel
    });
  }, [user, rightPanelData, fromRightPanel]);
 
  return (
    <div className={classes.root}>
      <CustomTitle title="NUMBER.TELEPHONY_FUNCTION" isDataExist={isDataExist}>
        {isDataExist &&
          Object.keys(data).map((key, i) => {
            const value = mapValue(data[key]);
            const label = createValueLabel(key);
            return (
              <div
                className={classes.dataContainer}
                key={i}
                style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA' }}
              >
                <div className={classes.dataKey}>{label}</div>
                <div>{value}</div>
              </div>
            );
          })}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.user,
    user: checkNumber.user
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(UserData);
