const styles = theme => {
  return {
    root: {
      width: '280px',
      paddingTop: '20px'
    },
    selectContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    label: {
      fontWeight: 500,
      paddingLeft: '30px'
    },
    rolesContainer: {
      border: '1px solid #B3B3B3',
      borderRadius: '4px',
      width: '100%',
      height: '100px',
      overflowX: 'auto',
      marginBottom: '25px'
    },
    roleContainer: {
      margin: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ' & i': {
        color: '#B3B3B3'
      }
    },
    fieldValue: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '11px',
      fontWeight: 'normal',
      borderRadius: '4px',
      margin: '5px 0 14px',
      paddingTop: '8px',
      width: '200px',
      '& p': {
        margin: '2px 0'
      }
    },
    addButton: {
      color: '#fff',
      backgroundColor: '#5D78FF',
      '&:hover': { backgroundColor: '#0ABB87' }
    },
    vacationsRoot: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginTop: '5px',
      height: '150px',
      [theme.breakpoints.down(theme.breakpoints.values.md - 100)]: {
        flexDirection: 'column',
        alignItems: 'center',
        height: 'unset'
      }
    },
    vacationsContainer: {
      width: '250px',
      height: '140px',
      margin: '10px 10px 0 0 '
    },
    icon: { cursor: 'pointer' },
    groupColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    customRolesRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '355px'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      padding: '20px 0',
      marginTop: '60px',
      '& button': {
        marginRight: '10px'
      }
    }
  };
};

export const customSelectStyles = {
  option: { fontSize: '12px' },
  control: {
    width: '200px'
  }
};

export default styles;
