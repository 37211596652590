import React from 'react';

import searchInArray from '../../../utils/searchInArray';
import customTableColumnSort from '../../../utils/customTableColumnSort';
import StatusCell from '../../../partials/statusCell/StatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import searchNumbers from '../../../utils/searchNumbers';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import TableNameCell from '../../../partials/tableNameCell/TableNameCell';
import findDatalimitOwner from '../../../utils/findDataLimitOwner';
import TableActionButtons from './TableActionButtons';
import styles from '../styles';
import {
  mapOwners,
  mapCellNumbersToFilter,
  mapCellNumbersToDisplay
} from '../actions/tableColumnsActions';
import CellNumbersTableCell from '../../../partials/CellNumbersTableCell/CellNumbersTableCell';
import calculateMbToGb from '../../../utils/calculateMbToGb';
import formatDate, { formatDateForSchedule } from '../../../utils/formatDate';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';
import removeWhiteSpaces from '../../../utils/removeWhiteSpaces';

export default function tableColumns({ clientId }) {
  const columns = [
    {
      title: 'TABLE.ACTIONS',
      render: rowData => <TableActionButtons data={rowData} />,
      cellStyle: styles.actionCell,
      disableClick: true,
      sorting: false,
      filtering: false,
      align: 'center',
      width: '40px'
    },
    {
      title: 'TABLE.NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: 'CELL_NUMBERS',
      render: rowData => (
        <CellNumbersTableCell
          data={rowData}
          mapNumbers={mapCellNumbersToDisplay}
          showMore={true}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: mapCellNumbersToFilter(rowData),
          term: removeWhiteSpaces(term),
          isValueNumber: true
        }),
      align: 'right',
      field: 'cell_numbers',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED,
      exportTransformer: rowData => mapCellNumbersToDisplay(rowData).join(', ')
    },
    {
      title: 'EDIT.USER',
      render: rowData => (
        <TableNameCell
          data={[...findDatalimitOwner(rowData)]}
          clientId={clientId}
          from="dataLimits"
          showMore={true}
          userDialogName={rowData.name}
        />
      ),
      exportTransformer: rowData =>
        [...findDatalimitOwner(rowData)]
          .map(user => Optional(user.name).or(null))
          .filter(name => isDefined(name))
          .join(','),
      field: 'cell_numbers',
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: mapOwners(rowData),
          term
        }),
      customSort: (a, b) =>
        customTableColumnSort(
          findDatalimitOwner(b)[0],
          findDatalimitOwner(a)[0],
          'name'
        ),
      disableClick: true
    },
    {
      title: 'STATUS',
      field: 'status',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.DATA_LIMITS,
            rowData.status
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.DATA_LIMITS)
      }
    },
    {
      title: 'DATA_LIMITS.DATA_LIMIT',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.block_limit_mb, term);
      },
      render: rowData => (
        <div>
          {rowData.no_limit
            ? translate('DATA_LIMITS.NO_DATALIMIT')
            : calculateMbToGb(rowData.block_limit_mb, true)}
        </div>
      ),
      exportTransformer: rowData =>
        rowData.no_limit
          ? translate('DATA_LIMITS.NO_DATALIMIT')
          : calculateMbToGb(rowData.block_limit_mb, true),
      field: 'block_limit_mb',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'USAGE_GB',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.currentMonthUsage.total_data_string, term);
      },
      field: 'currentMonthUsage.total_data_string',
      customSort: (a, b) =>
        customTableColumnSort(a, b, 'currentMonthUsage.total_data'),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'USAGE_PR',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          rowData.currentMonthUsage.totalPercentageString,
          term
        );
      },
      field: 'currentMonthUsage.totalPercentageString',
      customSort: (a, b) =>
        customTableColumnSort(a, b, 'currentMonthUsage.totalPercentage'),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'DATA_LIMITS.ROAMING_DISABLED',
      field: 'roaming_disabled',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.roaming_disabled
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      }
    },
    {
      title: 'DATA_LIMITS.ROAMING_EU_LIMIT',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.fair_usage_limit, term);
      },
      render: rowData => (
        <div>
          {rowData.no_limit
            ? translate('DATA_LIMITS.NO_DATALIMIT')
            : calculateMbToGb(rowData.fair_usage_limit, true)}
        </div>
      ),
      exportTransformer: rowData =>
        rowData.no_limit
          ? translate('DATA_LIMITS.NO_DATALIMIT')
          : calculateMbToGb(rowData.fair_usage_limit, true),
      field: 'fair_usage_limit',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'DATA_LIMITS.ROAMING_EU_GB',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.currentMonthUsage.roamString, term);
      },
      field: 'currentMonthUsage.roamString',
      customSort: (a, b) =>
        customTableColumnSort(a, b, 'currentMonthUsage.roamString'),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'DATA_LIMITS.ROAMING_EU_USAGE',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          rowData.currentMonthUsage.roamPercentageString,
          term
        );
      },
      field: 'currentMonthUsage.roamPercentageString',
      customSort: (a, b) =>
        customTableColumnSort(a, b, 'currentMonthUsage.roamPercentage'),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'DATA_LIMITS.ROAMING_WORLD',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          rowData.currentMonthUsage.data_roam_others_string,
          term
        );
      },
      field: 'currentMonthUsage.data_roam_others_string',
      customSort: (a, b) =>
        customTableColumnSort(
          a,
          b,
          'currentMonthUsage.data_roam_others_string'
        ),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'DATA_LIMITS.AUTO_RECHARGE',
      field: 'allow_recharge',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.allow_recharge
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      }
    },
    {
      title: 'CLIENT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.client.name, term);
      },
      field: 'client.name',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.REPORT_EMAIL',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.report_to_email, term);
      },
      field: 'report_to_email',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.REPORT_SMS',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.report_to_sms, term);
      },
      field: 'report_to_sms',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.REPORT_TO',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.report_only_to, term);
      },
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.report_only_to
          )}
        />
      ),
      field: 'report_only_to',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.MAX_NO',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.max_numbers, term);
      },
      field: 'max_numbers',
      hidden: true
    },
    {
      title: 'TMP open',
      field: 'tmp_open',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.tmp_open
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      hidden: true,
      untranslatedTitle: true
    },
    {
      title: 'Manual block',
      field: 'manual_block',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.manual_block
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      hidden: true,
      untranslatedTitle: true
    },
    {
      title: 'No limit',
      field: 'no_limit',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.no_limit
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      hidden: true,
      untranslatedTitle: true
    },
    {
      title: 'DATA_LIMITS.FUTURE_LIMIT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.future_limit, term);
      },
      render: rowData => (
        <div>
          {rowData.no_limit
            ? translate('DATA_LIMITS.NO_DATALIMIT')
            : calculateMbToGb(rowData.future_limit, true)}
        </div>
      ),
      exportTransformer: rowData =>
        rowData.no_limit
          ? translate('DATA_LIMITS.NO_DATALIMIT')
          : calculateMbToGb(rowData.future_limit, true),
      field: 'future_limit',
      align: 'right',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.FUTUR_LIMIT_AT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.future_limit_at, term);
      },
      field: 'future_limit_at',
      render: rowData => (
        <div>{formatDateForSchedule(rowData.future_limit_at)}</div>
      ),
      hidden: true
    },
    {
      title: 'DATA_LIMITS.WARNED',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.warned_at, term);
      },
      render: rowData => <div>{formatDate(rowData.warned_at)}</div>,
      exportTransformer: rowData => formatDate(rowData.warned_at),
      field: 'warned_at',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.BLOCKED',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.blocked_at, term);
      },
      render: rowData => <div>{formatDate(rowData.blocked_at)}</div>,
      exportTransformer: rowData => formatDate(rowData.blocked_at),
      field: 'blocked_at',
      hidden: true
    },
    {
      title: 'Update MDU',
      field: 'update_mdu',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.update_mdu
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      hidden: true,
      untranslatedTitle: true
    },
    {
      title: 'DATA_LIMITS.RECHARGED_AT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.recharged_at, term);
      },
      render: rowData => <div>{formatDate(rowData.recharged_at)}</div>,
      exportTransformer: rowData => formatDate(rowData.recharged_at),
      field: 'recharged_at',
      hidden: true
    },
    {
      title: 'DATA_LIMITS.RECHARGE_COUNT',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.recharge_count, term);
      },
      exportTransformer: rowData =>
        rowData.allow_recharge ? `${rowData.recharge_count} / 4` : '',
      field: 'recharge_count',
      render: rowData => (
        <div>
          {rowData.allow_recharge ? `${rowData.recharge_count} / 4` : ''}
        </div>
      ),
      customSort: (a, b) => customTableColumnSort(a, b, 'recharge_count')
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      exportTransformer: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      exportTransformer: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (
    can('manage', createSubject('DataLimit', { client_id: Number(clientId) }))
  ) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',

        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
