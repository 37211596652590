import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Formik, Form } from 'formik';

import { actions as reduxActions } from './reducers';
import styles, { customStyles } from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import InputRow from '../../partials/dataRows/InputRow';
import ActionsButtons from './components/ActionsButtons';
import validateSchema from './actions/validateSchema';
import editVacation from './actions/editVacation';
import { translate } from '../../i18n/I18nProvider';
import SelectRow from '../../partials/dataRows/SelectRow';
import SELECT_OPTIONS from '../../consts/selectsOptions';
import translateOptionslLabels from '../../utils/translateOptionsLabels';
import getVacations from '../../pages/vacations/actions/getVacations';

const EditVacation = ({
  classes,
  open,
  closeEditVacation,
  isEdit,
  vacation,
  getVacations
}) => {
  const onSubmit = useCallback(
    async values => {
      const requestDone = await editVacation({
        values,
        isEdit,
        id: vacation.id
      });

      if (requestDone) {
        getVacations();
        return closeEditVacation();
      }
    },
    // eslint-disable-next-line
    [isEdit, vacation.id]
  );

  const monthsOptions = useMemo(() => {
    return translateOptionslLabels(SELECT_OPTIONS.VACATION_MONTHS);
  }, []);

  const typeOptions = useMemo(() => {
    return translateOptionslLabels(SELECT_OPTIONS.VACATIONS_TYPE);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEditVacation();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeEditVacation}>
        {translate(
          isEdit ? 'VACATIONS.EDIT_VACATION' : 'VACATIONS.ADD_VACATION'
        )}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={
            isEdit
              ? vacation
              : {
                  holiday: '',
                  holiday_type: 'Single',
                  date: '',
                  month: 'jan'
                }
          }
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={validateSchema}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            dirty,
            setFieldValue
          }) => {
            const fieldProps = { errors, values, handleBlur, handleChange };
            return (
              <Form className={classes.content} onSubmit={handleSubmit}>
                <InputRow
                  label="TABLE.NAME"
                  name="holiday"
                  customStyles={customStyles.inputRow}
                  {...fieldProps}
                />
                <SelectRow
                  name="holiday_type"
                  label="TABLE.TYPE"
                  customStyles={customStyles.typeSelect}
                  options={typeOptions}
                  isEditModal={false}
                  setFieldValue={setFieldValue}
                  {...fieldProps}
                />
                <div className={classes.dateContainer}>
                  <InputRow
                    label="DATE"
                    name="date"
                    customStyles={customStyles.dayRow}
                    {...fieldProps}
                  />
                  <SelectRow
                    name="month"
                    label="MONTH"
                    customStyles={customStyles.monthSelect}
                    options={monthsOptions}
                    isEditModal={false}
                    setFieldValue={setFieldValue}
                    {...fieldProps}
                  />
                </div>

                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={closeEditVacation}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ editVacation }) => {
  return {
    ...editVacation
  };
};

const mapDispatchToProps = {
  closeEditVacation: reduxActions.closeEditVacation,
  editVacation,
  getVacations
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditVacation);
