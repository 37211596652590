import { JSONAPI_URLS } from "../consts/endpoints";
import getCount from "../pages/clientDashboard/actions/getCount";


const checkIfSimcardsShouldBeShown = async selectedClientId => {
    const simCardsCount = await getCount({
      url: JSONAPI_URLS.SIM_CARDS,
      selectedClientId
    });
  
    if (simCardsCount === '-' || simCardsCount === 0) {
      const numberRangesCount = await getCount({
        url: JSONAPI_URLS.EXTERNAL_NO_RANGES,
        selectedClientId
      });
  
      return numberRangesCount !== '-' && numberRangesCount > 0;
    }
  
    return true
  };

  export default checkIfSimcardsShouldBeShown
  