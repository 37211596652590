import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import withDetailsView from '../../../utils/withDetailsView';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import { doesArrayHasLength } from '../../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import { actions as datalimitMultieditReduxActions } from '../../../modals/datalimitMultiedit/reducers';
import styles from '../styles';
import { getDataLimits } from '../actions/getDataLimits';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import { Link } from 'react-router-dom';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';

export function DataLimitsTable({
  intl,
  dataLimits,
  isLoading,
  clientId,
  columns,
  setColumns,
  openMultiedit,
  classes,
  month,
  getDataLimits,
  clientName,
  customJobs
}) {
  const [filtering, setFiltering] = useState(false);
  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ clientId });
  }, [clientId]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Datalimits-2'
    });
    getDataLimits(clientId, month);
  }, [setColumns, tableDefaultColumns, getDataLimits, month, clientId]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'DataLimits');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        },
        {
          icon: () => (
            <Link
              to={{
                pathname: `/${clientId}/dataLimits/usageTable`
              }}
            >
              <i
                className="fas fa-chart-area"
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
              ></i>
            </Link>
          ),
          onClick: () => {},
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'DATA_LIMITS.USAGE_TABLE' })
        },
        {
          tooltip: intl.formatMessage({ id: 'BUTTON.MULTIEDIT' }),
          icon: () => <CustomIconButton iconClassName="far fa-edit" />,
          onClick: (evt, data) => openMultiedit(data)
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs, clientId, dataLimits]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ tableName: 'datalimits', clientName }),
      filtering,
      header: doesArrayHasLength(dataLimits),
      selection: true
    };
  }, [filtering, dataLimits, clientName]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Datalimits-2'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MaterialTable
      components={{
        Toolbar: props => (
          <MTableToolbar
            classes={{
              highlight: classes.toolbarHighlight
            }}
            {...props}
          />
        )
      }}
      title={
        <CustomTableTitle
          text="MENU.DATA_LIMITS"
          icon={<i className="fas fa-chart-bar"></i>}
          isLoading={isLoading}
        ></CustomTableTitle>
      }
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={dataLimits}
      options={options}
      localization={localization}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ dataLimits, loader, selectClient, auth }) => {
  return {
    ...dataLimits,
    isLoading: loader.isSpinVisible,
    clientId: selectClient.selectedClient.id,
    clientName: selectClient.selectedClient.name,
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns,
  openMultiedit: datalimitMultieditReduxActions.openDialog,
  getDataLimits
};

export default flowRight(
  injectIntl,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DataLimitsTable);
