import React from 'react';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { injectIntl } from 'react-intl';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';

import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import CustomDashboardButton from './CustomDashboardButton';
import styles from '../styles';
import { JSONAPI_URLS, OUTSIDE_API } from '../../../consts/endpoints';
import withUserCheck from '../../../utils/withUserCheck';
import withSettings from '../../../utils/withSettings';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import ExpandIcon from './ExpandIcon';

function GroupedButtons({
  selectedClientId,
  userHasQueues,
  isDomainProvided,
  connectDomain,
  hasCientSimcards,
  isSuperAdmin,
  classes,
  intl,
  isMitelTokenProvided,
  settings,
  hasClientAccounts
}) {
  return (
    <div className={classes.container}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          classes={{ content: classes.accordionSummary }}
          expandIcon={<ExpandIcon />}
        >
          <i
            className={clsx('fad fa-user', classes.accordionIcon)}
            style={{
              color: settings.colors.dashboard_icon
            }}
          ></i>
          <div>
            {intl.formatMessage({
              id: 'MENU.PROFILE'
            })}
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <div className={classes.buttonsContainer}>
            <CustomDashboardButton
              linkTo={`/user/userProfile`}
              label={intl.formatMessage({
                id: 'MENU.USER_PROFILE'
              })}
              icon="fad fa-id-card"
              counter={false}
            ></CustomDashboardButton>
            {isMitelTokenProvided && (
              <CustomDashboardButton
                linkTo={`/user/userContacts`}
                label={intl.formatMessage({
                  id: 'MENU.USER_CONTACTS'
                })}
                icon="fad fa-address-book"
                counter={false}
              ></CustomDashboardButton>
            )}
            <CustomDashboardButton
              linkTo={`/admin/userSettings`}
              label={intl.formatMessage({
                id: 'MENU.USER_SETTINGS'
              })}
              icon="fad fa-user-cog"
              counter={false}
            ></CustomDashboardButton>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          classes={{ content: classes.accordionSummary }}
          expandIcon={<ExpandIcon />}
        >
          <i
            className={clsx('fad fa-phone', classes.accordionIcon)}
            style={{
              color: settings.colors.dashboard_icon
            }}
          ></i>
          <div>
            {intl.formatMessage({
              id: 'MENU.TELEPHONY'
            })}
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <div className={classes.buttonsContainer}>
            {can(
              'read',
              createSubject('SimCard', {
                client_id: Number(selectedClientId)
              })
            ) && (
              <CustomDashboardButton
                linkTo={`/${selectedClientId}/simCards`}
                label={intl.formatMessage({
                  id: 'MENU.SIM_CARDS'
                })}
                icon="fad fa-sim-card"
                url={JSONAPI_URLS.SIM_CARDS}
                hideWhenCountIsZero={true}
                additionalRequest={`/${selectedClientId}/externalNumber`}
              ></CustomDashboardButton>
            )}
            {can(
              'read',
              createSubject('ExternalNumberRange', {
                client_id: Number(selectedClientId)
              })
            ) && (
              <CustomDashboardButton
                linkTo={`/${selectedClientId}/externalNumber`}
                label={intl.formatMessage({
                  id: 'MENU.NUMBER_RANGE'
                })}
                icon="fad fa-th-list"
                url={JSONAPI_URLS.EXTERNAL_NO_RANGES}
              ></CustomDashboardButton>
            )}
            {isDomainProvided && userHasQueues && (
              <CustomDashboardButton
                linkTo={`/${selectedClientId}/queues`}
                label={intl.formatMessage({
                  id: 'MENU.QUEUES'
                })}
                icon="fad fa-layer-group"
                counter={false}
                connectCounter={true}
                url={`${OUTSIDE_API.CLIENTS}/${connectDomain}/${OUTSIDE_API.DISTGROUP_STATS}`}
              ></CustomDashboardButton>
            )}
            {isDomainProvided &&
              can(
                'read',
                createSubject('Mitel::Schedule', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/schedules`}
                  label={intl.formatMessage({
                    id: 'MENU.SCHEDULES'
                  })}
                  icon="fad fa-calendar-alt"
                  counter={false}
                  connectCounter={true}
                  url={`${OUTSIDE_API.CLIENTS}/${connectDomain}/schedules`}
                ></CustomDashboardButton>
              )}
            {can(
              'read',
              createSubject('DataLimit', {
                client_id: Number(selectedClientId)
              })
            ) &&
              hasCientSimcards && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/dataLimits`}
                  label={intl.formatMessage({
                    id: 'MENU.DATA_LIMITS'
                  })}
                  icon="fad fa-chart-bar"
                  url={JSONAPI_URLS.DATA_LIMITS}
                  hideWhenCountIsZero={true}
                ></CustomDashboardButton>
              )}

            {isDomainProvided &&
              can(
                'read',
                createSubject('User', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/telephonyUsers`}
                  label={intl.formatMessage({
                    id: 'MENU.TELEPHONY_USERS'
                  })}
                  icon="fad fa-user-friends"
                  counter={false}
                  connectCounter={true}
                  url={`${OUTSIDE_API.CLIENTS}/${connectDomain}/users`}
                ></CustomDashboardButton>
              )}
              {can(
                'read',
                createSubject('Mitel::UserGroup', {
                  client_id: Number(selectedClientId)
                })
              ) &&
                isDomainProvided && (
                  <CustomDashboardButton
                    linkTo={`/${selectedClientId}/groups`}
                    label={intl.formatMessage({
                      id: 'MENU.GROUPS'
                    })}
                    icon="fad fa-users"
                    counter={false}
                  ></CustomDashboardButton>
                )}
          </div>
        </AccordionDetails>
      </Accordion>
      {((hasClientAccounts &&
        (can(
          'read',
          createSubject('Quote::Invoice', {
            client_id: Number(selectedClientId)
          })
        ) ||
          can(
            'read',
            createSubject('Quote::Subscription', {
              client_id: Number(selectedClientId)
            })
          ))) ||
        can(
          'read',
          createSubject('Quote::Connection', {
            client_id: Number(selectedClientId)
          })
        )) && (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            classes={{ content: classes.accordionSummary }}
            expandIcon={<ExpandIcon />}
          >
            <i
              className={clsx('fa fa-hand-pointer', classes.accordionIcon)}
              style={{
                color: settings.colors.dashboard_icon
              }}
            ></i>
            <div>
              {intl.formatMessage({
                id: 'MENU.ADMINISTRATION'
              })}
            </div>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <div className={classes.buttonsContainer}>
              {can(
                'read',
                createSubject('Quote::Invoice', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/invoices`}
                  label={intl.formatMessage({
                    id: 'MENU.INVOICES'
                  })}
                  icon="fad fa-file-invoice-dollar"
                  counter={false}
                ></CustomDashboardButton>
              )}
              {can(
                'read',
                createSubject('Quote::Subscription', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/subscriptions`}
                  label={intl.formatMessage({
                    id: 'MENU.SUBSCRIPTIONS'
                  })}
                  icon="fad fa-business-time"
                  counter={false}
                ></CustomDashboardButton>
              )}
              {can(
                'read',
                createSubject('Quote::Connection', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/network_connections`}
                  label={intl.formatMessage({
                    id: 'MENU.NETWORK_CONNECTIONS'
                  })}
                  icon="fad fa-network-wired"
                  counter={false}
                ></CustomDashboardButton>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      {(((!isDomainProvided || isSuperAdmin) &&
        can(
          'read',
          createSubject('User', {
            client_id: Number(selectedClientId)
          })
        )) ||
        can(
          'read',
          createSubject('Address', {
            client_id: Number(selectedClientId)
          })
        )) && (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            classes={{ content: classes.accordionSummary }}
            expandIcon={<ExpandIcon />}
          >
            <i
              className={clsx('fa fa-cogs', classes.accordionIcon)}
              style={{
                color: settings.colors.dashboard_icon
              }}
            ></i>
            <div>
              {intl.formatMessage({
                id: 'MENU.CLIENT_SETTINGS'
              })}
            </div>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <div className={classes.buttonsContainer}>
              {(!isDomainProvided || isSuperAdmin) &&
                can(
                  'read',
                  createSubject('User', {
                    client_id: Number(selectedClientId)
                  })
                ) && (
                  <CustomDashboardButton
                    linkTo={`/${selectedClientId}/users`}
                    label={intl.formatMessage({
                      id: 'MENU.USERS'
                    })}
                    icon="fad fa-users-cog"
                    url={JSONAPI_URLS.USERS}
                  ></CustomDashboardButton>
                )}
              {can(
                'read',
                createSubject('Address', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/addresses`}
                  label={intl.formatMessage({
                    id: 'MENU.ADDRESSES'
                  })}
                  icon="fad fa-map-marker-alt"
                  url={JSONAPI_URLS.ADDRESSES}
                ></CustomDashboardButton>
              )}
              {can(
                'read',
                createSubject('Mitel::License', {
                  client_id: Number(selectedClientId)
                })
              ) &&
                isDomainProvided && (
                  <CustomDashboardButton
                    linkTo={`/${selectedClientId}/licenses`}
                    label={intl.formatMessage({
                      id: 'MENU.LICENSES'
                    })}
                    icon="fad fa-file-certificate"
                    counter={false}
                  ></CustomDashboardButton>
                )}
              {can(
                'read',
                createSubject('Mitel::CdrHook', {
                  client_id: Number(selectedClientId)
                })
              ) &&
                isDomainProvided && (
                  <CustomDashboardButton
                    linkTo={`/${selectedClientId}/cdrHooks`}
                    label={intl.formatMessage({
                      id: 'MENU.CDR_HOOKS'
                    })}
                    icon="fad fa-spider-web"
                    counter={false}
                  ></CustomDashboardButton>
                )}
              {can(
                'update',
                createSubject('Config', {
                  client_id: Number(selectedClientId)
                })
              ) && (
                <CustomDashboardButton
                  linkTo={`/${selectedClientId}/appSettings`}
                  label={intl.formatMessage({
                    id: 'MENU.APP_SETTINGS'
                  })}
                  icon="fad fa-toolbox"
                  counter={false}
                ></CustomDashboardButton>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    selectedClientId: selectClient.selectedClient.id,
    userHasQueues: auth.haveQueues,
    isDomainProvided: isDefined(selectClient.selectedClient?.connect30_domain),
    connectDomain: Optional(selectClient.selectedClient?.connect30_domain).or(
      void 0
    ),
    isPartnerClient:
      isDefined(selectClient.selectedClient.partner) ||
      isDefined(selectClient.selectedClient.partner_id),
    hasCientSimcards: selectClient.selectedClient.hasCientSimcards,
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false)
  };
};

export default flowRight(
  injectIntl,
  withUserCheck,
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(GroupedButtons);
