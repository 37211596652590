import React from 'react';

import CustomCopyToClipboard from '../../../partials/copyToClipboard.js/CustomCopyToClipboard';
import mapNumberToDisplay from '../../../utils/mapNumberToDisplay';
import searchNumbers from '../../../utils/searchNumbers';
import Optional from '../../../utils/optional';
import DomainNumberRangeCell from './DomainNumberRangeCell';
import mapInactiveCellValue, {
  formatDate
} from '../actions/mapInactiveCellValue';
import defaultFormatDate from '../../../utils/formatDate';
import {
  doesArrayHasLength,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import ActionButtons from './ActionButtons';
import findTranslatedValues from '../../../utils/findTranslatedValues';
import { NUMBER_TYPES } from '../../../consts/initialValues';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import searchInArray from '../../../utils/searchInArray';
import OperatorCell from './OperatorCell';
import StatusCell from './StatusCell';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';

export default function tableColumns({
  hasUSerPartner,
  networkOptions,
  clientId,
  resetValues,
  isBeTable
}) {
  const columns = [
    {
      render: rowData => (
        <ActionButtons
          data={rowData}
          numberRange={rowData}
          resetValues={resetValues}
          isBeTable={isBeTable}
        />
      ),
      disableClick: true,
      sorting: false,
      width: '40px'
    },
    {
      title: 'CLIENT',
      render: rowData =>
        Optional(rowData.client)
          .map(client => client.name)
          .or(''),
      hideForUserView: true,
      field: 'client.name',
      width: '100px'
    },
    {
      title: 'TABLE.RANGE_FROM',
      render: rowData => (
        <CustomCopyToClipboard value={mapNumberToDisplay(rowData.range_from)} />
      ),
      exportTransformer: rowData => mapNumberToDisplay(rowData.range_from),
      customFilterAndSearch: (term, rowData) => {
        if (isUndefined(rowData.mitelData)) {
          return searchInAllNumbers({
            from: rowData.range_from,
            to: rowData.range_up_to_incl,
            term
          });
        }
        return searchInNumbersArray(rowData.mitelData, term);
      },
      field: 'range_from',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'TABLE.RANGE_TO',
      render: rowData => (
        <CustomCopyToClipboard
          value={mapNumberToDisplay(rowData.range_up_to_incl)}
        />
      ),
      exportTransformer: rowData =>
        mapNumberToDisplay(rowData.range_up_to_incl),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.range_up_to_incl, term);
      },
      field: 'range_up_to_incl',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },

    {
      title: 'TABLE.NETWORK',
      field: 'provider.name',
      render: rowData =>
        Optional(rowData.provider)
          .map(provider => Optional(provider.sane_name).or(provider.name))
          .or(''),
      exportTransformer: rowData => rowData['provider.name'],
      lookup: { ...networkOptions }
    },
    {
      title: 'OPERATOR',
      field: 'service_operator',
      render: rowData => <OperatorCell data={rowData} />,
      hidden: true,
      hideForCLients: true,
      exportTransformer: rowData =>
        Optional(rowData.service_operator)
          .map(operators => operators.join(', '))
          .or(''),
      customFilterAndSearch: (term, rowData) => {
        return searchInArray({ array: rowData.service_operator, term });
      }
    },
    {
      title: 'TABLE.NO_OF_NUMBERS',
      field: 'interval',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'STATUS',
      field: 'status',
      render: rowData => <StatusCell status={rowData.status} />,
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.NUMBER_STATUSES)
      }
    },
    {
      title: 'TABLE.TYPE',
      field: 'number_type',
      render: rowData =>
        findTranslatedValues(rowData.number_type, NUMBER_TYPES),
      exportTransformer: rowData =>
        findTranslatedValues(rowData.number_type, NUMBER_TYPES),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.NUMBER_TYPES)
      }
    },
    {
      title: 'NUMBER_RANGE.DOMAIN_RANGE',
      field: 'connect30_number_ranges',
      render: rowData => (
        <DomainNumberRangeCell data={rowData.connect30_number_ranges} />
      ),
      hidden: true,
      sorting: false
    },
    {
      title: 'TABLE.ACTIVE_AT',
      field: 'och_active_at',
      render: rowData => formatDate(rowData.och_active_at),
      exportTransformer: rowData => formatDate(rowData.och_active_at),
      customFilterAndSearch: (term, rowData) => {
        return searchFormattedDate(rowData.och_active_at, term);
      },
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'TABLE.INACTVE_AT',
      field: 'och_inactive_at',
      render: rowData => mapInactiveCellValue(rowData),
      exportTransformer: rowData =>
        mapInactiveCellValue({
          och_inactive_at: rowData.och_inactive_at
        }),
      customFilterAndSearch: (term, rowData) => {
        return searchFormattedDate(rowData.och_inactive_at, term);
      },
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'TABLE.RESERVED',
      field: 'reserved',
      hidden: true
    },
    {
      title: 'TABLE.DESCRIPTION',
      field: 'description',
      hidden: true
    },
    {
      title: 'CREATED_AT',
      render: rowData => defaultFormatDate(rowData.created_at),
      exportTransformer: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => defaultFormatDate(rowData.updated_at),
      exportTransformer: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (hasUSerPartner) {
    mappedColumns = columns.filter(column =>
      isUndefined(column.hideForCLients)
    );
  }

  if (isDefined(clientId)) {
    mappedColumns = mappedColumns.filter(column =>
      isUndefined(column.hideForUserView)
    );
  }

  if (
    can(
      'manage',
      createSubject('ExternalNumberRange', { client_id: Number(clientId) })
    )
  ) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}

const searchFormattedDate = (date, serchTerm) => {
  if (isUndefined(date)) {
    return false;
  }
  const formattedDate = formatDate(date);

  return (
    formattedDate
      .toString()
      .toLowerCase()
      .indexOf(serchTerm.toLowerCase().trim()) !== -1
  );
};

const searchInNumbersArray = (data, term) => {
  const { all = [] } = data;

  return doesArrayHasLength(
    all.map(el => el.number).filter(el => searchNumbers(el, term))
  );
};

const searchInAllNumbers = ({ from, to, term }) => {
  if (from === to) {
    return searchNumbers(from, term);
  }

  const allNumbersArray = arrayRange(from, to, 1);

  return doesArrayHasLength(
    allNumbersArray.filter(el => searchNumbers(el, term))
  );
};

const arrayRange = (from, to, step) =>
  Array.from(
    { length: (to - from) / step + 1 },
    (value, index) => from + index * step
  );
