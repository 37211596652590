import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';

import styles from '../styles/dataBox';
import withSettings from '../../../utils/withSettings';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import getSubscriptions from '../actions/getSubscription';
import { connect } from 'react-redux';
import Optional from '../../../utils/optional';
import { actions as reduxActions } from '../reducers';
import getSubscriptionDetails from '../actions/getSubscriptionDetails';

const SubscriptionBox = ({
  classes,
  settings,
  number,
  clientId,
  subscription,
  setSubscription,
  setIsLoading,
  isLoadingSubscription
}) => {
  useEffect(() => {
    (async function() {
      setSubscription(void 0);
      if (isDefined(number) && isDefined(clientId)) {
        setIsLoading(true);
        const response = await getSubscriptions(
          number.length < 9 ? `45${number}` : number,
          clientId
        );

        if (isDefined(response)) {
          const details = await getSubscriptionDetails(response.id, clientId);

          setSubscription(details);
        }
        setIsLoading(false);
      }
    })();
  }, [number, clientId, setSubscription,setIsLoading]);

  const onSubscriptionClick = () => {
    if (isDefined(subscription?.id)) {
      window.open(
        `https://quote.dstny.dk/home/accounts/account/${subscription?.parentAccountId}/subscriptions/${subscription?.id}?tenantId=dstny`
      );
    }
  };
  return (
    <div className={classes.root}>
      {isLoadingSubscription && (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.valueContainer}>
        <div>{translate('REQUEST_NEW_USER.SUBSCRIPTION')}</div>
        <div className={classes.value}>
          {subscription?.skuTranslations?.default}
        </div>
      </div>
      <div className={classes.iconContainer}>
        <i
          className={clsx('far fa-regular fa-building', classes.icon, {
            [classes.disablePointer]: isUndefined(subscription?.id)
          })}
          style={{
            color: isDefined(subscription?.id)
              ? settings.colors.links
              : '#F2F3F8'
          }}
          onClick={onSubscriptionClick}
        ></i>
      </div>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  const {
    externalNoData,
    number,
    subscription,
    isLoadingSubscription
  } = checkNumber;
  return {
    clientId: Optional(externalNoData?.client?.id).or(void 0),
    number,
    subscription,
    isLoadingSubscription
  };
};

const mapDispatchToProps = {
  setSubscription: reduxActions.setSubscription,
  setIsLoading: reduxActions.setIsLoading
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SubscriptionBox);
