import { translate } from "../i18n/I18nProvider";

export default function translateOptionslLabels(options) {
  return options.map(option => {
    const { value, label } = option;
    return {
      value,
      label: translate(label)
    };
  });
}