import pjson from '../../../package.json';
import enLocale from 'date-fns/locale/en-US';
import daLocale from 'date-fns/locale/da';
import { translate } from '../i18n/I18nProvider';
import Optional from '../utils/optional';

export const ENV =
  Object.keys(window.ENV).length === 0 ? process.env : window.ENV;

export const SERVER = ENV.REACT_APP_API_URL;

export const INITIAL_VALUES = {
  LOGIN_USERNAME: ENV.REACT_APP_INITIAL_LOGIN_USERNAME,
  LOGIN_PASSWORD: ENV.REACT_APP_INITIAL_LOGIN_PASSWORD
};

export const DEFAULT_CONFIG_VALUES = {
  SUPPORT_EMAIL: '',
  WEBSITE: '',
  SUPPORT_PHONE_NUMBER: '',
  HELPDESK_WEBSITE: ''
};

export const CONST_VALUES = {
  VERSION: pjson.version,
  ICC_CONST: '89450',
  SUPPORT_NUMBER: '8888 7777',
  SENTRY_DSN:
    'https://fc6be3cd7e744a30b1b4505f5f8653b4@o412373.ingest.sentry.io/5289319',
  APPENDIX_LINK: 'https://dstny.dk/aftalebilag/',
  SIMCARD_PRICING:
    'https://dstny.dk/wp-content/uploads/downloads/aftalebilag/bilag_2b.pdf'
};

export const PROVIDERS_NUMBERS = {
  'TDC NET': 894501,
  'Telenor net': 894502,
  '3 NET': 894506,
  TDC: 894501,
  Telenor: 894502,
  '3': 894506
};

export const EUICC_NETWORK_PREFIX = {
  'TDC NET': '890440458177274848',
  'Telenor net': '8903401101423',
  TDC: '890440458177274848',
  Telenor: '8903401101423'
};

export const DATALIMIT_MULTIEDIT = {
  allow_recharge: '',
  report_to_sms: '',
  report_to_email: '',
  report_only_to: '',
  roaming_disabled: '',
  manual_block: '',
  update_block_limit_mb_now: '',
  block_limit_mb: '',
  fair_usage_limit: ''
};

export const NUMBER_RANGES_MULTIEDIT = {
  network: '',
  csbc_routing: '',
  status: '',
  future_status: '',
  address: '',
  description: ''
};

export const NUMBER_TYPES = {
  mobile: 'NUMBER_RANGES.MOBILE',
  landline: 'NUMBER_RANGES.LANDLINE'
};

export const NUMBER_STATUSES = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  cancel: 'TERMINATED',
  outported: 'MENU.OUTPORTED'
};

export const NUMBER_LOOKUP_GENERAL_DATA_KEYS = [
  'number',
  'status',
  'carrier',
  'extra_card',
  'subscriber_icc',
  'subscriber_icc_info',
  'subscriber_id',
  'subscriber_imsi',
  'subscriber_msisdn',
  'subscriber_np_status',
  'subscriber_np_status_date',
  'subscriber_status',
  'activation_date_time'
];

export const NUMBER_LOOKUP_DATALIMIT_KEYS = [
  { value: 'name', intlKey: 'TABLE.NAME' },
  { value: 'status', intlKey: 'STATUS' },
  { value: 'max_numbers', intlKey: 'DATA_LIMITS.MAX_NO' },
  { value: 'block_limit_mb', intlKey: 'DATA_LIMITS.DATA_LIMIT' },
  { value: 'roaming_disabled', intlKey: 'DATA_LIMITS.ROAMING_DISABLED' },
  { value: 'fair_usage_limit', intlKey: 'DATA_LIMITS.FAIR_USAGE_LIMIT' },
  { value: 'recharge_count', intlKey: 'DATA_LIMITS.RECHARGE_COUNT' },
  { value: 'recharged_at', intlKey: 'DATA_LIMITS.RECHARGED_AT' },
  { value: 'allow_recharge', intlKey: 'DATA_LIMITS.AUTO_RECHARGE' },
  { value: 'future_limit', intlKey: 'DATA_LIMITS.FUTURE_LIMIT' },
  { value: 'future_limit_at', intlKey: 'DATA_LIMITS.FUTUR_LIMIT_AT' },
  { value: 'warned_at', intlKey: 'DATA_LIMITS.WARNED' },
  { value: 'blocked_at', intlKey: 'DATA_LIMITS.BLOCKED' }
];

export const NUMBER_LOOKUP_SIMCARD_DATA = [
  { value: 'owner', intlKey: 'EDIT.USER' },
  { value: 'client', intlKey: 'CLIENT' },
  { value: 'icc', intlKey: 'SIMCARD_NUMBER' },
  { value: 'msisdn', intlKey: 'TABLE.MOBILE_NUMBER' },
  { value: 'description', intlKey: 'TABLE.DESCRIPTION' },
  { value: 'data_card', intlKey: 'DATACARD' },
  { value: 'card_type', intlKey: 'CARD_TYPE' },
  { value: 'primary_card', intlKey: 'TABLE.PRIMARY_CARD' },
  { value: 'active', intlKey: 'STATUS' },
  { value: 'mobile_network', intlKey: 'TABLE.NETWORK' }
];

export const NUMBER_LOOKUP_NUMBER_RANGES_DATA = [
  { value: 'range_from', intlKey: 'TABLE.RANGE_FROM' },
  { value: 'range_up_to_incl', intlKey: 'TABLE.RANGE_TO' },
  { value: 'provider', intlKey: 'TABLE.NETWORK' },
  { value: 'client', intlKey: 'CLIENT' },
  { value: 'interval', intlKey: 'TABLE.NO_OF_NUMBERS' },
  { value: 'status', intlKey: 'STATUS' },
  { value: 'number_type', intlKey: 'TABLE.TYPE' },
  { value: 'och_active_at', intlKey: 'TABLE.ACTIVE_AT' },
  { value: 'och_inactive_at', intlKey: 'TABLE.INACTVE_AT' },
  { value: 'description', intlKey: 'TABLE.DESCRIPTION' }
];

export const EMPTY_SCHEDULE = {
  disableRecordingPrompts: false,
  endDate: null,
  endDateSet: false,
  endTime: 0,
  forwardNumber: null,
  hangup: false,
  name: '',
  prompt: '',
  reccurenceEndDate: null,
  reccurenceEndType: 'NEVER',
  reccurence_0: false,
  reccurence_1: false,
  reccurence_2: false,
  reccurence_3: false,
  reccurence_4: false,
  reccurence_5: false,
  reccurence_6: false,
  recurrenceType: 'NONE',
  startDate: null,
  startDateSet: false,
  startTime: 0,
  type: 'OPEN'
};

export const EMPTY_CONTACT = {
  cid: '',
  company: '',
  department: '',
  firstname: '',
  lastname: '',
  shortname: '',
  email: '',
  street: '',
  city: '',
  postalcode: '',
  country: '',
  speeddial: '',
  contactStatus: 'FAVORITE',
  ownerType: 'USER'
};

export const TRUE_FALSE_OPTIONS = {
  true: 'TRUE',
  false: 'FALSE'
};

export const DATE_FORMAT = {
  en: 'yyyy/MM/dd',
  da: 'yyyy-MM-dd'
};

export const PICKERS_LOCALE = {
  en: enLocale,
  da: daLocale
};

export const STATUSES_ICONS = {
  CONTACT: 'far fa-star',
  FAVORITE: 'fas fa-star',
  VIP: 'far fa-heart',
  BLOCKED: 'fas fa-ban'
};

export const COUNTRY_CODE = {
  danish: '45'
};

export const INVOICE_URLS = {
  guide:
    'https://dstny.dk/wp-content/uploads/2021/11/faktureringsvejledning.pdf',
  faq: 'https://ipvision.dk/faktura-faq'
};

export const CUSTOMER_SERVICE_MAIL = 'kundeservice@dstny.dk';

export const STANDARD_USER_ROLE = { id: '1000', name: 'Standard bruger' };

export const DEFAULT_DIR_FILDS = [
  { name: 'field1', value: null, id: 'field1' },
  { name: 'field2', value: null, id: 'field2' },
  { name: 'field3', value: null, id: 'field3' },
  { name: 'field4', value: null, id: 'field4' },
  { name: 'field5', value: null, id: 'field5' },
  { name: 'field6', value: null, id: 'field6' }
];

export const LANGUAGES = {
  en: 'ENGLISH',
  da: 'DANISH',
  de: 'GERMAN',
  fi: 'FINNISH',
  nb: 'NORWEGIAN',
  no: 'NORWEGIAN',
  sv: 'SWEDISH'
};

export const SUBSCRIPTIONS_STATUS_OPTIONS = [
  { value: 'ALL', label: 'All' },
  { value: 'INITIAL', label: 'Initial' },
  { value: 'IN_ACTIVATION_PROCESS', label: 'In activation process' },
  { value: 'In termination process', label: 'In termination process' },
  { value: 'TERMINATED', label: 'Terminated' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'TERMINATION_PENDING', label: 'Termination pending' }
];

export function getSubscriptionsGroupingOptions() {
  return [
    { value: false, label: translate('SUBSCRIPTIONS.GROUP_BY_SKU') },
    { value: true, label: translate('SUBSCRIPTIONS.GROUP_BY_CATEGORY') }
  ];
}

export const PERMISSION_CREATE = [
  `<realm>.module.<module>[.<action>]`,
  `<realm>.resource.<resource>.`
];

export const EMPTY_JOB = {
  name: '',
  description: '',
  job_class: '',
  category: '',
  object_type: '',
  parameters: {},
  form_schema: {},
  ui_schema: {},
  roles: []
};

export const findESimStatus = status => {
  const translations = {
    AVAILABLE: translate('ESIM_STATUS.AVAILABLE'),
    CONFIRMED: translate('ESIM_STATUS.CONFIRMED'),
    RELEASED: translate('ESIM_STATUS.RELEASED'),
    DOWNLOADED: translate('ESIM_STATUS.DOWNLOADED'),
    INSTALLED: translate('ESIM_STATUS.INSTALLED'),
    ENABLED: translate('ESIM_STATUS.ENABLED'),
    DELETED: translate('ESIM_STATUS.DELETED'),
    ERROR: translate('ESIM_STATUS.ERROR')
  };

  return translations[status];
};

export const findPhonebookStatuses = status => {
  const translations = {
    not_secret: translate('PHONE_BOOK.NOT_SECRET'),
    hidden: translate('PHONE_BOOK.HIDDEN'),
    secret_number: translate('PHONE_BOOK.SECTER_NUMBER'),
    secret_address: translate('PHONE_BOOK.SECRET_ADDRESS')
  };

  return Optional(translations[status]).or('');
};

export const DEFAULT_BOOK_DATA = {
  protection_code: 'hidden',
  person_given_name: '',
  person_middle_name: '',
  person_surname_name: '',
  organization_name: '',
  address: '',
  care_of_name: '',
  use_default_address: true
};

export const translateDatalimitStatus = status => {
  const translations = {
    active: translate('DATA_LIMITS.ACTIVE'),
    recharged: translate('DATA_LIMITS.RECHARGED'),
    rlh_billed: translate('DATA_LIMITS.BILLED'),
    keep_open: translate('DATA_LIMITS.KEEP_OPEN'),
    shut: translate('DATA_LIMITS.SHUT'),
    blocked: translate('DATA_LIMITS.KEEP_BLOCKED')
  };

  return Optional(translations[status]).or(status);
};
