const styles = theme => {
  return {
    root: { minHeight: '260px' }
  };
};

export default styles;

export const CUSTOM_STYLES = {
  checkboxRow: {
    label: {
      width: '165px'
    }
  },
  textFieldRow: {
    label: {
      width: '150px'
    }
  }
};
