import React from 'react';
import { Link } from 'react-router-dom';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

function LinkCell({ settings, from, clientId, user, classes = {} }) {
  const name = Optional(user.name).or('');
  const client = Optional(clientId).or('admin');
  const userId =
    from === 'telephonyUsers' || from === 'groups' ? user.user_id : user.id;

  return (
    <Link
      className={classes.root}
      style={{ color: settings.colors.links }}
      to={{
        pathname: `/${client}/${from}/user`,
        state: { id: userId }
      }}
    >
      {name}
    </Link>
  );
}

export default withSettings(LinkCell);
