import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

const editVacation = async ({ values, isEdit, id }) => {
  const { holiday, holiday_type, date, month } = values;
  const data = {
    type: 'vacation_days',
    attributes: {
      holiday,
      holiday_type,
      date,
      month
    }
  };

  const response = isEdit
    ? await updateData({
        url: `${URLS.VACATIONS}/${id}`,
        data: {
          data: { ...data, id: Number(id) }
        }
      })
    : await postData({
        url: `${URLS.VACATIONS}`,
        data: {
          data
        }
      });

  if (response.status === RESPONSE_CODES.CREATED) {
    showSnackbar({
      intlTextKey: 'MESSAGE.ADD_VACATION',
      messageType: 'success'
    });

    return true;
  }

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.EDIT_VACATION',
      messageType: 'success'
    });
    return true;
  }

  return false;
};

export default editVacation;
