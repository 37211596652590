/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import { getClientsList } from './actions';
import Optional from '../../utils/optional';
import createSelectClientOptions from './actions/createSelectClientOptions';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import history from '../../../history';
import { actions as dashboardReduxActions } from '../../pages/clientDashboard/reducers';
import { URLS } from '../../consts/endpoints';
import { getData } from '../../utils/http';
import { isDefined } from '../../utils/isDefined';
import checkIfSimcardsShouldBeShown from '../../utils/checkIfSimcardsShouldBeShown';
import LastClients from './components/LastClients';
import {
  getCurrentValues,
  setSearchHistory
} from '../../utils/localstorageUtils';
import withSettings from '../../utils/withSettings';

export function SelectClient({
  clientsList,
  selectedClientId,
  getClientsList,
  toggleDialog,
  classes,
  intl,
  open,
  resetSelectClient,
  setSelectedClient,
  role,
  adminDomains,
  resetDashboard,
  setHistory,
  settings
}) {
  useEffect(() => {
    getClientsList({ role, adminDomains });
  }, [getClientsList, role, adminDomains]);

  useEffect(() => {
    return resetSelectClient;
  }, [resetSelectClient]);

  useEffect(() => {
    const history = getCurrentValues({
      name: `clients`
    });

    setHistory(history);
  }, [clientsList, setHistory]);

  const options = useMemo(() => createSelectClientOptions(clientsList), [
    clientsList
  ]);

  const handleChange = async selected => {
    resetDashboard();

    const newSearchHistory = setSearchHistory({
      name: `clients`,
      value: selected.value
    });

    if (isDefined(newSearchHistory)) {
      setHistory(newSearchHistory);
    }

    history.push(`/${selected.value}/dashboard`);
    let selectedClient = clientsList.find(
      client => client.id === selected.value
    );

    const hasCientSimcards = await checkIfSimcardsShouldBeShown(selected.value);

    let client;
    try {
      const { data } = await getData(
        `${URLS.CLIENTS}/${selected.value}.json?include=partner`
      );

      client = data;
    } finally {
      if (isDefined(client)) {
        selectedClient = client;
      }
    }

    setSelectedClient({
      ...selectedClient,
      hasCientSimcards
    });
    toggleDialog();
  };

  const handleClose = () => {
    toggleDialog();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogContent,
        scrollPaper: classes.scrollPaper
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        className={classes.dialogTitle}
        style={{
          backgroundColor: settings.colors.modal_header,
          color: settings.colors.modal_header_text
        }}
      >
        {intl.formatMessage({
          id: 'MENU.SWITCH_CLIENT'
        })}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Select
          styles={reactSelectDefaultStyles}
          className={classes.select}
          value={selectedClientId}
          onChange={handleChange}
          autoFocus
          options={options.length > 0 ? options : ''}
          placeholder={`${intl.formatMessage({
            id: 'EDIT.SELECT_CLIENT'
          })} ...`}
        />
        <LastClients handleChange={handleChange} />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    clientsList: Optional(selectClient.selectClientsList).or([]),
    selectedClientId: Optional(selectClient.selectedClient)
      .map(client => client.id)
      .or(auth.defaultClient.id),
    open: selectClient.open,
    role: auth.role,
    adminDomains: auth.user.admin_domains
  };
};

const mapDispatchToProps = {
  getClientsList,
  toggleDialog: reduxActions.toggleDialog,
  resetSelectClient: reduxActions.resetSelectClient,
  setSelectedClient: reduxActions.setSelectedClient,
  resetDashboard: dashboardReduxActions.resetAdmins,
  setHistory: reduxActions.setHistory
};

export default flowRight(
  withSettings,
  injectIntl,
  withRouter,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SelectClient);
