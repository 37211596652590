import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import styles from '../styles/customDashboardButton';
import withSettings from '../../../utils/withSettings';
import getCount from '../actions/getCount';
import { isDefined } from '../../../utils/isDefined';
import getConnectCount from '../actions/getConnectCount';

function CustomDashboardButton({
  classes,
  settings,
  linkTo,
  label,
  icon,
  url,
  counter = true,
  connectCounter = false,
  selectedClientId,
  hideWhenCountIsZero,
  additionalRequest
}) {
  const [count, setCount] = useState(0);
  const [shouldBeShown, setShouldBeShown] = useState(false);

  useEffect(() => {
    setCount('-');
    if (counter && isDefined(selectedClientId)) {
      (async function fetch() {
        const count = await getCount({ url, selectedClientId });

        return setCount(count);
      })();
    }
    if (connectCounter) {
      (async function fetch() {
        const count = await getConnectCount({
          url,
          selectedClientId
        });

        return setCount(count);
      })();

      if (isDefined(additionalRequest)) {
        (async function fetch() {
          const count = await getCount({
            url: additionalRequest,
            selectedClientId
          });

          return setShouldBeShown(count > 0);
        })();
      }
    }

    return setCount('-');
    // eslint-disable-next-line
  }, [url, selectedClientId]);

  if (hideWhenCountIsZero && count === 0 && !shouldBeShown) {
    return null;
  }

  return (
    <Link to={linkTo}>
      <div
        className={classes.root}
        style={{ backgroundColor: settings.colors.dashboard_button }}
      >
        <i
          className={clsx(icon, classes.icon)}
          style={{ color: settings.colors.dashboard_icon }}
        ></i>
        {counter || connectCounter ? (
          <div
            className={classes.labelContainer}
            style={{ color: settings.colors.dashboard_text }}
          >
            <div className={classes.counter}>{count}</div>
            <div className={classes.labelSmall}>{label}</div>
          </div>
        ) : (
          <div
            className={classes.label}
            style={{ color: settings.colors.dashboard_text }}
          >
            {label}
          </div>
        )}
      </div>
    </Link>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    selectedClientId: selectClient.selectedClient.id
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(CustomDashboardButton);
