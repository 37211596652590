import React, { useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import { connect } from 'react-redux';

import styles, { customSelectStyles } from '../styles/callerIdSelect';
import Optional from '../../../utils/optional';
import { changeSelectedLine } from '../actions/setSelectedLine';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';

const CallerIdSelect = ({
  classes,
  intl,
  linesOptions,
  selectedLine,
  connectId,
  changeSelectedLine
}) => {
  const handleChange = useCallback(
    value => {
      if (value.number !== selectedLine.number) {
        changeSelectedLine(value, connectId);
      }
    },
    // eslint-disable-next-line
    [connectId, selectedLine]
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'NUMBER.CALLER_ID_SELECT'
        })}
      </div>
      <Select
        styles={reactSelectDefaultStyles}
        customStyles={customSelectStyles}
        className={classes.select}
        defaultValue={selectedLine}
        onChange={handleChange}
        options={linesOptions.length > 0 ? linesOptions : ''}
      />
    </div>
  );
};

const mapStatesToProps = ({ user }) => {
  return {
    linesOptions: Optional(user.linesOptions).or([]),
    selectedLine: user.selectedLine,
    connectId: user.connect30_user_id
  };
};

const mapDispatchToProps = {
  changeSelectedLine
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(CallerIdSelect);
