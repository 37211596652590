import { translate } from '../../../i18n/I18nProvider';
import { isUndefined } from '../../../utils/isDefined';
import { createMonthsArray } from './mapDatalimits';

export default function createUsageColumnsLabels(lastMonthsCount, showAll) {
  const [actualMonth, ...months] = createMonthsArray(lastMonthsCount + 1);

  let columns = [
    { label: translate('TABLE.NAME'), key: 'name' },
    { label: translate('CELL_NUMBERS'), key: 'cellNumbers' },
    { label: translate('EDIT.USER'), key: 'user' },
    {
      label: `${translate('CURRENT')} ${translate('DATA_LIMITS.DATA_LIMIT')}`,
      key: 'limit'
    },
    {
      label: `${translate('USAGE_GB')}: ${actualMonth.date} `,
      key: `usageGB_${actualMonth.date}`
    },
    ...months.map(month => {
      return {
        label: `${translate('USAGE_GB')}: ${month.date} `,
        key: `usageGB_${month.date}`,
        showAll: true
      };
    }),
    {
      label: translate('DATA_LIMITS.AVERAGE_USE'),
      key: `totalAverage`
    },
    {
      label: `${translate('CURRENT')} ${translate(
        'DATA_LIMITS.ROAMING_EU_LIMIT'
      )}`,
      key: 'fairLimit'
    },
    {
      label: `${translate('DATA_LIMITS.ROAMING_EU_GB')}: ${
        actualMonth.date
      } `,
      key: `fairUsageGB_${actualMonth.date}`
    },
    ...months.map(month => {
      return {
        label: `${translate('DATA_LIMITS.ROAMING_EU_GB')}: ${
          month.date
        } `,
        key: `fairUsageGB_${month.date}`,
        showAll: true
      };
    }),
    {
      label: translate('DATA_LIMITS.AVERAGE_USE'),
      key: `fairAverage`
    }
  ];

  if (!showAll) {
    columns = columns.filter(column => isUndefined(column.showAll));
  }

  return columns;
}
