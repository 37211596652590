import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import flowRight from 'lodash.flowright';
import React, { useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles, { CUSTOM_STYLES } from '../styles/simcardsStep';
import { actions as reduxActions } from '../reducers';
import InputRow from '../../../partials/dataRows/InputRow';
import { CONST_VALUES } from '../../../consts/initialValues';
import SelectRow from '../../../partials/dataRows/SelectRow';
import validateSchema from '../actions/validateSchema';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import mapProvidersOptions from '../../../utils/mapProvidersOptions';
import withSettings from '../../../utils/withSettings';
import SelectAccount from '../../../partials/selectAccount/SelectAccount';
import { getAllSelectOptions } from '../actions/getSelectOptions';
import mapDefaultAddress from '../../requestUserWizard/actions/mapDefaultAddress';
import { isUndefined } from '../../../utils/isDefined';

function SimcardsStep({
  handleNext,
  setSimcardData,
  intl,
  classes,
  resetOrderSimcard,
  firstStepData,
  settings,
  accountsOptions,
  clientId,
  getAllSelectOptions,
  partner,
  partnerId
}) {
  useEffect(() => {
    getAllSelectOptions(clientId);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    values => {
      const secondStep = mapDefaultAddress(values);

      setSimcardData({ ...values, account: values.account.value }, 'firstStep');
      setSimcardData(secondStep, 'secondStep');
      handleNext();
    },
    // eslint-disable-next-line
    []
  );
  const providersOptions = mapProvidersOptions();
  return (
    <Formik
      initialValues={firstStepData}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl).simcardsStep}
    >
      {({
        handleSubmit,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        const fieldProps = {
          errors,
          values,
          handleBlur,
          handleChange,
          setFieldValue
        };
        return (
          <Form onSubmit={handleSubmit} className={classes.root}>
            <div className={classes.sectionContainer}>
              <SelectRow
                name="provider"
                label="ORDER_SIMCARD.SELECT_PROVIDER"
                isEditModal={false}
                options={providersOptions}
                customStyles={CUSTOM_STYLES.selectRow}
                {...fieldProps}
              />
              <InputRow
                label="QUANTITY"
                name="count"
                maxValue={50}
                fieldType="number"
                customStyles={CUSTOM_STYLES.inputRow}
                {...fieldProps}
              />
              <Field
                component={SelectAccount}
                selectOptions={accountsOptions}
                fullAccount={true}
                customStyles={CUSTOM_STYLES.acountSelect}
              />
            </div>
            {isUndefined(partnerId) && isUndefined(partner) ? (
              <Link
                variant="body2"
                className={classes.appendixLink}
                style={{ color: settings.colors.links }}
                onClick={() => window.open(CONST_VALUES.SIMCARD_PRICING)}
              >
                {intl.formatMessage({
                  id: 'ORDER_SIMCARD.SIMCARD_PRICES'
                })}
              </Link>
            ) : (
              <div className={classes.appendixLink}></div>
            )}

            <div className={classes.footer}>
              <PrimaryButton onClick={handleSubmit}>
                {intl.formatMessage({
                  id: 'BUTTON.NEXT'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={resetOrderSimcard}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({
  orderNewSimcard: { simcardData, accountsOptions },
  selectClient
}) => {
  return {
    firstStepData: simcardData.firstStep,
    accountsOptions,
    clientId: selectClient.selectedClient?.id,
    partner: selectClient.selectedClient?.partner,
    partnerId: selectClient.selectedClient?.partner_id
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  getAllSelectOptions
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SimcardsStep);
