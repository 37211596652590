import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import closeSwapCard from './actions/closeSwapCard';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import { translate } from '../../i18n/I18nProvider';
import StandardCardView from './components/StandardCardView';
import Optional from '../../utils/optional';
import { doesArrayHasLength, isArray, isDefined } from '../../utils/isDefined';
import OrderESimcard from './components/OrderESimcard';
import TabPanel from '../../partials/TabPanel';
import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import clsx from 'clsx';
import Problems from '../../pages/user/components/Problems';

export function SwapSimcard({
  classes,
  open,
  closeSwapCard,
  hasClientAccounts,
  simcard,
  tabIndex,
  setTabIndex,
  problems
}) {
  const handleChange = newValue => {
    setTabIndex(newValue);
  };

  const dialogClasses = useMemo(
    () => {
      return {
        paper: classes.root
      };
    }, // eslint-disable-next-line
    []
  );

  const shouldBlockButton = isArray(problems)
    ? problems.filter(problem => problem?.severity === 'MAJOR')
        .length > 0
    : false;

  return (
    <Dialog
      maxWidth="md"
      classes={dialogClasses}
      open={open}
      PaperComponent={DraggableDialog}
      onClose={closeSwapCard}
    >
      <CustomDialogTitle onCloseClick={closeSwapCard}>
        {translate('BUTTON.SWAP_SIMCARD')}
      </CustomDialogTitle>
      {isDefined(problems) && <Problems problems={problems} />}
      {hasClientAccounts && simcard?.mobile_network?.brand === 'TDC' ? (
        <DialogContent className={classes.dialogContent}>
          <div className={classes.buttonsRow}>
            <PrimaryButton
              disabled={shouldBlockButton}
              onClick={() => handleChange(1)}
            >{`${translate('PHYSICAL')} ${translate(
              'NUMBER.SIMCARD'
            )}`}</PrimaryButton>
            <PrimaryButton
              disabled={shouldBlockButton}
              onClick={() => handleChange(2)}
            >
              eSIM
            </PrimaryButton>
          </div>

          <TabPanel value={tabIndex} index={0}>
            {translate('SWAP_SIMCARD_SELECT_TEXT')}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <StandardCardView />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <OrderESimcard />
          </TabPanel>
        </DialogContent>
      ) : (
        <DialogContent
          className={clsx(classes.dialogContent, classes.oneOption)}
        >
          <StandardCardView />
        </DialogContent>
      )}
    </Dialog>
  );
}

const mapStatesToProps = ({ swapSimcard, selectClient }) => {
  return {
    ...swapSimcard,
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false)
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  closeSwapCard
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SwapSimcard);
