import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';

import styles, { CUSTOM_STYLES } from '../styles/checkboxSection';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import { isDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

export function CheckboxSection({ classes, form, clientId }) {
  const { initialValues } = form;

  const disableRechargeCheckbox = useMemo(() => {
    return (
      (isDefined(initialValues.recharge_count) &&
        initialValues.recharge_count > 0) ||
      !can(
        'update',
        createSubject('DataLimit', {
          client_id: Number(clientId)
        }),
        'allow_recharge'
      )
    );
  }, [initialValues.recharge_count, clientId]);

  return (
    <div className={classes.root}>
      <div>
        <CheckboxRow
          label="DATA_LIMITS.AUTO_RECHARGE"
          name="allow_recharge"
          infoIcon={true}
          infoIconTooltipComponent={
            <div>
              <div>{translate('DATA_LIMITS.TOOLTIP.ACTIVATION')}</div>
              <div style={{ height: '5px' }}></div>
              <div>{translate('DATA_LIMITS.TOOLTIP.AUTO_RECHARGE_1')}</div>
              <div style={{ height: '10px' }}></div>
              <div>{translate('DATA_LIMITS.TOOLTIP.DEACTIVATION')}</div>
              <div style={{ height: '5px' }}></div>
              <div>{translate('DATA_LIMITS.TOOLTIP.AUTO_RECHARGE_2')}</div>
            </div>
          }
          disabled={disableRechargeCheckbox}
          customStyles={CUSTOM_STYLES.checkboxRow}
          {...form}
        />
      </div>

      <CheckboxRow
        label="DATA_LIMITS.ROAMING_DISABLED"
        name="roaming_disabled"
        customStyles={CUSTOM_STYLES.checkboxRow}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'roaming_disabled'
          )
        }
        {...form}
      />
      {initialValues.status === 'blocked' && (
        <CheckboxRow
          label="DATA_LIMITS.TEMP_OPEN"
          name="tmp_open"
          infoIcon={true}
          infoIconTooltipIntlKey="DATA_LIMITS.TEMPORARY_OPEN_TOOLTIP"
          customStyles={CUSTOM_STYLES.checkboxRow}
          disabled={
            !can(
              'update',
              createSubject('DataLimit', {
                client_id: Number(clientId)
              }),
              'tmp_open'
            )
          }
          {...form}
        />
      )}
      <CheckboxRow
        label="DATA_LIMITS.BLOCK_DATA_USAGE"
        name="manual_block"
        infoIcon={true}
        infoIconTooltipIntlKey="DATA_LIMITS.BLOCK_MANUALY"
        customStyles={CUSTOM_STYLES.checkboxRow}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'manual_block'
          )
        }
        {...form}
      />
    </div>
  );
}

export default withStyles(styles)(CheckboxSection);
