import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback
} from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import {
  mapTableOptions,
  setLocalization,
  TABLE_OPTIONS
} from '../../../consts/tableOptions';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import getValueFromLocation from '../../../utils/getValueFromLocation';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import tableColumns from './tableColumns';
import { setClient } from '../../../modals/editClient/actions/setClient';
import fetchClients from '../actions/fetchClients';
import { actions as reduxActions } from '../reducers';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import withUserCheck from '../../../utils/withUserCheck';

export function ClientsTable({
  intl,
  location,
  openEditClient,
  refresh,
  setRefresh,
  columns,
  setColumns,
  customJobs,
  isNonClientAdminUser,
  partnerId
}) {
  const [filtering, setFiltering] = useState(false);
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DEFAULT.pageSize);
  const partner = getValueFromLocation(location, 'id');
  const partnerName = getValueFromLocation(location, 'name', 'All');

  const tableRef = useRef();

  useEffect(() => {
    if (refresh) {
      tableRef.current.onQueryChange();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [partner, refresh]);

  const fetchData = useCallback(
    query => {
      setPageSize(query.pageSize);
      return fetchClients(query, partner);
      // eslint-disable-next-line
    },
    [setPageSize, partner]
  );

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Clients-1.1'
    });
  }, [setColumns, tableDefaultColumns]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Clients');
  }, [customJobs]);

  const clientData = useMemo(() => {
    if (isNonClientAdminUser && isDefined(partnerId)) {
      return { partner_id: partnerId, csbc_routing: 'ipsolutions' };
    }

    return void 0;
  }, [partnerId, isNonClientAdminUser]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        },
        {
          icon: () => <AddButton buttonText="BUTTON.NEW_CLIENT" />,
          onClick: () => openEditClient(clientData),
          isFreeAction: true
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      pageSize,
      filtering
    };
  }, [filtering, pageSize]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Clients-1.1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.CLIENTS"
            icon={<i className="fas fa-users"></i>}
            name={partnerName}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={fetchData}
        options={options}
        localization={localization}
        tableRef={tableRef}
        onChangeColumnHidden={onChangeColumnHidden}
      />
    </>
  );
}

const mapStatesToProps = ({ clients, auth }) => {
  return {
    ...clients,
    customJobs: auth.customJobs,
    partnerId: auth.user?.partner_id
  };
};

const mapDispatchToProps = {
  openEditClient: setClient,
  setRefresh: reduxActions.setRefresh,
  setColumns: reduxActions.setColumns
};

export default flowRight(
  withRouter,
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps)
)(ClientsTable);
