import React, { useState, cloneElement } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import styles from './styles';
import PrimaryButton from '../customButtons/PrimaryButton';
import SecondaryButton from '../customButtons/SecondaryButton';
import CustomDialogTitle from '../customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../DraggableDialog';
import { isDefined } from '../../utils/isDefined';

export function ConfirmDialog({
  closeMenu = () => {},
  classes,
  intl,
  onConfirmClick,
  dialogTitleIntlId = 'EMPTY',
  dialogTextIntlId = 'EMPTY',
  dialogContentComponent = null,
  children,
  closeButtonIntlId = 'BUTTON.CANCEL',
  PrimaryButtonIntlId = 'BUTTON.CONFIRM',
  actionButtonsEnabled = true,
  valueToCheck,
  isWarning = false,
  diasabledChildren = false,
  primaryButtonShown = true
}) {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    closeMenu();
    if (isDefined(valueToCheck) && !valueToCheck) {
      onConfirmClick();
    } else {
      if (!diasabledChildren) {
        setOpen(true);
      }
    }
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirmClick();
    setOpen(false);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog} isWarning={isWarning}>
          {intl.formatMessage({
            id: dialogTitleIntlId
          })}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          {dialogTextIntlId &&
            intl.formatMessage({
              id: dialogTextIntlId
            })}
          {dialogContentComponent}
        </DialogContent>
        {actionButtonsEnabled && (
          <DialogActions className={classes.footer}>
            {primaryButtonShown && (
              <PrimaryButton onClick={handleConfirm}>
                {intl.formatMessage({
                  id: PrimaryButtonIntlId
                })}
              </PrimaryButton>
            )}
            <SecondaryButton onClick={onCloseDialog}>
              {intl.formatMessage({
                id: closeButtonIntlId
              })}
            </SecondaryButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

export default flowRight(injectIntl, withStyles(styles))(ConfirmDialog);
