import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

import { isDefined } from '../../utils/isDefined';
import CustomCopyToClipboard from '../copyToClipboard.js/CustomCopyToClipboard';

import styles from './styles';
import ShowMoreDataDialog from './components/ShowMoreDataDialog';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

function CellNumbersCell({
  data,
  classes,
  mapNumbers,
  showMore = false,
  intl,
  length = 1
}) {
  const numbers = isDefined(mapNumbers) ? mapNumbers(data) : data;

  const numbersToShow = showMore ? numbers.slice(0, length) : numbers;

  return (
    <div className={classes.root}>
      {isDefined(numbers) &&
        numbersToShow.map((number, i) => (
          <CustomCopyToClipboard value={number} key={i} />
        ))}
      {numbers.length > length && showMore && (
        <ShowMoreDataDialog data={data}>
          <Tooltip
            placement="bottom"
            title={intl.formatMessage({
              id: 'SHOW_MORE'
            })}
          >
            <i className={clsx('fal fa-ellipsis-h', classes.icon)}></i>
          </Tooltip>
        </ShowMoreDataDialog>
      )}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CellNumbersCell);
