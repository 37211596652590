import { translate } from '../i18n/I18nProvider';
import capitaliseFirstLetter from '../utils/capitaliseFirstLetter';

export default {
  PLATFORM_OPTIONS: [
    { value: 'lpbx', label: 'lpbx (legacy)' },
    { value: 'vpbx', label: 'Direkte Routing' },
    { value: 'mts', label: 'Connect 3.0 (test)' },
    { value: 'mtp', label: 'Connect 3.0' },
    { value: 'ipsolutions', label: 'ipsolutions' },
    { value: 'no-backend', label: 'no-backend' }
  ],
  LANG_OPTIONS: [
    { value: 'en', label: 'English' },
    { value: 'da', label: 'Danish' }
  ],
  SCHEDULES_REPEAT_OPTIONS: [
    { value: 'DAILY', label: 'DAILY' },
    { value: 'WEEKLY', label: 'WEEKLY' },
    { value: 'MONTHLY', label: 'MONTHLY' },
    { value: 'YEARLY', label: 'YEARLY' },
    { value: 'NONE', label: 'NEVER' }
  ],
  SCHEDULES_TYPE_OPTIONS: [
    { value: 'OPEN', label: 'OPEN' },
    { value: 'CLOSED', label: 'CLOSED' },
    { value: 'FORWARD', label: 'FORWARD' }
  ],
  SCHEDULES_END_OPTIONS: [
    { value: 'NEVER', label: 'NEVER' },
    { value: 'DATE', label: 'TO_DATE' }
  ],
  TRUE_FALSE: [],
  CONTEXT_OPTIONS: [
    { value: 'global', label: 'global' },
    { value: 'partner', label: 'partner' },
    { value: 'partner_clients', label: 'partner_clients' },
    { value: 'client', label: 'client' },
    { value: 'client_clients', label: 'client_clients' },
    { value: 'user', label: 'user' }
  ],
  VACATION_MONTHS: [
    { value: 'jan', label: 'JANUARY' },
    { value: 'feb', label: 'FEBRUARY' },
    { value: 'mar', label: 'MARCH' },
    { value: 'apr', label: 'APRIL' },
    { value: 'may', label: 'MAY' },
    { value: 'jun', label: 'JUNE' },
    { value: 'jul', label: 'JULY' },
    { value: 'aug', label: 'AUGUST' },
    { value: 'sep', label: 'SEPTEMBER' },
    { value: 'oct', label: 'OCTOBER' },
    { value: 'nov', label: 'NOVEMBER' },
    { value: 'dec', label: 'DECEMBER' }
  ],
  VACATIONS_TYPE: [
    { value: 'Single', label: 'VACATIONS.SINGLE' },
    { value: 'Interval', label: 'VACATIONS.INTERVAL' }
  ],
  CDR_HOOK_TYPE: [
    { value: '', label: '' },
    { value: 'AGENT', label: 'AGENT' },
    { value: 'CALL', label: 'CALL' },
    { value: 'IM', label: 'IM' },
    { value: 'QUEUESTATS', label: 'QUEUESTATS' },
    { value: 'REGISTRATION', label: 'REGISTRATION' },
    { value: 'SMS', label: 'SMS' },
    { value: 'VOICE_QUALITY', label: 'VOICE_QUALITY' }
  ]
};

export function trueFalseSelectOptions({ trueLabel, falseLabel }) {
  return [
    { value: true, label: trueLabel },
    { value: false, label: falseLabel }
  ];
}

export function createHoursOptions() {
  let timeOptions = [];

  for (let i = 0; i < 48; i++) {
    const hour = i % 2 === 0 ? i / 2 : i / 2 - 1 / 2;
    const option = {
      label: `${hour < 10 ? `0${hour}` : hour}:${i % 2 === 0 ? '00' : '30'}`,
      value: 1800000 * i
    };
    timeOptions = [...timeOptions, option];
  }

  return timeOptions;
}

export function createContactTypes() {
  return [
    { value: 'HOME', label: translate('HOME') },
    { value: 'WORK', label: translate('WORK') },
    { value: 'MOBILE', label: translate('MOBILE') },
    { value: 'MAIN', label: translate('MAIN') },
    { value: 'OTHER', label: translate('OTHER') }
  ];
}

export function createContactStatuses() {
  return [
    { value: 'FAVORITE', label: translate('FAVORITE') },
    { value: 'CONTACT', label: translate('CONTACT') },
    { value: 'VIP', label: 'VIP' },
    { value: 'BLOCKED', label: translate('BLOCKED') }
  ];
}

export function createInvoiceGroupingOptions() {
  return [
    {
      value: 'END',
      label: translate('INVOICE.SUBSCRIPTION_BOTTOM')
    },
    {
      value: 'START',
      label: translate('INVOICE.SUBSCRIPTION_TOP')
    }
  ];
}

export function createStatuses() {
  return [
    { value: 'active', label: translate('ACTIVE') },
    { value: 'inactive', label: translate('ON_HOLD') },
    { value: 'cancel', label: translate('TERMINATED') },
    { value: 'outported', label: translate('MENU.OUTPORTED') },
    { value: 'reserved', label: translate('TABLE.RESERVED') }
  ];
}

export function createDurationTypeOptions() {
  return [
    { value: 'MINUTES', label: capitaliseFirstLetter(translate('MINUTES')) },
    { value: 'NO_END_TIME', label: translate('USER.NO_END_TIME') },
    { value: 'UNTIL_NEXT_WORKING_DAY', label: translate('USER.UNTIL_NEXT_DAY') }
  ];
}

export function createNumberTypes() {
  return [
    { value: 'mobile', label: translate('NUMBER_RANGES.MOBILE') },
    { value: 'landline', label: translate('NUMBER_RANGES.LANDLINE') }
  ];
}

export function getPhoneBookStatuses() {
  return [
    { value: undefined, label: translate('EMPTY') },
    { value: 'not_secret', label: translate('PHONE_BOOK.NOT_SECRET') },
    { value: 'hidden', label: translate('PHONE_BOOK.HIDDEN') },
    { value: 'secret_number', label: translate('PHONE_BOOK.SECTER_NUMBER') },
    { value: 'secret_address', label: translate('PHONE_BOOK.SECRET_ADDRESS') }
  ];
}
