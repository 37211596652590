import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchGroups } from './actions/getGroups';
import GroupsTable from './components/GroupsTable';
import { actions as reduxActions } from './reducers';

function Groups({ connectClientId, fetchGroups, resetGroups }) {
  useEffect(() => {
    fetchGroups(connectClientId);
    return resetGroups;
    // eslint-disable-next-line
  }, [connectClientId]);

  return (
    <>
      <GroupsTable />
    </>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  resetGroups: reduxActions.resetGroups,
  fetchGroups
};

export default connect(mapStatesToProps, mapDispatchToProps)(Groups);
