import { createReduxActionTypes } from '../../../utils/actionFactory';
import Optional from '../../../utils/optional';

const OPEN_DIALOG = 'OPEN_DIALOG';
const RESET_DIALOG = 'RESET_DIALOG';
const SET_COMPLETED = 'SET_COMPLETED';
const SET_TAB_INDEX = 'SET_TAB_INDEX';

export const actionTypes = createReduxActionTypes('SWAP_SIMCARD', [
  OPEN_DIALOG,
  RESET_DIALOG,
  SET_COMPLETED,
  SET_TAB_INDEX
]);

const DEFAULT_STATE = {
  open: false,
  simcard: {},
  completed: false,
  tabIndex: 0,
  problems: void 0
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      const { simcard, fromEdit, problems } = action;
      return {
        ...state,
        simcard: { ...simcard },
        open: true,
        fromEdit: Optional(fromEdit).or(false),
        problems
      };
    }
    case actionTypes[SET_TAB_INDEX]: {
      const { tabIndex } = action;
      return { ...state, tabIndex };
    }
    case actionTypes[SET_COMPLETED]: {
      const { new_sim_card_icc } = action;
      return { ...state, completed: true, new_sim_card_icc };
    }
    case actionTypes[RESET_DIALOG]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDialog: ({ simcard, fromEdit, problems }) => ({
    type: actionTypes[OPEN_DIALOG],
    simcard,
    fromEdit,
    problems
  }),
  setCompleted: new_sim_card_icc => ({
    type: actionTypes[SET_COMPLETED],
    new_sim_card_icc
  }),
  setTabIndex: tabIndex => ({
    type: actionTypes[SET_TAB_INDEX],
    tabIndex
  }),
  reset: () => ({ type: actionTypes[RESET_DIALOG] })
};
