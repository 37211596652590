const styles = theme => {
  return {
    root: { height: '260px', color: '#646C9A' },
    rechargeLabel: { paddingLeft: '10px' },
    infoIcon: {
      marginLeft: '5px'
    },
    expired: {
      textAlign: 'end',
      fontSize: '12px',
      marginLeft: '10px',
      paddingRight: '50px',
      marginTop: '5px'
    },
    hidden: {
      visibility: 'hidden'
    }
  };
};

export default styles;

export const CUSTOM_STYLES = {
  checkboxRow: {
    label: {
      width: '170px'
    }
  },
  checkboxRowRecharge: {
    label: {
      width: '170px',
      paddingLeft: '20px'
    }
  }
};
