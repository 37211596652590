import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_ORDER_SIMCARD_WIZARD = 'OPEN_ORDER_SIMCARD_WIZARD';
const RESET_ORDER_SIMCARD_WIZARD = 'RESET_ORDER_SIMCARD_WIZARD';
const SET_SIMCARD_DATA = 'SET_SIMCARD_DATA';
const SET_ADDRESSES_OPTIONS = 'SET_ADDRESSES_OPTIONS';
const SET_ACCOUNTS_OPTIONS = 'SET_ACCOUNTS_OPTIONS';

export const actionTypes = createReduxActionTypes(
  'REQUEST_ORDER_SIMCARD_WIZARD',
  [
    OPEN_ORDER_SIMCARD_WIZARD,
    SET_SIMCARD_DATA,
    SET_ADDRESSES_OPTIONS,
    SET_ACCOUNTS_OPTIONS,
    RESET_ORDER_SIMCARD_WIZARD
  ]
);

const DEFAULT_STATE = {
  open: false,
  simcardData: {
    firstStep: {
      provider: '',
      count: 0,
      account: ''
    },
    secondStep: {
      shipping_address: '',
      name: '',
      att: '',
      additionalValues: null
    }
  },
  addressesOptions: [],
  accountsOptions: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_ORDER_SIMCARD_WIZARD]: {
      return {
        ...state,
        open: true
      };
    }
    case actionTypes[SET_SIMCARD_DATA]: {
      const { simcardData, step } = action;
      return {
        ...state,
        simcardData: {
          ...state.simcardData,
          [step]: { ...state.simcardData[step], ...simcardData }
        }
      };
    }
    case actionTypes[SET_ADDRESSES_OPTIONS]: {
      return {
        ...state,
        addressesOptions: [...action.addressesOptions]
      };
    }
    case actionTypes[SET_ACCOUNTS_OPTIONS]: {
      const { accountsOptions } = action;
      return {
        ...state,
        accountsOptions
      };
    }
    case actionTypes[RESET_ORDER_SIMCARD_WIZARD]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openOrderSimcard: () => ({
    type: actionTypes[OPEN_ORDER_SIMCARD_WIZARD]
  }),
  setSimcardData: (simcardData, step) => ({
    type: actionTypes[SET_SIMCARD_DATA],
    simcardData,
    step
  }),
  setAddressesOptions: addressesOptions => ({
    type: actionTypes[SET_ADDRESSES_OPTIONS],
    addressesOptions
  }),
  setAccountOptions: accountsOptions => ({
    type: actionTypes[SET_ACCOUNTS_OPTIONS],
    accountsOptions
  }),
  resetOrderSimcard: () => ({ type: actionTypes[RESET_ORDER_SIMCARD_WIZARD] })
};
