import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import { actions as reduxActions } from '../reducers';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import getVacations from '../actions/getVacations';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import { translate } from '../../../i18n/I18nProvider';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { actions as editVacationReduxActions } from '../../../modals/editVacation/reducers';
import AddButton from '../../../partials/tableCustomButtons/AddButton';

const VacationsTable = ({
  vacations,
  columns,
  setColumns,
  getVacations,
  isLoading,
  openEditVacation
}) => {
  const [filtering, setFiltering] = useState(false);

  useEffect(() => {
    getVacations();
    // eslint-disable-next-line
  }, []);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Vacations-2'
    });
  }, [setColumns, tableDefaultColumns]);

  const actions = useMemo(
    () => [
      {
        icon: () => <FilterButton />,
        onClick: () => {
          setFiltering(!filtering);
        },
        isFreeAction: true,
        tooltip: translate('BUTTON.FILTER')
      },
      {
        icon: () => <AddButton buttonText="BUTTON.ADD" />,
        onClick: () => openEditVacation(),
        isFreeAction: true,
        tooltip: translate('VACATIONS.ADD_VACATION')
      }
    ],
    // eslint-disable-next-line
    [filtering]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Vacations-2'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.VACATIONS"
            isLoading={isLoading}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={vacations}
        options={options}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
      />
    </>
  );
};

const mapStatesToProps = ({ vacations, loader }) => {
  return {
    ...vacations,
    isLoading: loader.isSpinVisible
  };
};

const mapDispatchToProps = {
  getVacations,
  setColumns: reduxActions.setColumns,
  openEditVacation: editVacationReduxActions.openEditVacation
};

export default flowRight(connect(mapStatesToProps, mapDispatchToProps))(
  VacationsTable
);
