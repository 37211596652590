import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';
const SET_PROMPTS = 'SET_PROMPTS';
const SET_VACATIONS = 'SET_VACATIONS';
const SET_ERRORS = 'SET_ERRORS';
const CLOSE_SUMMARY_DIALOG = 'CLOSE_SUMMARY_DIALOG';
const OPEN_SUMMARY_DIALOG = 'OPEN_SUMMARY_DIALOG'

export const actionTypes = createReduxActionTypes('ADDING_SCHEDULES', [
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_PROMPTS,
  SET_VACATIONS,
  SET_ERRORS,
  OPEN_SUMMARY_DIALOG,
  CLOSE_SUMMARY_DIALOG
]);

const DEFAULT_STATE = {
  open: false,
  schedules: [],
  customErrors: {},
  prompts: [],
  vacations: [],
  summaryOpen: false,
  summaryInfo: void 0
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      const { schedules } = action;
      return {
        ...state,
        open: true,
        schedules
      };
    }
    case actionTypes[SET_PROMPTS]: {
      const { prompts } = action;
      return {
        ...state,
        prompts
      };
    }
    case actionTypes[SET_VACATIONS]: {
      const { vacations } = action;
      return {
        ...state,
        vacations
      };
    }
    case actionTypes[SET_ERRORS]: {
      const { customErrors } = action;
      return {
        ...state,
        customErrors
      };
    }
    case actionTypes[OPEN_SUMMARY_DIALOG]: {
      const { summaryInfo } = action;
      return {
        ...state,
        summaryOpen: true,
        summaryInfo
      };
    }
    case actionTypes[CLOSE_SUMMARY_DIALOG]: {
      return {
        ...DEFAULT_STATE
      };
    }
    case actionTypes[CLOSE_DIALOG]: {
      return {
        ...DEFAULT_STATE,
        summaryOpen: state.summaryOpen,
        summaryInfo: state.summaryInfo
      };
    }
    default:
      return state;
  }
};

export const actions = {
  openDiaog: ({ schedules }) => ({
    type: actionTypes[OPEN_DIALOG],
    schedules
  }),
  setPrompts: prompts => ({
    type: actionTypes[SET_PROMPTS],
    prompts
  }),
  setVacations: vacations => ({
    type: actionTypes[SET_VACATIONS],
    vacations
  }),
  setErrors: customErrors => ({
    type: actionTypes[SET_ERRORS],
    customErrors
  }),
  openSummaryDialog: summaryInfo => ({
    type: actionTypes[OPEN_SUMMARY_DIALOG],
    summaryInfo
  }),
  closeSummaryDialog: () => ({ type: actionTypes[CLOSE_SUMMARY_DIALOG] }),
  closeDialog: () => ({ type: actionTypes[CLOSE_DIALOG] })
};
