import React from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { openEditSchedule } from '../../../modals/editSchedule/actions/setSchedule';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

function AddSchedule({ openEditSchedule, group = {}, clientId }) {

  return (
    <div>
      {can(
        'create',
        createSubject('Mitel::Schedule', { client_id: Number(clientId) })
      ) && (
        <CustomIconButton
          iconTooltipIntlKey="EDIT_SCHEDULE.ADD_NEW"
          onClick={() =>
            openEditSchedule({
              schedule: null,
              group,
              isEdit: false
            })
          }
          iconClassName="far fa-plus"
        />
      )}
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  openEditSchedule
};

export default flowRight(connect(mapStatesToProps, mapDispatchToProps))(
  AddSchedule
);
