import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import invoicesTableColumns from './invoicesTableColumns';
import { fetchInvoices } from '../actions/getInvoices';
import InvoiceTableTitle from './InvoiceTableTitle';
import SelectAccount from './SelectAccount';
import { MuiThemeProvider } from '@material-ui/core';
import { themes } from '../styles';
import InvoiceDetails from '../../../modals/invoiceDetails/InvoiceDetails';
import Optional from '../../../utils/optional';
import  InvoicesActionButtons  from './InvoicesActionButtons';

export function InvoicesTable({
  fetchInvoices,
  clientId,
  selectedAccount,
  isLoading,
  selectOptions
}) {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (Object.keys(selectedAccount).length > 0) {
      fetchInvoices({
        id: selectedAccount.externalReference,
        setInvoices,
        clientId
      });
    }
    // eslint-disable-next-line
  }, [selectedAccount, clientId]);

  const tableDefaultColumns = useMemo(() => {
    return invoicesTableColumns({ clientId, selectedAccount });
  }, [selectedAccount, clientId]);

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      columnsButton: false,
      padding: 'default',
      toolbarButtonAlignment: 'left'
    };
  }, []);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const actions = useMemo(
    () => {
      return [
        {
          icon: () => <SelectAccount />,
          onClick: () => {},
          isFreeAction: true
        }
      ];
    },
    // eslint-disable-next-line
    []
  );

  const dataToShow = useMemo(() => {
    if (selectedAccount.value === 'all') {
      return invoices.map(invoice => {
        const account = selectOptions.find(
          option => option.externalReference === invoice.accountReference
        );

        return { ...invoice, account: Optional(account?.label).or('') };
      });
    }

    return invoices;
  }, [invoices, selectedAccount, selectOptions]);

  const detailPanel = useCallback(
    ({ rowData }) => <InvoicesActionButtons id={rowData.invoiceNumber} />,
    []
  );

  return (
    <MuiThemeProvider theme={themes.table}>
      <MaterialTable
        title={<InvoiceTableTitle />}
        columns={tableDefaultColumns}
        data={dataToShow}
        options={tableOptions}
        localization={localization}
        actions={actions}
        isLoading={isLoading}
        detailPanel={detailPanel}
      />
      <InvoiceDetails />
    </MuiThemeProvider>
  );
}

const mapStatesToProps = ({ invoices, loader, selectClient }) => {
  return {
    ...invoices,
    isLoading: loader.isSpinVisible,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  fetchInvoices
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(InvoicesTable);
