import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles/completeStep';
import closeSwapCard from '../actions/closeSwapCard';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';

function CompleteStep({
  intl,
  classes,
  closeSwapCard,
  simcard = {},
  new_sim_card_icc = '',
  supportNumber
}) {
  const is3Network = useMemo(() => simcard.mobile_network?.brand === '3', [
    simcard
  ]);

  return is3Network ? (
    <div className={classes.root}>
      <p>
        {intl.formatMessage({
          id: 'SWAP_SIMCARD.INFO_TEXT_2_3_NETWORK'
        })}
      </p>
      <p>
        {intl.formatMessage({
          id: 'SWAP_SIMCARD.INFO_TEXT_3_3_NETWORK'
        })}
      </p>
      <div className={classes.footer}>
        <SecondaryButton onClick={closeSwapCard}>
          {intl.formatMessage({
            id: 'BUTTON.CLOSE'
          })}
        </SecondaryButton>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <p>
        {intl.formatMessage({
          id: 'SWAP_SIMCARD.COMPLETED_FIRST_PART_1'
        })}{' '}
        <span>{simcard.msisdn}</span>{' '}
        {intl.formatMessage({
          id: 'SWAP_SIMCARD.COMPLETED_FIRST_PART_2'
        })}{' '}
        <span>{new_sim_card_icc}</span>.
      </p>
      <p>
        {intl.formatMessage({
          id: 'SWAP_SIMCARD.COMPLETED_SECOND_PART'
        })}
      </p>
      <p>
        {intl.formatMessage({
          id: 'SWAP_SIMCARD.COMPLETED_THIRD_PART'
        })}{' '}
        <span>
          <a href={`tel:${supportNumber}`}>{supportNumber}</a>
        </span>
        .
      </p>
      <div className={classes.footer}>
        <SecondaryButton onClick={closeSwapCard}>
          {intl.formatMessage({
            id: 'BUTTON.CLOSE'
          })}
        </SecondaryButton>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ swapSimcard, builder }) => {
  return {
    ...swapSimcard,
    supportNumber: builder.settings.supportNumber
  };
};

const mapDispatchToProps = {
  closeSwapCard
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(CompleteStep);
