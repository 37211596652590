export default {
  root: {
    width: '450px',
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'column'
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  messageContainer: {
    fontSize: '10px',
    visibility: 'hidden',
    textAlign: 'center',
    color: '#F00'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px'
  },
  buttonContainer: { marginTop: '12px' },
  messageVisible: {
    visibility: 'visible'
  }
};

export const customStyles = {
  textfieldRoot: { width: '390px' }
};
