import React from 'react';

import CustomCopyToClipboard from '../../../partials/copyToClipboard.js/CustomCopyToClipboard';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import searchInArray from '../../../utils/searchInArray';
import searchNumbers from '../../../utils/searchNumbers';
import CellNumbersTableCell from '../../../partials/CellNumbersTableCell/CellNumbersTableCell';
import TableNameCell from '../../../partials/tableNameCell/TableNameCell';
import StatusCell from '../../../partials/statusCell/StatusCell';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import LockUserTableCell from '../../../partials/lockUserTableCell/LockUserTableCell';
import formatDate from '../../../utils/formatDate';
import TableActionButtons from './TableActionButtons';
import Optional from '../../../utils/optional';
import { isUndefined } from '../../../utils/isDefined';
import customTableColumnSort from '../../../utils/customTableColumnSort';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';
import { translate } from '../../../i18n/I18nProvider';

export default function tableColumns({
  client,
  lockUserAction,
  filtersOptions = {},
  settings
}) {
  const columns = [
    {
      render: rowData => (
        <TableActionButtons
          data={rowData}
          clientId={Optional(client).or(rowData.client_id)}
        />
      ),
      // cellStyle: styles.actionCell,
      disableClick: true,
      sorting: false,
      width: '40px'
    },
    {
      title: 'TABLE.NAME',
      render: rowData => (
        <TableNameCell
          data={[rowData]}
          from="telephonyUsers"
          clientId={client}
        />
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name',
      customSort: (a, b) => customTableColumnSort(a, b, 'name'),
      width: '150px'
    },
    {
      title: 'USER.CUSTOM_ID',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.custom_id, term);
      },
      field: 'custom_id',
      hidden: true
    },
    {
      title: 'USER.DOMAIN_NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.user_id, term);
      },
      field: 'user_id',
      width: '100px'
    },
    {
      title: 'TABLE.EMAIL',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.email, term);
      },
      field: 'email',
      width: '200px'
    },
    {
      title: 'TABLE.DEPARTMENT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.department, term);
      },
      field: 'department',
      lookup: filtersOptions.department,
      width: '120px'
    },
    {
      title: 'USER.LICENSE_TYPE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.licenseType, term);
      },
      field: 'licenseType',
      lookup: filtersOptions.licenseType,
    },
    {
      title: 'TABLE.ADMIN_ROLE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.admin_role, term);
      },
      field: 'admin_role',
      lookup: filtersOptions.adminRole,
      width: '110px'
    },
    {
      title: 'TABLE.PRIMARY_LINE',
      field: 'primary_line',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.primary_line, term);
      },
      render: rowData => <CustomCopyToClipboard value={rowData.primary_line} />,
      width: '140px',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'TABLE.PRIMARY_TYPE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.primary_type, term);
      },
      field: 'primary_type',
      width: '140px',
      hidden: true
    },
    {
      title: 'TABLE.PRIMARY_ALIAS',
      customSort: (a, b) =>
        sortFirstArrayElement(a.primary_aliases, b.primary_aliases),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: rowData.primary_aliases,
          term,
          isValueNumber: true
        }),
      render: rowData => (
        <CellNumbersTableCell data={rowData.primary_aliases} />
      ),
      width: '140px',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'TABLE.SECONDARY_LINE',
      field: 'secondary_line',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.secondary_line, term);
      },
      render: rowData => (
        <CustomCopyToClipboard value={rowData.secondary_line} />
      ),
      width: '140px',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'TABLE.SECONDARY_TYPE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.secondary_type, term);
      },
      field: 'secondary_type',
      width: '140px',
      hidden: true
    },
    {
      title: 'TABLE.SECONDARY_ALIAS',
      customSort: (a, b) =>
        sortFirstArrayElement(a.secondary_aliases, b.secondary_aliases),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: rowData.secondary_aliases,
          term,
          isValueNumber: true
        }),
      render: rowData => (
        <CellNumbersTableCell data={rowData.secondary_aliases} />
      ),
      width: '140px',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'REQUEST_NEW_USER.COST_CENTER',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.cost_center, term);
      },
      field: 'cost_center',
      hidden: true
    },
    {
      title: 'LANGUAGE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.language, term);
      },
      field: 'language',
      width: '100px',
      hidden: true
    },
    {
      title: 'TABLE.MOBILES',
      customSort: (a, b) => sortFirstArrayElement(a.mobiles, b.mobiles),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: rowData.mobiles,
          term,
          isValueNumber: true
        }),
      render: rowData => <CellNumbersTableCell data={rowData.mobiles} />,
      width: '120px',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'SOFTPHONE',
      field: 'softphone',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.softphone
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      }
    },
    {
      title: 'MOBILE_APP',
      field: 'mobile_app',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.mobile_app
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      }
    },
    {
      title: 'SIP_DESKTOP_PHONE',
      field: 'sip_phones'
    },
    {
      title: 'SIP_PHONE_3RD',
      field: 'third_party_phone_type',
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.PHONE_TYPES)
      }
    },
    {
      title: 'IPAD_SUPPORT',
      field: 'ipad_support',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.ipad_support
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      hidden: true
    },
    {
      title: 'USER.LOCATION',
      field: 'location',
      hidden: true
    },
    {
      title: `${translate('USER.ACCES_TO_SELFCARE')} ${Optional(
        settings?.title
      ).or('selfacre')}`,
      untranslatedTitle: true,
      field: 'locked',
      render: rowData => (
        <LockUserTableCell data={rowData} lockUserAction={lockUserAction} />
      ),
      sorting: false,
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.LOCK)
      }
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (can('manage', createSubject('User', { client_id: Number(client) }))) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}

function sortFirstArrayElement(a = [], b = []) {
  const firstElement = a[0];
  const secondElement = b[0];
  if (isUndefined(firstElement)) return -1;
  if (isUndefined(secondElement)) return 1;
  if (firstElement === secondElement) return 0;
  return firstElement < secondElement ? -1 : 1;
}
