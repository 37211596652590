const styles = () => {
  return {
    root: { width: '650px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      width: '650px'
    },
    scrollPaper: {
      alignItems: 'start'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '20px'
    },
    nameRow: {
      display: 'flex',
      alignItems: 'center'
    },
    number: { paddingLeft: '30px' },
    typeContainer: { display: 'flex' },
    icon: { cursor: 'pointer', margin: '0 5px 0 -20px' },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      padding: '30px 0',
      marginTop: '30px',
      '& button': {
        marginRight: '10px'
      }
    },
    tableOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    errorMessage: {
      fontSize: '8px',
      color: '#FD397A'
    }
  };
};

export default styles;

export const customStyles = {
  select: {
    typeSelect: {
      root: { marginRight: 0 }
    },
    vacationSelect: {
      root: { marginRight: 0, width: '200px' },
      label: { display: 'none' },
      value: { width: '200px' }
    }
  },
  input: {
    name: {
      root: { marginRight: 0 }
    }
  }
};
