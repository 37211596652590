import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { getPartners } from './actions/getPartners';
import { actions as reduxActions } from './reducers';
import { mapTableOptions, setLocalization } from '../../consts/tableOptions';
import withDetailsView from '../../utils/withDetailsView';
import CustomTableTitle from '../../partials/CustomTableTitle';
import FilterButton from '../../partials/tableCustomButtons/FilterButton';
import tableColumns from './components/tableColumns';
import { doesArrayHasLength } from '../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../utils/localstorageUtils';
import customTableIcons from '../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../utils/filterCustomJobs';
import CustomJobsMenu from '../../partials/customJobsMenu/CustomJobsMenu';

function Partners({
  getPartners,
  intl,
  partners,
  resetPartners,
  isLoading,
  columns,
  setColumns,
  customJobs
}) {
  const [filtering, setFiltering] = useState(false);

  useEffect(() => {
    getPartners();
    return resetPartners;
    // eslint-disable-next-line
  }, []);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Partners-1'
    });
  }, [setColumns, tableDefaultColumns]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Partners');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering,
      header: doesArrayHasLength(partners)
    };
  }, [filtering, partners]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Partners-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MaterialTable
      title={
        <CustomTableTitle
          text="MENU.PARTNERS"
          icon={<i className="fas fa-handshake"></i>}
          isLoading={isLoading}
        />
      }
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={partners}
      options={options}
      localization={localization}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ partners, loader, auth }) => {
  return {
    ...partners,
    isLoading: loader.isSpinVisible,
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  getPartners,
  resetPartners: reduxActions.resetPartners,
  setColumns: reduxActions.setColumns
};

export default flowRight(
  injectIntl,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps)
)(Partners);
