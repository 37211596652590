import { InputAdornment, TextField, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import {
  isDefined,
  isStringEmpty,
  isUndefined
} from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import validateEuicc from '../actions/validateEuicc';
import styles, { customStyles } from '../styles/validateEuicc';
import Optional from '../../../utils/optional';
import { EUICC_NETWORK_PREFIX } from '../../../consts/initialValues';

function ValidateEuicc({
  intl,
  classes,
  values,
  setFieldValue,
  clientId,
  settings,
  name,
  disabled = false,
  shouldReset = false,
  network
}) {
  const [euiccInfo, setEuiccInfo] = useState({});
  const [euicc, setEuicc] = useState('');

  useEffect(() => {
    setEuiccInfo({});
  }, [shouldReset, network]);

  const EUICCNumberPrefix = useMemo(() => {
    return findEUICCdPrefix(network);
  }, [network]);

  const onValidate = useCallback(
    async () => {
      setEuiccInfo({});
      setFieldValue(name, '');
      const { icc, info } = await validateEuicc({
        euicc,
        currentNetwork: values.network,
        clientId
      });
      if (isDefined(info)) {
        setEuiccInfo(info);
      }
      if (isDefined(icc)) {
        setFieldValue(name, icc);
        setEuicc('');
      }
    },
    // eslint-disable-next-line
    [values, clientId, name, euicc]
  );

  const onChange = useCallback(
    e => {
      setEuicc(e.target.value);

      if (isStringEmpty(e.target.value) && euiccInfo?.isError) {
        setEuiccInfo({});
      }
    },
    [setEuicc, euiccInfo]
  );

  return (
    <div className={classes.root}>
      {intl.formatMessage({
        id: 'SWAP_SIMCARD.INFO_TEXT_2'
      })}
      <div className={classes.inputRow}>
        <div className={classes.inputContainer}>
          <TextField
            value={euicc}
            onChange={onChange}
            error={euiccInfo?.isError}
            variant="outlined"
            style={customStyles.textfieldRoot}
            margin="dense"
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ fontSize: '10px' }}>
                  {EUICCNumberPrefix}
                </InputAdornment>
              )
            }}
            placeholder={intl.formatMessage({
              id: 'SWAP_SIMCARD.PLACEHOLDER_EUICC'
            })}
            inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
          />
          <div
            className={clsx(classes.messageContainer, {
              [classes.messageVisible]: isDefined(euiccInfo?.text)
            })}
            style={{
              color: euiccInfo?.isError
                ? settings.colors.danger
                : settings.colors.success
            }}
          >
            {euiccInfo.text}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <PrimaryButton onClick={onValidate} disabled={isStringEmpty(euicc)}>
            VALIDATE
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(ValidateEuicc);

function findEUICCdPrefix(network) {
  if (isUndefined(network)) {
    return '';
  }

  return Optional(EUICC_NETWORK_PREFIX[network]).or('');
}
