const styles = theme => {
  return {
    dialogContent: {
      overflow: 'visible'
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '300px',
      justifyContent: 'space-between'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
      '& button': {
        marginRight: '10px'
      }
    }
  };
};

export default styles;

export const customStyles = {
  inputRow: {
    root: { marginRight: 0, width: '300px' },
    field: { flexDirection: 'column', width: '100%' },
    label: { fontWeight: '600' },
    textfield: { width: '100%' }
  },
  dayRow: {
    root: { marginRight: 0, width: '50px' },
    field: { flexDirection: 'column', width: '100%' },
    label: { fontWeight: '600' },
    textfield: { width: '100%' }
  },
  checkbox: {
    root: {
      width: '100%',
      alignItems: 'flex-start'
    },
    label: { fontWeight: '600' }
  },
  typeSelect: {
    root: { marginRight: 0, width: '300px' },
    field: { flexDirection: 'column' },
    label: { fontWeight: '600' },
    value: { width: '300px' }
  },
  monthSelect: {
    root: { marginRight: 0, width: '200px' },
    label: { display: 'none' }
  }
};
