import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import DeleteVacationConfimation from './DeleteVacationConfimation';
import deleteVacation from '../actions/deleteVacation';
import getVacations from '../actions/getVacations';
import {actions as editVacationReduxActions} from '../../../modals/editVacation/reducers'

export function TableActionButtons({
  data: vacation,
  classes,
  getVacations,
  openEditVacation
}) {
  const onEditClick = useCallback(
    () => {
      openEditVacation(vacation, true);
    },
    // eslint-disable-next-line
    [vacation]
  );

  const onDeleteConfirmation = useCallback(
    async () => {
      const isDelated = await deleteVacation({ id: vacation.id });

      if (isDelated) {
        getVacations();
      }
    },
    // eslint-disable-next-line
    [vacation.id]
  );

  return (
    <div className={classes.actionButtons}>
      <ConfirmDialog
        onConfirmClick={onDeleteConfirmation}
        dialogContentComponent={<DeleteVacationConfimation />}
        dialogTitleIntlId={'VACATIONS.DELETE_VACATION'}
      >
        <CustomIconButton
          disabledIconTooltipIntlKey="TOOLTIP.DELETE_PERMISSION_INFO"
          iconTooltipIntlKey="VACATIONS.DELETE_VACATION"
          iconClassName="far fa-trash-alt"
        />
      </ConfirmDialog>
      <CustomIconButton
        iconTooltipIntlKey="VACATIONS.EDIT_VACATION"
        onClick={onEditClick}
        iconClassName="far fa-edit"
      />
    </div>
  );
}

const mapDispatchToProps = {
  getVacations,
  openEditVacation: editVacationReduxActions.openEditVacation
};

export default flowRight(
  injectIntl,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
