import React, { useCallback, useMemo, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import validateSchema from '../actions/validateSchema';
import styles, { customStyles } from '../styles/newNumberStep';
import {
  CONST_VALUES,
  EUICC_NETWORK_PREFIX,
  PROVIDERS_NUMBERS
} from '../../../consts/initialValues';
import swapCard from '../actions/swapCard';
import closeSwapCard from '../actions/closeSwapCard';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import {
  isDefined,
  isStringEmpty,
  isUndefined
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import validateEuicc from '../actions/validateEuicc';
import withSettings from '../../../utils/withSettings';

export function NewNumberStep({
  classes,
  intl,
  closeSwapCard,
  simcard = {},
  swapCard,
  isLoading,
  settings
}) {
  const [euiccInfo, setEuiccInfo] = useState({});
  const [icc, setIcc] = useState('');

  const numberPrefix = useMemo(() => {
    return findSimcardPrefix(simcard.mobile_network);
  }, [simcard.mobile_network]);

  const EUICCNumberPrefix = useMemo(() => {
    return findEUICCdPrefix(simcard.mobile_network);
  }, [simcard.mobile_network]);

  const onSubmit = useCallback(
    values => {
      swapCard({
        id: simcard.id,
        prefix: numberPrefix,
        values
      });
    },
    // eslint-disable-next-line
    [simcard]
  );

  const onValidate = useCallback(
    async euicc => {
      setEuiccInfo({});
      setIcc('');
      const { icc, info } = await validateEuicc({
        euicc,
        currentNetwork: simcard.mobile_network,
        clientId: simcard.client_id
      });
      if (isDefined(info)) {
        setEuiccInfo(info);
      }
      if (isDefined(icc)) {
        setIcc(icc);
      }
    },
    [simcard]
  );

  return (
    <Formik
      initialValues={{ new_sim_card_icc: icc, euIcc: '' }}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validateSchema(numberPrefix)}
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        errors,
        setFieldError,
        setFieldValue
      }) => {
        return (
          <Form className={classes.content} onSubmit={handleSubmit}>
            {intl.formatMessage({
              id: 'SWAP_SIMCARD.ENTER_NUMBER'
            })}
            <div className={classes.iccContainer}>
              <TextField
                style={customStyles.textfieldRoot}
                name="new_sim_card_icc"
                value={values.new_sim_card_icc}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('new_sim_card_icc', e.target.value);
                  setFieldError('new_sim_card_icc', '');
                }}
                error={Boolean(errors.new_sim_card_icc)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {numberPrefix}
                    </InputAdornment>
                  )
                }}
                placeholder={intl.formatMessage({
                  id: 'SWAP_SIMCARD.PLACEHOLDER'
                })}
                inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
              />
              <div
                className={clsx(classes.messageContainer, {
                  [classes.messageVisible]: Boolean(errors.new_sim_card_icc)
                })}
              >
                {errors.new_sim_card_icc}
              </div>
              {numberPrefix !== 894506 ? (
                <>
                  <div className={classes.infoText}>
                    {intl.formatMessage({
                      id: 'SWAP_SIMCARD.INFO_TEXT_2'
                    })}
                  </div>
                  <TextField
                    style={customStyles.textfieldRootEuicc}
                    name="euIcc"
                    value={values.euIcc}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(errors.euIcc)}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ fontSize: '10px' }}
                        >
                          {EUICCNumberPrefix}
                        </InputAdornment>
                      )
                    }}
                    placeholder={intl.formatMessage({
                      id: 'SWAP_SIMCARD.PLACEHOLDER_EUICC'
                    })}
                    inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
                  />
                  <div
                    className={clsx(classes.messageContainer, {
                      [classes.messageVisible]: isDefined(euiccInfo.text)
                    })}
                    style={{
                      color: euiccInfo.isError
                        ? settings.colors.danger
                        : settings.colors.success
                    }}
                  >
                    {euiccInfo.text}
                  </div>
                  <PrimaryButton
                    onClick={() => onValidate(values.euIcc)}
                    disabled={isStringEmpty(values.euIcc)}
                    isLoading={isLoading}
                  >
                    VALIDATE
                  </PrimaryButton>
                  <div className={classes.infoText}>
                    {intl.formatMessage({
                      id: 'SWAP_SIMCARD.INFO_TEXT'
                    })}
                  </div>
                </>
              ) : (
                <div className={classes.infoText}>
                  {intl.formatMessage({
                    id: 'SWAP_SIMCARD.INFO_TEXT_1_3_NETWORK'
                  })}
                </div>
              )}
            </div>
            <footer className={classes.footer}>
              <PrimaryButton
                onClick={handleSubmit}
                disabled={isStringEmpty(values.new_sim_card_icc)}
                isLoading={isLoading}
              >
                {intl.formatMessage({
                  id: 'BUTTON.SUBMIT'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={closeSwapCard} isLoading={isLoading}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}
const mapStatesToProps = ({ swapSimcard, loader }) => {
  return {
    simcard: swapSimcard.simcard,
    isLoading: loader.isLinearVisible
  };
};

const mapDispatchToProps = {
  closeSwapCard,
  swapCard
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NewNumberStep);

function findSimcardPrefix(mobile_network = {}) {
  const { brand } = mobile_network;
  if (isUndefined(brand)) {
    return CONST_VALUES.ICC_CONST;
  }

  return Optional(PROVIDERS_NUMBERS[brand]).or(CONST_VALUES.ICC_CONST);
}

function findEUICCdPrefix(mobile_network = {}) {
  const { brand } = mobile_network;
  if (isUndefined(brand)) {
    return '';
  }

  return Optional(EUICC_NETWORK_PREFIX[brand]).or('');
}
