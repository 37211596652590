import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import { Dialog, TextField } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import styles, { customStyles } from './styles';
import { actions as reduxActions } from './reducers';
import ActionsButtons from './components/ActionsButtons';
import InputRow from '../../partials/dataRows/InputRow';
import SelectRow from '../../partials/dataRows/SelectRow';
import SELECTS_OPTIONS from '../../consts/selectsOptions';
import translateOptionslLabels from '../../utils/translateOptionsLabels';
import { getPrompts } from './actions/getAllPrompts';
import DaysElement from './components/DaysElement';
import DatesElement from './components/DatesElement';
import EndElement from './components/EndElement';
import CheckboxElement from './components/CheckboxesElement';
import { updateOnSubmit } from './actions/updateSchedule';
import validateOnSubmit from './actions/validateForm';
import InfoBox from './components/InfoBox';
import DraggableDialog from '../../partials/DraggableDialog';
import Optional from '../../utils/optional';
import PromptRow from './components/PromptRow';
import { getVacations } from './actions/getVacations';
import setSystemVactionsValues from './actions/setSystemVactionsValues';
import { translate } from '../../i18n/I18nProvider';

const EditSchedule = ({
  classes,
  open,
  schedule,
  resetSchedule,
  connectClientId,
  getPrompts,
  prompts: promptsOptions,
  isEdit = true,
  updateOnSubmit,
  group,
  customErrors,
  getVacations,
  vacations
}) => {
  const [systemDateSelected, setSytemDateSelected] = useState(false);

  useEffect(() => {
    if (open) {
      getPrompts(connectClientId);
      getVacations();
    }

    return setSytemDateSelected(false);
    // eslint-disable-next-line
  }, [connectClientId, open]);

  const onSubmit = useCallback(
    async values => {
      const isValidated = await validateOnSubmit({
        values,
        group,
        initialValues: schedule
      });

      if (isValidated) {
        updateOnSubmit({
          schedule: values,
          number: group.number,
          connectClientId,
          isEdit
        });
      }
    },
    // eslint-disable-next-line
    [group, connectClientId, isEdit]
  );

  const title = useMemo(() => {
    return translate(isEdit ? 'EDIT_SCHEDULE.TITLE' : 'EDIT_SCHEDULE.ADD_NEW');
  }, [isEdit]);

  const repeatOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_REPEAT_OPTIONS);
  }, []);

  const typeOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_TYPE_OPTIONS);
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        container: classes.container,
        paper: classes.dialogContent,
        scrollPaper: classes.scrollPaper
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          resetSchedule();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={resetSchedule}>
        {title}
      </CustomDialogTitle>
      <Formik
        initialValues={schedule}
        enableReinitialize
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          dirty,
          values,
          handleBlur,
          handleChange,
          setFieldValue
        }) => {
          const fieldProps = {
            values,
            handleBlur,
            handleChange,
            setFieldValue
          };

          const errors = customErrors;

          const onArrowsClick = () => {
            setSystemVactionsValues({ vacations, values, setFieldValue });

            return setSytemDateSelected(true);
          };

          return (
            <>
              {Object.keys(errors).length > 0 && <InfoBox />}
              <Form className={classes.content} onSubmit={handleSubmit}>
                {values.name !== 'default' && (
                  <>
                    <div className={classes.nameRow}>
                      <InputRow
                        label="NAME"
                        name="name"
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyles={customStyles.input.name}
                      />
                      <i
                        className={clsx(
                          'far fa-chevron-double-left',
                          classes.icon
                        )}
                        onClick={onArrowsClick}
                      ></i>
                      <SelectRow
                        name="vacations"
                        label="NAME"
                        isEditModal={false}
                        options={vacations}
                        customStyles={customStyles.select.vacationSelect}
                        {...fieldProps}
                      />
                    </div>
                    <SelectRow
                      name="recurrenceType"
                      label="SCHEDULE_REPEAT"
                      options={repeatOptions}
                      isDisabled={systemDateSelected}
                      {...fieldProps}
                    />
                    {values.recurrenceType === 'WEEKLY' && (
                      <Field component={DaysElement} />
                    )}
                    {(values.recurrenceType === 'DAILY' ||
                      values.recurrenceType === 'WEEKLY') && (
                      <Field component={CheckboxElement} />
                    )}

                    <Field
                      component={DatesElement}
                      systemDateSelected={systemDateSelected}
                    />

                    {(values.recurrenceType === 'MONTHLY' ||
                      values.recurrenceType === 'YEARLY') && (
                      <Field component={EndElement} />
                    )}
                  </>
                )}
                <Field component={PromptRow} promptsOptions={promptsOptions} />

                <div className={classes.typeContainer}>
                  <SelectRow
                    name="type"
                    label="SCHEDULE_TYPE"
                    customStyles={customStyles.select.typeSelect}
                    options={typeOptions}
                    {...fieldProps}
                  />
                  {values.type === 'FORWARD' && (
                    <div>
                      <TextField
                        name="forwardNumber"
                        value={Optional(values.forwardNumber).or('')}
                        margin="dense"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.forwardNumber)}
                        inputProps={{
                          autoComplete: 'off',
                          'data-lpignore': true
                        }}
                      />
                      <div className={classes.errorMessage}>
                        {errors.forwardNumber}
                      </div>
                    </div>
                  )}
                </div>
                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={resetSchedule}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const mapStatesToProps = ({ editSchedule, selectClient }) => {
  return {
    ...editSchedule,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit,
  getPrompts,
  validateOnSubmit,
  getVacations
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditSchedule);
