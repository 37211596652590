import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { Field, FieldArray, Form, Formik } from 'formik';

import styles, { customStyles } from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import { actions as reduxActions } from './reducers';
import { translate } from '../../i18n/I18nProvider';
import { EMPTY_SCHEDULE } from '../../consts/initialValues';
import InputRow from '../../partials/dataRows/InputRow';
import SelectRow from '../../partials/dataRows/SelectRow';
import InfoBox from './components/InfoBox';
import PromptRow from '../editSchedule/components/PromptRow';
import EndElement from '../editSchedule/components/EndElement';
import DatesElement from '../editSchedule/components/DatesElement';
import DaysElement from '../editSchedule/components/DaysElement';
import CheckboxesElement from '../editSchedule/components/CheckboxesElement';
import ActionsButtons from './components/ActionsButtons';
import Optional from '../../utils/optional';
import translateOptionslLabels from '../../utils/translateOptionsLabels';
import SELECTS_OPTIONS from '../../consts/selectsOptions';
import getPrompts from './actions/getPrompts';
import getVacations from './actions/getVacations';
import Vacations from './components/Vacations';
import { doesArrayHasLength } from '../../utils/isDefined';
import addSchedules from './actions/addSchedules';
import validateOnSubmit from './actions/validateForm';

const AddingSchedule = ({
  classes,
  closeDialog,
  open,
  schedules,
  customErrors,
  connectClientId,
  prompts: promptsOptions,
  getPrompts,
  getVacations,
  vacations,
  addSchedules,
  inProgress
}) => {
  useEffect(() => {
    if (open) {
      getPrompts({ connectClientId });
      getVacations();
    }
  }, [connectClientId, open, getPrompts, getVacations]);

  const onSubmit = useCallback(
    async values => {
      const isValidated = await validateOnSubmit({
        values
      });

      if (isValidated) {
        addSchedules({ values, schedules, connectClientId });
      }
    },
    // eslint-disable-next-line
    [schedules, connectClientId]
  );

  const repeatOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_REPEAT_OPTIONS);
  }, []);

  const typeOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_TYPE_OPTIONS);
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        container: classes.container,
        paper: classes.dialogContent,
        scrollPaper: classes.scrollPaper
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog();
        }
      }}
    >
      {inProgress && (
        <div className={classes.tableOverlay}>
          <CircularProgress />
        </div>
      )}
      <CustomDialogTitle onCloseClick={closeDialog}>
        {translate('EDIT_SCHEDULE.ADDING_SCHEDULES')}
      </CustomDialogTitle>
      <DialogContent>
        <div>
          {schedules.map(schedule => {
            return (
              <div
                className={classes.number}
                key={schedule.id}
              >{`${schedule.number} - ${schedule.name}`}</div>
            );
          })}
        </div>
        <Formik
          initialValues={{ ...EMPTY_SCHEDULE }}
          enableReinitialize
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            dirty,
            values,
            handleBlur,
            handleChange,
            setFieldValue
          }) => {
            const fieldProps = {
              values,
              handleBlur,
              handleChange,
              setFieldValue
            };

            const errors = customErrors;

            return (
              <>
                {Object.keys(errors).length > 0 && <InfoBox />}
                <Form className={classes.content} onSubmit={handleSubmit}>
                  <>
                    <div className={classes.nameRow}>
                      <InputRow
                        label="NAME"
                        name="name"
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyles={customStyles.input.name}
                        disabled={doesArrayHasLength(values.vacations)}
                      />
                    </div>
                    <FieldArray
                      name="vacations"
                      render={arrayHelpers => (
                        <Vacations
                          options={vacations}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    />
                    <SelectRow
                      name="recurrenceType"
                      label="SCHEDULE_REPEAT"
                      options={repeatOptions}
                      isDisabled={doesArrayHasLength(values.vacations)}
                      {...fieldProps}
                    />
                    {values.recurrenceType === 'WEEKLY' && (
                      <Field component={DaysElement} />
                    )}
                    {(values.recurrenceType === 'DAILY' ||
                      values.recurrenceType === 'WEEKLY') && (
                      <Field component={CheckboxesElement} />
                    )}

                    <Field
                      component={DatesElement}
                      systemDateSelected={doesArrayHasLength(values.vacations)}
                    />

                    {(values.recurrenceType === 'MONTHLY' ||
                      values.recurrenceType === 'YEARLY') && (
                      <Field
                        component={EndElement}
                        systemDateSelected={doesArrayHasLength(
                          values.vacations
                        )}
                      />
                    )}
                  </>
                  <Field
                    component={PromptRow}
                    promptsOptions={promptsOptions}
                  />

                  <div className={classes.typeContainer}>
                    <SelectRow
                      name="type"
                      label="SCHEDULE_TYPE"
                      customStyles={customStyles.select.typeSelect}
                      options={typeOptions}
                      {...fieldProps}
                    />
                    {values.type === 'FORWARD' && (
                      <div>
                        <TextField
                          name="forwardNumber"
                          value={Optional(values.forwardNumber).or('')}
                          margin="dense"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(errors.forwardNumber)}
                          inputProps={{
                            autoComplete: 'off',
                            'data-lpignore': true
                          }}
                        />
                        <div className={classes.errorMessage}>
                          {errors.forwardNumber}
                        </div>
                      </div>
                    )}
                  </div>
                  <footer className={classes.footer}>
                    <ActionsButtons
                      onCloseClick={closeDialog}
                      handleSubmit={handleSubmit}
                      dirty={dirty}
                      errors={errors}
                    />
                  </footer>
                </Form>
              </>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ addingSchedules, selectClient, loader }) => {
  return {
    ...addingSchedules,
    connectClientId: selectClient.selectedClient.connect30_domain,
    inProgress: loader.isSpinVisible
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeDialog,
  getPrompts,
  getVacations,
  addSchedules
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddingSchedule);
