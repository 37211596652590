import React, { useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import clsx from 'clsx';

import styles, { customSelectStyles } from './styles';
import Optional from '../../utils/optional';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import getSelectValuefromOptions from '../../utils/getSelectValueFromOptions';
import { injectIntl } from 'react-intl';
import { isArrayEmpty } from '../../utils/isDefined';

function SelectAccount({
  selectOptions = [],
  classes,
  form,
  intl,
  customStyles = {},
  fullAccount = false
}) {
  const handleChange = useCallback(
    selected => {
      form.setFieldValue('account', fullAccount ? selected : selected.value);
    },
    // eslint-disable-next-line
    [form]
  );

  const ValueContainer = props => {
    const { children } = props;
    return (
      <components.ValueContainer {...props}>
        <div className={classes.labelContainer}>
          <div>{Optional(children[0].props?.data?.name).or('')}</div>
          <div>{Optional(children[0].props?.data?.accountNo).or('')}</div>
        </div>
      </components.ValueContainer>
    );
  };

  const value = useMemo(
    () =>
      fullAccount
        ? form.values.account
        : getSelectValuefromOptions(selectOptions, form.values.account),
    [selectOptions, form.values.account, fullAccount]
  );

  return (
    <div className={classes.root} style={customStyles.root}>
      <div className={classes.label} style={customStyles.label}>
        {intl.formatMessage({
          id: 'ACCOUNT'
        })}
      </div>

      {isArrayEmpty(selectOptions) ? (
        <div className={clsx(classes.labelContainer, classes.onlyAccount)}>
          <div>
            {intl.formatMessage({
              id: 'REQUEST_NEW_USER.NO_ACCOUNTS FOUND'
            })}
          </div>
        </div>
      ) : selectOptions.length > 1 ? (
        <Select
          styles={reactSelectDefaultStyles}
          customStyles={customSelectStyles}
          options={selectOptions}
          value={value}
          menuPlacement="top"
          maxMenuHeight="150px"
          onChange={handleChange}
          onBlur={form.handleBlur}
          components={{ ValueContainer }}
        />
      ) : (
        <div className={clsx(classes.labelContainer, classes.onlyAccount)}>
          <div>{Optional(value?.name).or(' ')}</div>
          <div>{Optional(value?.accountNo).or(' ')}</div>
        </div>
      )}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(SelectAccount);
