import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles, { CUSTOM_STYLES } from '../styles/reportSection';
import InputRow from '../../../partials/dataRows/InputRow';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

export function ReportSection({ classes, form, clientId }) {
  return (
    <div className={classes.root}>
      <CheckboxRow
        label="DATA_LIMITS.REPORT_TO"
        name="report_only_to"
        infoIcon={true}
        infoIconTooltipIntlKey="DATA_LIMITS.TOOLTIP.REPORT_TO"
        customStyles={CUSTOM_STYLES.checkboxRow}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'report_only_to'
          )
        }
        {...form}
      />
      <InputRow
        label="DATA_LIMITS.REPORT_SMS"
        name="report_to_sms"
        infoIcon={true}
        infoIconTooltipIntlKey="DATA_LIMITS.TOOLTIP.REPORT_SMS"
        customStyles={CUSTOM_STYLES.textFieldRow}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'report_to_sms'
          )
        }
        {...form}
      />
      <InputRow
        label="DATA_LIMITS.REPORT_EMAIL"
        name="report_to_email"
        infoIcon={true}
        infoIconTooltipIntlKey="DATA_LIMITS.TOOLTIP.REPORT_EMAIL"
        customStyles={CUSTOM_STYLES.textFieldRow}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'report_to_email'
          )
        }
        {...form}
      />
    </div>
  );
}

export default flowRight(withStyles(styles))(ReportSection);
