import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import tableColumns from './tableColumns';
import {
  mapTableOptions,
  setLocalization,
  TABLE_OPTIONS
} from '../../../consts/tableOptions';
import fetchChangeLog from '../actions/fetchChangeLog';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';

const ChangeLogTable = ({ intl, isLoading, selectedClient }) => {
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DEFAULT.pageSize);
  const [filtering, setFiltering] = useState(false);

  const tableRef = useRef();

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ selectedClient });
  }, [selectedClient]);

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      pageSize,
      search: false,
      columnsButton: false,
      filtering
    };
  }, [pageSize, filtering]);

  useEffect(() => {
    tableRef.current.onQueryChange();
    // eslint-disable-next-line
  }, [selectedClient]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const fetchData = useCallback(
    query => {
      setPageSize(query.pageSize);
      return fetchChangeLog({ query, clientId: selectedClient.value });

      // eslint-disable-next-line
    },
    [setPageSize, selectedClient]
  );

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering]
  );

  return (
    <MaterialTable
      title={
        <CustomTableTitle
          text="MENU.CHANGELOG"
          icon={<i className="fas fa-history"></i>}
        />
      }
      columns={tableDefaultColumns}
      data={fetchData}
      options={tableOptions}
      localization={localization}
      tableRef={tableRef}
      isLoading={isLoading}
      actions={actions}
    />
  );
};

const mapStatesToProps = ({ loader }) => {
  return {
    isLoading: loader.isSpinVisible
  };
};

export default flowRight(injectIntl, connect(mapStatesToProps))(ChangeLogTable);
