import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, OTHER_LOGIN } from './types';
import { actions as reduxActions } from '../reducers/index';
import { actions as selectClientReduxActions } from '../../../partials/selectClient/reducers';
import { JSONAPI_URLS, OUTSIDE_API } from '../../../consts/endpoints';
import { getData, postData } from '../../../utils/http';
import findLowestIdRole from '../../../utils/findLowestIRole';
import Optional from '../../../utils/optional';
import { ROLES_IDS } from '../../../consts/rolesIds';
import { mitelRequest } from '../../../utils/mitelRequest';
import urlCreator from '../../../utils/urlCreator';
import { isArrayEmpty, isUndefined } from '../../../utils/isDefined';
import { getInitialValue } from '../../../partials/selectClient/actions';
import checkIfSimcardsShouldBeShown from '../../../utils/checkIfSimcardsShouldBeShown';

const CUSTOM_JOBS_QUERY_PARAMS = {
  fields: { custom_jobs: ['id', 'name', 'category'] }
};

export function loginMsUser({ auth, intl }) {
  return {
    type: sagaActions[OTHER_LOGIN],
    auth,
    intl
  };
}

export function* otherLogin({ auth }) {
  yield put(reduxActions.setLoading(true));
  yield put(reduxActions.setAuth(auth));
  try {
    let haveQueues = true;

    const {
      headers,
      data: { data: user }
    } = yield call(postData, {
      url: JSONAPI_URLS.LOGIN
    });

    const { authorization: authToken } = headers;
    const role = yield call(findLowestIdRole, user.roles);

    if (role.id <= ROLES_IDS.STANDARD_USER) {
      if (isUndefined(user.connect30_user_id)) {
        haveQueues = false;
      } else {
        const { data: queues = [] } = yield call(mitelRequest, {
          options: { url: urlCreator(OUTSIDE_API.QUEUES, {}) }
        });

        haveQueues = isArrayEmpty(queues) ? false : true;
      }
    }

    const hasCientSimcards = checkIfSimcardsShouldBeShown(user.client_id);

    const customJobsResponse = yield call(
      getData,
      urlCreator(JSONAPI_URLS.CUSTOM_JOBS, CUSTOM_JOBS_QUERY_PARAMS)
    );

    yield put(
      reduxActions.setUser({
        user,
        defaultClient: { ...user.client, hasCientSimcards },
        role,
        haveQueues,
        authToken,
        lang: Optional(user.language).or('en'),
        customJobs: Optional(customJobsResponse?.data).or([])
      })
    );
    yield call(getInitialValue, { role, adminDomains: user.admin_domains });

    yield put(
      selectClientReduxActions.setSelectedClient({
        ...user.client,
        hasCientSimcards
      })
    );
    yield put(reduxActions.setLogin(true));
  } finally {
    yield put(reduxActions.setLoading(false));
  }
}

function* watchOtherLogin() {
  yield takeLatest(sagaActions[OTHER_LOGIN], otherLogin);
}

export default watchOtherLogin();
