import { createReduxActionTypes } from '../../../utils/actionFactory';
import Optional from '../../../utils/optional';

const OPEN_EDIT_VACATION = 'OPEN_EDIT_VACATION';
const RESET_EDIT_VACATION = 'RESET_EDIT_VACATION';

export const actionTypes = createReduxActionTypes('EDIT_VACATION', [
  OPEN_EDIT_VACATION,
  RESET_EDIT_VACATION
]);

const DEFAULT_STATE = {
  open: false,
  isEdit: false,
  vacation: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_EDIT_VACATION]: {
      const { isEdit, vacation } = action;
      return {
        ...state,
        open: true,
        isEdit: Optional(isEdit).or(false),
        vacation: Optional(vacation).or({})
      };
    }
    case actionTypes[RESET_EDIT_VACATION]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openEditVacation: (vacation, isEdit) => ({
    type: actionTypes[OPEN_EDIT_VACATION],
    isEdit,
    vacation
  }),
  closeEditVacation: () => ({ type: actionTypes[RESET_EDIT_VACATION] })
};
