import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { isArray } from '../../../utils/isDefined';
import { createVacationsSelectOptions } from '../../editSchedule/actions/getVacations';

const getVacations = () => {
  return async dispatch => {
    let vacations = [];
    try {
      const { data } = await getData(`${JSONAPI_URLS.VACATIONS}`);

      if (isArray(data)) {
        vacations = data;
      }
    } catch {
    } finally {
      dispatch(
        reduxActions.setVacations(createVacationsSelectOptions(vacations))
      );
    }
  };
};

export default getVacations;
