const styles = theme => {
  return {
    root: { width: '600px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '13px',
      minHeight: '475px',
      paddingTop: '5px'
    },
    oneOption: { justifyContent: 'center' },
    buttonsRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      margin: '20px 0'
    }
  };
};

export default styles;
