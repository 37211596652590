import groupby from 'lodash.groupby';
import { isStringDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function createSelectClientOptions(clientsList) {
  return Optional(clientsList)
    .map(mapClients)
    .or([]);
}

function mapClients(clientsList) {
  const groupedClients = groupby(clientsList, client =>
    Optional(client.partner)
      .map(client => client.name)
      .or('')
  );

  return Object.keys(groupedClients)
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
    .map(partner => {
      const mappedClients = groupedClients[partner].map(client => {
        return {
          value: Number(client.id),
          label: createClientOptionText(client)
        };
      });

      return {
        label: partner !== '' ? partner : 'IPVision',
        options: mappedClients
      };
    });
}

function createClientOptionText(client) {
  let notes = '';

  if (isStringDefined(client.notes)) {
    notes =
      client.notes.length > 22
        ? `${client.notes.slice(0, 22)}...`
        : client.notes;
  }

  return `${client.name} 
    ${mapClientTextSection(notes)}
    ${mapClientTextSection(client.connect30_domain)}`;
}

function mapClientTextSection(value) {
  return isStringDefined(value) ? `- ${value}` : '';
}
