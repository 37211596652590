import React from 'react';

import TableActionButtons from './TableActionButtons';
import formatDate from '../../../utils/formatDate';
import OPTIONS from '../../../consts/selectsOptions';
import { isDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';

export default function tableColumns() {
  const columns = [
    {
      render: rowData => <TableActionButtons data={rowData} />,
      disableClick: true,
      sorting: false,
      width: '50px'
    },
    {
      title: 'TABLE.NAME',
      field: 'holiday'
    },
    {
      title: 'TABLE.TYPE',
      render: rowData => findTypeTranslation(rowData.holiday_type),
      field: 'holiday_type',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(findTypeTranslation(rowData.holiday_type), term);
      }
    },
    {
      title: 'DATE',
      field: 'date'
    },
    {
      title: 'MONTH',
      render: rowData => findMonthTranslation(rowData.month),
      field: 'month',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(findMonthTranslation(rowData.month), term);
      }
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
}

const findMonthTranslation = value => {
  const founded = OPTIONS.VACATION_MONTHS.find(
    option => option.value === value
  );

  if (isDefined(founded)) {
    return translate(founded.label);
  }

  return value;
};

const findTypeTranslation = value => {
  const founded = OPTIONS.VACATIONS_TYPE.find(
    option => option.value === value
  );

  if (isDefined(founded)) {
    return translate(founded.label);
  }

  return value;
};
