import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_USER } from './types';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import {
  isDefined,
  doesArrayHasLength,
  isUndefined
} from '../../../utils/isDefined';
import mapCellNumbers from './mapCellNumbers';
import getUserInfoFromLocation from './getUserInfoFromLocation';
import getMitelData from './getMitelData';
import Optional from '../../../utils/optional';
import history from '../../../../history';

const QUERY_PARAMS = {
  include: [
    'sim_cards.cell_numbers.data_limit',
    'sim_cards.primary_card',
    'roles',
    'department'
  ],
  fields: {
    roles: 'name',
    departments: 'name',
  }
};

export function getUser({
  location,
  hasCurrentUserConnectId,
  connectClientId,
  isNonClientAdminUser,
  isCurrentUserSelectedClientUser,
  currentUserClientId,
  isPartnerAdmin,
  currentLoggedUserId
}) {
  return {
    type: sagaActions[GET_USER],
    location,
    hasCurrentUserConnectId,
    connectClientId,
    isNonClientAdminUser,
    isCurrentUserSelectedClientUser,
    currentUserClientId,
    isPartnerAdmin,
    currentLoggedUserId
  };
}

export function* fetchUser({
  location,
  hasCurrentUserConnectId,
  connectClientId,
  isNonClientAdminUser,
  isCurrentUserSelectedClientUser,
  currentUserClientId,
  isPartnerAdmin,
  currentLoggedUserId
}) {
  const { userId, page, clientId, isCurrentUser } = yield call(
    getUserInfoFromLocation,
    {
      location,
      currentLoggedUserId
    }
  );

  let user = {};

  if (isUndefined(userId)) {
    return history.replace(`/${clientId}/${page}`);
  }

  if (page === 'telephonyUsers' || page === 'groups') {
    const { data } = yield call(
      getData,
      urlCreator(`${URLS.USERS}.json`, {
        ...QUERY_PARAMS,
        filter: { connect30_user_id: userId, client_id: clientId }
      })
    );

    user = Optional(data[0]).or({ connect30_user_id: userId });
  } else {
    const { data } = yield call(
      getData,
      urlCreator(`${URLS.USERS}/${userId}.json`, QUERY_PARAMS)
    );
    user = data;
  }

  if (
    (isNonClientAdminUser || hasCurrentUserConnectId) &&
    isDefined(user.connect30_user_id) &&
    isDefined(connectClientId) &&
    (!isPartnerAdmin || isCurrentUser)
  ) 
  {
    user = {
      ...(yield call(getMitelData, {
        user,
        isCurrentUserSelectedClientUser,
        connectClientId: isCurrentUser ? currentUserClientId : connectClientId,
        isCurrentUser
      }))
    };
  }

  if (doesArrayHasLength(user.sim_cards)) {
    const cellNumbersData = yield call(mapCellNumbers, user);

    user = {
      ...user,
      ...cellNumbersData
    };
  }

  yield put(reduxActions.setUser(user));
}

function* watchGetUser() {
  yield takeLatest(sagaActions[GET_USER], fetchUser);
}

export default watchGetUser();
