import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSchedules } from './actions/getSchedules';
import { actions as reduxActions } from './reducers';
import SchedulesTable from './components/SchedulesTable';
import EditSchedule from '../../modals/editSchedule/EditSchedule';
import AddingSchedule from '../../modals/addingSchedules/AddingSchedule';
import Summary from '../../modals/addingSchedules/components/Summary';

function Schedules({
  getSchedules,
  connectClientId,
  clientId,
  resetSchedules
}) {
  useEffect(() => {
    getSchedules(connectClientId);
    return resetSchedules;
    // eslint-disable-next-line
  }, [connectClientId]);

  return (
    <>
      <SchedulesTable client={clientId} />
      <EditSchedule />
      <AddingSchedule />
      <Summary />
    </>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  resetSchedules: reduxActions.resetSchedules,
  getSchedules
};

export default connect(mapStatesToProps, mapDispatchToProps)(Schedules);
