import React from 'react';
import { translate } from '../../../i18n/I18nProvider';
import formatDate from '../../../utils/formatDate';
import mapNumberToDisplay from '../../../utils/mapNumberToDisplay';
import CustomCopyToClipboard from '../../copyToClipboard.js/CustomCopyToClipboard';

export default function tableColumns() {
  return [
    {
      title: translate('TABLE.NUMBER'),
      render: rowdata => (
        <CustomCopyToClipboard value={mapNumberToDisplay(rowdata.number)} />
      )
    },
    {
      title: translate('DATA_LIMITS.ACTIVE_FROM'),
      render: rowdata => formatDate(rowdata.active_on_client_from)
    },
    {
      title: translate('DATA_LIMITS.ACTIVE_TO'),
      render: rowdata => formatDate(rowdata.active_on_client_to)
    }
  ];
}

