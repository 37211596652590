export default {
  root: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  isTable: { width: '100%' },
  alignLeft: { justifyContent: 'flex-start' },
  field: {
    width: '70px',
    minHeight: '20px',
    Height: 'fit-content',
    fontSize: '10px',
    fontWeight: 500,
    borderRadius: '10px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
};
