import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles/generalData';
import withSettings from '../../../utils/withSettings';
import CustomTitle from './CustomTitle';
import { NUMBER_LOOKUP_NUMBER_RANGES_DATA } from '../../../consts/initialValues';
import Optional from '../../../utils/optional';
import { mapValue } from '../actions/dataActions';
import checkNumberData from '../../../utils/checkNumberData';
import STATUSES from '../../../consts/statusBoxOptions';
import { Link } from 'react-router-dom';
import { isDefined } from '../../../utils/isDefined';
import { actions as rightPanelReduxActions } from '../../../partials/rightPanel/reducers';
import NumberRangeMenu from './NumberRangeMenu';
import changeClient from '../../../utils/changeClient';
import { formatDateForSchedule } from '../../../utils/formatDate';

function NumberRangesData({
  classes,
  externalNoData = {},
  fromRightPanel,
  rightPanelData = {},
  intl,
  settings,
  closeRightPanel
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: externalNoData,
      rightPanelData,
      fromRightPanel
    });
  }, [externalNoData, rightPanelData, fromRightPanel]);

  const { provider, client } = data;

  const closeRightPanelAction = useCallback(
    () => {
      if (fromRightPanel) {
        closeRightPanel();
      }
    },
    // eslint-disable-next-line
    [fromRightPanel]
  );

  const onClientClick = async () => {
    closeRightPanelAction();

    await changeClient({ client });
  };

  return (
    <div className={classes.root}>
      <CustomTitle
        title="NUMBER.NUMBER_RANGE_DATA"
        isDataExist={isDataExist}
        dotsMenu={true}
        menuComponent={
          <NumberRangeMenu numberRange={data} fromRightPanel={fromRightPanel}>
            <i className={clsx('fas fa-ellipsis-h', classes.dotsIcon)} />
          </NumberRangeMenu>
        }
      >
        {isDataExist &&
          NUMBER_LOOKUP_NUMBER_RANGES_DATA.map((el, i) => {
            const value = mapValue(data[el.value]);
            const label = Optional(el.intlKey)
              .map(intlKey =>
                intl.formatMessage({
                  id: intlKey
                })
              )
              .or(el.value);

            switch (el.value) {
              case 'client': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>
                    <Link
                      onClick={onClientClick}
                      className={classes.links}
                      style={{ color: settings.colors.links }}
                      to={
                        isDefined(client?.id)
                          ? {
                              pathname: `/${client.id}/dashboard`
                            }
                          : ''
                      }
                    >
                      <div className={classes.valueContainer}>
                        {Optional(client?.name).or('')}
                      </div>
                    </Link>
                  </div>
                );
              }
              case 'provider': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{Optional(provider?.sane_name).or('')}</div>
                  </div>
                );
              }
              case 'status': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>
                      {Optional(STATUSES.NUMBER_STATUSES[value]?.intlKey)
                        .map(key =>
                          intl.formatMessage({
                            id: key
                          })
                        )
                        .or(value)}
                    </div>
                  </div>
                );
              }
              case 'number_type': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>
                      {Optional(STATUSES.NUMBER_TYPES[value]?.intlKey)
                        .map(key =>
                          intl.formatMessage({
                            id: key
                          })
                        )
                        .or(value)}
                    </div>
                  </div>
                );
              }
              case 'och_active_at': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{formatDateForSchedule(value)}</div>
                  </div>
                );
              }
              case 'och_inactive_at': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{formatDateForSchedule(value)}</div>
                  </div>
                );
              }
              default:
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{value}</div>
                  </div>
                );
            }
          })}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.externalNoData,
    externalNoData: checkNumber.externalNoData
  };
};

const mapDispatchToProps = {
  closeRightPanel: rightPanelReduxActions.closeRightPanel
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NumberRangesData);
