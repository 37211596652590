export default {
  root: {
    backgroundColor: '#fff',
    margin: '5px',
    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'column',
    width: '500px',
    height: '480px'
  },
  label: {
    fontSize: '16px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  columnsContainer: { display: 'flex', flexDirection: 'column' },
  dotsIcon: { cursor: 'pointer', paddingRight: '20px' },
  datarowValue: { fontWeight: 600 },
  phoneBookRoot: {
    backgroundColor: '#fff',
    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'column'
  },
  noEntry: { display: 'flex', justifyContent: 'center' }
};

export const dataRowCustomStyles = {
  root: { backgroundColor: '#FAFAFA' }
};
