import React, { useCallback, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';
import { isArray, isDefined, isUndefined } from '../../../utils/isDefined';
import Features from './Features';
import CustomTitle from './CustomTitle';
import DatalimitMenu from './DatalimitMenu';
import checkNumberData from '../../../utils/checkNumberData';
import styles from '../styles/subscriptionStatus';
import DatalimitInfo from './DatalimitInfo';
import Problems from './Problems';

const SubscriptionStatus = ({
  subscription,
  datalimitData = {},
  lang,
  classes,
  isLoadingSubscription,
  isLandlineNumber
}) => {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: datalimitData,
      rightPanelData: {},
      fromRightPanel: false
    });
  }, [datalimitData]);

  const checkRefillStatus = useCallback((projection = []) => {
    const refill = projection.find(el => el.name === 'Refill');

    if (isUndefined(refill)) {
      return false;
    }

    return refill.value;
  }, []);

  return (
    <div className={classes.root}>
      <CustomTitle
        title="NUMBER.SUBSCRIPTION_STATUS"
        isDataExist={
          !isLoadingSubscription && (isDataExist || isDefined(subscription))
        }
        dotsMenu={true}
        menuComponent={
          <DatalimitMenu datalimit={data}>
            <i className={clsx('fas fa-ellipsis-h', classes.dotsIcon)} />
          </DatalimitMenu>
        }
      >
        {isUndefined(subscription) ? (
          <div className={classes.textContainer}></div>
        ) : (
          <div className={classes.contentContainer}>
            <div
              className={classes.dataContainer}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <div className={classes.dataKey}>
                {translate('REQUEST_NEW_USER.SUBSCRIPTION')}
              </div>

              <div>
                {Optional(subscription?.skuTranslations?.[lang]).or(
                  subscription?.skuTranslations?.da
                )}
              </div>
            </div>
            <div
              className={classes.dataContainer}
              style={{
                backgroundColor: '#FAFAFA'
              }}
            >
              <div className={classes.dataKey}>{translate('NAME')}</div>

              <div>{Optional(subscription?.name).or('')}</div>
            </div>
            <div
              className={classes.dataContainer}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <div className={classes.dataKey}>
                {translate('SUBSCRIPTIONS.REFILL')}
              </div>

              <div>
                {checkRefillStatus(subscription?.projection)
                  ? translate('ACTIVE').toLowerCase()
                  : translate('INACTIVE').toLowerCase()}
              </div>
            </div>
            {isDefined(subscription?.changeDate) && (
              <div
                className={classes.dataContainer}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <div className={classes.dataKey}>
                  {translate('SUBSCRIPTIONS.CHANGE_DATE')}
                </div>

                <div>{subscription.changeDate}</div>
              </div>
            )}
            {isDefined(subscription?.changeText) && (
              <div
                className={classes.dataContainer}
                style={{
                  backgroundColor: '#FAFAFA'
                }}
              >
                <div className={classes.dataKey}>
                  {translate('SUBSCRIPTIONS.CHANGE_DETAILS')}
                </div>

                <div>{subscription.changeText}</div>
              </div>
            )}
            {isArray(subscription?.features) && (
              <Features features={subscription?.features} />
            )}
          </div>
        )}
        {!isLandlineNumber && <DatalimitInfo />}
        {isArray(subscription?.problems) && (
          <Problems problems={subscription?.problems} />
        )}
      </CustomTitle>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber, auth }) => {
  const {
    subscription,
    datalimitData,
    isLoadingSubscription,
    externalNoData
  } = checkNumber;
  return {
    subscription,
    datalimitData,
    lang: auth.lang,
    isLoadingSubscription,
    isLandlineNumber: externalNoData?.number_type === 'landline' ? true : false
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(SubscriptionStatus);
