import React from 'react';
import { TableRow, TableCell, TableHead } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import { injectIntl } from 'react-intl';

import styles from '../styles/scheduleDetails';
import AddSchedule from './AddSchedule';

function ScheduleDetailHeader({ group, classes, intl }) {
  return (
    <TableHead >
      <TableRow>
        <TableCell className={classes.tableDragColumn}></TableCell>
        <TableCell className={classes.tableActions}>
          <AddSchedule group={group} />
        </TableCell>
        <TableCell>{intl.formatMessage({ id: 'NAME' })}</TableCell>
        <TableCell>{intl.formatMessage({ id: 'SCHEDULES.DURATION' })}</TableCell>
        <TableCell>{intl.formatMessage({ id: 'SCHEDULES.ACTION' })}</TableCell>
        <TableCell>{intl.formatMessage({ id: 'SCHEDULES.PROMPT'})}</TableCell>
        <TableCell className={classes.voicePrompt}> </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default flowRight(injectIntl, withStyles(styles))(ScheduleDetailHeader);
