import can from './can';
import createSubject from './createSubject';
import {
  doesArrayHasLength,
  isArray,
  isArrayEmpty,
  isDefined,
  isUndefined
} from './isDefined';
import Optional from './optional';

export default function checkMenuItemList({ items = {}, clientId, values }) {
  const main = reduceItemArray({ itemArray: items.main, clientId, values });

  const telephony = reduceItemArray({
    itemArray: items.telephony,
    clientId,
    section: items.sections.telephony,
    values
  });

  const clientSettings = reduceItemArray({
    itemArray: items.clientSettings,
    clientId,
    section: items.sections.clientSettings,
    values
  });

  const administration = reduceItemArray({
    itemArray: items.administration,
    clientId,
    section: items.sections.administration,
    values
  });

  return [...main, ...telephony, ...administration, ...clientSettings];
}

function reduceItemArray({ itemArray = [], clientId, section, values }) {
  const {
    isMitelTokenProvided,
    isDomainProvided,
    isPartnerClient,
    isSuperAdmin,
    userHasQueues,
    hasCientSimcards,
    hasUserPartnerId,
    hasClientAccounts,
    userRules
  } = values;
  let mappedArray = itemArray.reduce((arr, item) => {
    if (item.title === 'Users') {
      if (!isSuperAdmin && isDomainProvided) {
        return arr;
      }
    } else if (
      (item.hideWhenThereIsNoMitelToken && !isMitelTokenProvided) ||
      (item.showOnlyForSuperAdmin && !isSuperAdmin) ||
      (item.hideWhenThereIsNoDomain && !isDomainProvided) ||
      (item.hideForPartnerClients && isPartnerClient) ||
      (item.isSettingsLink && !hasUserPartnerId) ||
      (!userHasQueues && item.hideWhenThereIsNoQueues) ||
      (!hasCientSimcards && item.hideWhenThereIsNoSimcards) ||
      (!hasClientAccounts && item.hideWhenNoAccounts)
    ) {
      return arr;
    }

    const conditionKey = Optional(item.conditionKey).or('client_id');
    const checkAction = Optional(item.checkAction).or('read');

    if (
      !item.ignoreCheck &&
      !can(
        checkAction,
        createSubject(item.checkKey, { [conditionKey]: Number(clientId) })
      )
    ) {
      return arr;
    }

    if (isDefined(item.customCheck)) {
      const shouldShow = customCheck(item.customCheck, userRules);

      return shouldShow ? [...arr, item] : arr;
    }

    return [...arr, item];
  }, []);

  if (doesArrayHasLength(mappedArray) && isDefined(section)) {
    mappedArray = [
      {
        awesomeIcon: section.awesomeIcon,
        translate: section.translate,
        title: section.section,
        submenu: mappedArray
      }
    ];
  }

  return mappedArray;
}

const checkPhonebookRegistrationRule = rules => {
  if (!isArray(rules)) {
    return false;
  }

  const mappedRules = rules.filter(
    rule =>
      rule.subject.indexOf('PhoneBookRegistration') !== -1 &&
      (rule.actions.indexOf('manage') !== -1 ||
        rule.subject.indexOf('read') !== -1)
  );

  if (isArrayEmpty(mappedRules)) {
    return false;
  }

  const haveNumberRangeCondition = mappedRules.filter(el => {
    const {
      conditions: { external_number_range_id }
    } = el;

    if (isUndefined(external_number_range_id)) {
      return false;
    }

    return doesArrayHasLength(external_number_range_id?.$in);
  });

  return doesArrayHasLength(haveNumberRangeCondition);
};

export const customCheck = (key, rules) => {
  switch (key) {
    case 'Phonebook': {
      return checkPhonebookRegistrationRule(rules);
    }
    default:
      return false;
  }
};
