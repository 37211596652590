import React, { useState, useEffect } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { injectIntl } from 'react-intl';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv6';

import styles from './styles';
import updateFormData from './actions/updateFormData';
import getFormValues from './actions/getFormValues';
import PrimaryButton from '../customButtons/PrimaryButton';
import SecondaryButton from '../customButtons/SecondaryButton';
import { isArray, isDefined, isString } from '../../utils/isDefined';
import deleteClientConfig from './actions/deleteClientConfig';
import getFormData from './actions/getFormData';
import ConfirmDialog from '../confirmationDialog/ConfirmDialog';
import withUserCheck from '../../utils/withUserCheck';
import CustomArrayFieldTemplate from './components/CustomArrayFieldTemplate';
import { postData } from '../../utils/http';
import { URLS } from '../../consts/endpoints';
import { actions as testUserSyncReduxActions } from '../../modals/testUsersSync/reducers';
import { connect } from 'react-redux';
import TestUsersSync from '../../modals/testUsersSync/TestUsersSync';
import { actions as errorReduxActions } from '../../partials/errorDialog/reducers';
import Optional from '../../utils/optional';

const CustomForm = ({
  classes,
  onCloseDialog,
  clientId,
  templateName,
  intl,
  fromDialog = true,
  isNonClientAdminUser,
  openTestUserSync,
  openErrorDialog
}) => {
  const [schema, setSchema] = useState({});
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uiSchema, setUiSchema] = useState({});

  useEffect(() => {
    (async function() {
      setIsLoading(true);
      const response = await getFormValues({
        setSchema,
        setFormData,
        clientId,
        templateName,
        setUiSchema
      });
      return response && setIsLoading(false);
    })();
  }, [clientId, templateName]);

  const onSubmit = async (event, props) => {
    let id = props?.nativeEvent?.submitter?.id;

    if (id === 'test_form') {
      try {
        const response = await postData({
          url: `${URLS.MICROSOFT}/preview_users`,
          data: { config: event.formData },
          passErrors: true
        });
        if (isArray(response?.data)) {
          openTestUserSync(response.data);
        }
      } catch (e) {
        openErrorDialog({
            directMessage: `${Optional(
              e?.response?.data?.error
            )
              .map(message => `Error message : ${message}`)
              .or('')}`
          })
        
      }
    } else {
      await updateFormData({ event, clientId, templateName });
      await getFormData({ setFormData, clientId, templateName });
    }
  };

  const onDelete = async () => {
    await deleteClientConfig({ clientId, templateName });
    await getFormData({ setFormData, clientId, templateName });
  };

  return (
    isDefined(formData) &&
    !isLoading && (
      <>
        <Form
          validator={validator}
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onSubmit={onSubmit}
          templates={{
            ArrayFieldTemplate: CustomArrayFieldTemplate
          }}
        >
          <div className={classes.footer}>
            {isString(templateName) &&
              templateName.toLowerCase().search('azure') !== -1 && (
                <PrimaryButton type="submit" id="test_form">
                  TEST
                </PrimaryButton>
              )}
            <PrimaryButton type="submit" id="save_form">
              {intl.formatMessage({
                id: 'BUTTON.SAVE'
              })}
            </PrimaryButton>
            {fromDialog && (
              <SecondaryButton onClick={onCloseDialog}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            )}
            {isNonClientAdminUser && (
              <ConfirmDialog
                dialogTitleIntlId="DELETE"
                dialogTextIntlId="SETTINGS.DELETE_TEXT"
                onConfirmClick={onDelete}
              >
                <PrimaryButton disabled={Object.keys(formData).length === 0}>
                  {intl.formatMessage({
                    id: 'DELETE'
                  })}
                </PrimaryButton>
              </ConfirmDialog>
            )}
          </div>
        </Form>
        <TestUsersSync />
      </>
    )
  );
};

const mapDispatchToProps = {
  openTestUserSync: testUserSyncReduxActions.openTestUserSync,
  openErrorDialog: errorReduxActions.openDialog
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(CustomForm);
