import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import CustomTableTitle from '../../../partials/CustomTableTitle';
import tableColumns from './tableColumns';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import { setTableColumns } from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import onLicenceUpdate from '../actions/updateLicense';
import updateLicenses from '../actions/updateLicenses';
import CustomTableSaveButton from '../../../partials/tableIcons/CustomTableSaveButton';

const LicensesTable = ({ intl, licenses, columns, setColumns }) => {
  const [filtering, setFiltering] = useState(false);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Licenses'
    });
  }, [setColumns, tableDefaultColumns]);

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      filtering,
      paging: false
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering]
  );

  return (
    <div>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.LICENSES"
            icon={<i className="fas fa-file-certificate"></i>}
          />
        }
        icons={{
          ...customTableIcons,
          Check: forwardRef((props, ref) => (
            <CustomTableSaveButton ref={ref} {...props} />
          ))
        }}
        actions={actions}
        columns={columns}
        data={licenses}
        options={options}
        localization={localization}
        editable={{
          isEditHidden: rowData => rowData.type !== 'unit',
          onRowUpdate: async (newData, oldData) => {
            await onLicenceUpdate({
              component: oldData.component,
              quota: newData.quota
            });
          },
          onBulkUpdate: async changes => await updateLicenses({ changes })
        }}
      />
    </div>
  );
};
const mapStatesToProps = ({ licenses }) => {
  return {
    ...licenses
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(LicensesTable);
