import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from './styles';
import Optional from '../../utils/optional';
import clsx from 'clsx';

export function StatusCell({
  classes,
  intl,
  intlKey,
  color = '',
  text = '',
  isTable = true,
  alignLeft = true
}) {
  const completeText = Optional(intlKey)
    .map(key =>
      intl.formatMessage({
        id: key
      })
    )
    .or(text);

  return (
    <div
      className={clsx(classes.root, {
        [classes.isTable]: isTable,
        [classes.alignLeft]: alignLeft
      })}
    >
      <div className={classes.field} style={{ backgroundColor: color }}>
        {completeText}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(StatusCell);
