import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, LOGIN } from './types';
import { actions as reduxActions } from '../reducers/index';
import { actions as selectClientReduxActions } from '../../../partials/selectClient/reducers';
import { JSONAPI_URLS, OUTSIDE_API } from '../../../consts/endpoints';
import RESPONSE_CODES, { ERRORS_MESSAGE } from '../../../consts/responseCodes';
import {  postData } from '../../../utils/http';
import findLowestIdRole from '../../../utils/findLowestIRole';
import Optional from '../../../utils/optional';
import { ROLES_IDS } from '../../../consts/rolesIds';
import urlCreator from '../../../utils/urlCreator';
import { mitelRequest } from '../../../utils/mitelRequest';
import { isArrayEmpty, isUndefined } from '../../../utils/isDefined';
import { getInitialValue } from '../../../partials/selectClient/actions';
import getCustomJobs from '../../../utils/getCustomJobs';
import checkIfSimcardsShouldBeShown from '../../../utils/checkIfSimcardsShouldBeShown';

export function loginUser({ login, password, onLoginError = () => {}, intl }) {
  return {
    type: sagaActions[LOGIN],
    login,
    password,
    onLoginError,
    intl
  };
}

export function* login({ login, password, onLoginError, intl }) {
  yield put(reduxActions.setLoading(true));
  try {
    let haveQueues = true;

    const {
      headers,
      data: { data: user }
    } = yield call(postData, {
      auth: true,
      url: JSONAPI_URLS.LOGIN,
      data: {
        user: { login, password }
      }
    });

    const { authorization: authToken } = headers;
    yield put(reduxActions.setAuth(authToken));
    const role = yield call(findLowestIdRole, user.roles);
    if (role.id <= ROLES_IDS.STANDARD_USER) {
      if (isUndefined(user.connect30_user_id)) {
        haveQueues = false;
      } else {
        const { data: queues = [] } = yield call(mitelRequest, {
          options: { url: urlCreator(OUTSIDE_API.QUEUES, {}) }
        });

        haveQueues = isArrayEmpty(queues) ? false : true;
      }
    }

    const hasCientSimcards = checkIfSimcardsShouldBeShown(user.client_id)

    const customJobs = yield call(getCustomJobs, '');

    yield put(
      reduxActions.setUser({
        user,
        defaultClient: { ...user.client, hasCientSimcards },
        role,
        haveQueues,
        authToken,
        lang: Optional(user.language).or('en'),
        customJobs
      })
    );

    yield call(getInitialValue, { role, adminDomains: user.admin_domains });

    yield put(
      selectClientReduxActions.setSelectedClient({
        ...user.client,
        hasCientSimcards
      })
    );

    yield put(reduxActions.setLogin(true));
  } catch (error) {
    if (error.message === ERRORS_MESSAGE.NETWORK_ERROR) {
      return yield put(
        reduxActions.setLoginError({
          error: intl.formatMessage({
            id: 'MESSAGE.CONNECTION_PROBLEM'
          })
        })
      );
    }

    const isUnauthorized =
      RESPONSE_CODES.UNAUTHORIZED.findIndex(
        code => code === error.response.status
      ) !== -1;
    if (isUnauthorized) {
      yield put(
        reduxActions.setLoginError({
          error: Optional(error.response.data).or(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_LOGIN'
            })
          )
        })
      );
    } else {
      yield put(
        reduxActions.setLoginError({
          error: `${Optional(error.response.status).or('')} : ${Optional(
            error.response.statusText
          ).or('')}`
        })
      );
    }
  } finally {
    yield put(reduxActions.setLoading(false));
  }
}

function* watchLogin() {
  yield takeLatest(sagaActions[LOGIN], login);
}

export default watchLogin();
