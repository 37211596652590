/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { Breadcrumbs as MaterialBreadCrumbs } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';
import { isDefined } from '../../utils/isDefined';

import mapBreadcrumbsItems from './actions/mapBreadcrumbsItems';
import withSettings from '../../utils/withSettings';

export function BreadCrumbs({ location, intl, classes, user, settings }) {
  const items = mapBreadcrumbsItems(location, user);

  return (
    <MaterialBreadCrumbs className={classes.root}>
      <Link to="/">
        <i
          className={clsx('fas fa-home-alt', classes.link)}
          style={{ color: settings.colors.links }}
        />
      </Link>
      {items.map((item, i) => (
        <div key={i}>
          {isDefined(item.url) ? (
            <Link
              className={classes.link}
              style={{ color: settings.colors.links }}
              to={item.url}
            >
              {!item.isUserPage
                ? intl.formatMessage({ id: `MENU.${item.title}` })
                : item.title}
            </Link>
          ) : (
            <div
              className={classes.username}
              style={{ color: settings.colors.links }}
            >
              {item.title}
            </div>
          )}
        </div>
      ))}
    </MaterialBreadCrumbs>
  );
}

export default flowRight(
  injectIntl,
  withRouter,
  withSettings,
  withStyles(styles)
)(BreadCrumbs);
