import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import getFullTableData from '../../../utils/getFullTableData';

const getVacations = () => {
  return async dispatch => {
    const vacations = await getFullTableData(JSONAPI_URLS.VACATIONS, {});
    
    dispatch(reduxActions.setVacations(vacations));
  };
};

export default getVacations;
