import React, { useCallback, useEffect, useMemo, useState } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import { getExternalNoRanges } from '../actions/getExternalNoRanges';
import withDetailsView from '../../../utils/withDetailsView';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import tableColumns from '../components/tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import withUserCheck from '../../../utils/withUserCheck';
import {
  doesArrayHasLength,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import RangesDetails from '../components/RangesDetails';
import RangesTableHeader from '../components/RangesTableHeader';
import styles from '../styles';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { setRange } from '../../../modals/editRange/actions/setRange';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import { openRangesMultiedit } from '../../../modals/numberRangeMultiedit/actions/setRanges';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';

const NumbersRangesTable = ({
  getExternalNoRanges,
  externalNoRanges,
  intl,
  columns,
  setColumns,
  hasUSerPartner,
  networkOptions,
  connectClientId,
  mainSelected,
  userConnectId,
  classes,
  clientName,
  customJobs,
  setRange,
  openRangesMultiedit,
  client,
  isSuperAdmin,
  isNonClientAdminUser
}) => {
  const [getServiceOperator, setGetServiceOperator] = useState(null);
  const [getConnectRanges, setGetConnectRanges] = useState(null);
  const [filtering, setFiltering] = useState(false);

  useEffect(() => {
    if (isDefined(getServiceOperator) && isDefined(getConnectRanges)) {
      getExternalNoRanges({
        id: client,
        getServiceOperator,
        getConnectRanges,
        connectClientId
      });
    }

    // eslint-disable-next-line
  }, [client, getServiceOperator, getConnectRanges]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({
      hasUSerPartner,
      networkOptions,
      client,
      resetValues: {
        id: client,
        getServiceOperator,
        getConnectRanges,
        connectClientId
      }
    });
  }, [
    hasUSerPartner,
    networkOptions,
    client,
    getServiceOperator,
    getConnectRanges,
    connectClientId
  ]);

  useEffect(() => {
    const telepoRanges = columns.find(
      el =>
        el.title ===
        intl.formatMessage({
          id: 'NUMBER_RANGE.DOMAIN_RANGE'
        })
    );

    if (isDefined(telepoRanges) && !telepoRanges.hidden !== getConnectRanges) {
      setGetConnectRanges(!telepoRanges.hidden);
    }

    const serviceOperator = columns.find(
      el =>
        el.title ===
        intl.formatMessage({
          id: 'OPERATOR'
        })
    );

    if (hasUSerPartner) {
      setGetServiceOperator(false);
    } else if (
      isDefined(serviceOperator) &&
      !serviceOperator.hidden !== getServiceOperator
    ) {
      setGetServiceOperator(!serviceOperator.hidden);
    }

    // eslint-disable-next-line
  }, [columns, getConnectRanges]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'NumberRanges-1'
    });
  }, [setColumns, tableDefaultColumns]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'NumberRanges');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        },
        {
          icon: () => <AddButton buttonText="BUTTON.ADD" />,
          onClick: () =>
            setRange({
              range: {},
              resetValues: {
                id: client,
                getServiceOperator,
                getConnectRanges,
                connectClientId
              },
              isEdit: false
            }),
          isFreeAction: true,
          hidden:
            !can(
              'create',
              createSubject('ExternalNumberRange', {
                client_id: Number(client)
              })
            ) || isUndefined(client)
        }
      ];

      if (doesArrayHasLength(mappedJobs) && isDefined(client)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      if (isNonClientAdminUser) {
        buttons = [
          ...buttons,
          {
            tooltip: intl.formatMessage({ id: 'BUTTON.MULTIEDIT' }),
            icon: () => <CustomIconButton iconClassName="far fa-edit" />,
            onClick: (evt, data) =>
              openRangesMultiedit({
                ranges: data,
                resetValues: {
                  id: client,
                  getServiceOperator,
                  getConnectRanges,
                  connectClientId
                }
              })
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [
      filtering,
      customJobs,
      client,
      getServiceOperator,
      getConnectRanges,
      isNonClientAdminUser
    ]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ tableName: 'numberRanges', clientName }),
      filtering,
      selection: isNonClientAdminUser
    };
  }, [filtering, clientName, isNonClientAdminUser]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'NumberRanges-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  const detailPanel = [
    rowData => ({
      disabled: isUndefined(client),
      icon: () => (
        <i
          className="fas fa-chevron-right"
          style={{
            display: isUndefined(client) ? 'none' : '',
            fontSize: '12px'
          }}
        ></i>
      ),
      openIcon: () => (
        <i
          className="fas fa-chevron-down"
          style={{
            fontSize: '12px'
          }}
        ></i>
      ),
      render: () => <RangesDetails data={rowData} />
    })
  ];

  const dataToShow = useMemo(() => {
    switch (mainSelected) {
      case 'assigned': {
        return externalNoRanges.filter(number =>
          doesArrayHasLength(number.mitelData?.assigned)
        );
      }
      case 'unassigned': {
        return externalNoRanges.filter(number =>
          doesArrayHasLength(number.mitelData?.unassigned)
        );
      }
      case 'all': {
        return externalNoRanges;
      }
      default:
        return externalNoRanges;
    }
    // eslint-disable-next-line
  }, [mainSelected, externalNoRanges]);

  return (
    <div>
      <MaterialTable
        components={{
          Toolbar: props => (
            <MTableToolbar
              classes={{
                title: classes.toolbarTitle,
                spacer: classes.toolbarSpacer,
                actions: classes.toolbarActions,
                highlight: classes.toolbarHighlight
              }}
              {...props}
            />
          )
        }}
        title={<RangesTableHeader showSelector={isDefined(client)} />}
        icons={customTableIcons}
        actions={actions}
        options={options}
        columns={columns}
        data={dataToShow}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
        detailPanel={
          isDefined(userConnectId) || isSuperAdmin ? detailPanel : null
        }
      />
    </div>
  );
};

const mapStatesToProps = ({ externalNoRanges, auth, selectClient, loader }) => {
  return {
    ...externalNoRanges,
    isLoading: loader.isSpinVisible,
    clientName: selectClient.selectedClient.name,
    connectClientId: selectClient.selectedClient.connect30_domain,
    userConnectId: auth.user.connect30_user_id,
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  getExternalNoRanges,
  setColumns: reduxActions.setColumns,
  resetExternalNoRanges: reduxActions.resetExternalNoRanges,
  setRange,
  openRangesMultiedit
};

export default flowRight(
  injectIntl,
  withDetailsView,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NumbersRangesTable);
