import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles, { CUSTOM_STYLES } from '../styles/mainSection';
import InputRow from '../../../partials/dataRows/InputRow';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

export function MainSection({ classes, form, isClientUser, clientId }) {
  const { values } = form;

  return (
    <div className={classes.root}>
      <InputRow
        label="CLIENT"
        name="clientName"
        disabled={true}
        customStyles={CUSTOM_STYLES.textFieldRow}
        {...form}
      />
      <InputRow
        label="NAME"
        name="name"
        infoIcon={true}
        infoIconTooltipIntlKey="DATA_LIMITS.EDIT_NAME_TOOLTIP"
        customStyles={CUSTOM_STYLES.textFieldRow}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'name'
          )
        }
        {...form}
      />
      <InputRow
        label="DATA_LIMITS.MAX_NO_FULL_TEXT"
        name="max_numbers"
        infoIcon={true}
        infoIconTooltipIntlKey="DATA_LIMITS.EDIT_MAX_NO_TOOLTIP"
        customStyles={CUSTOM_STYLES.textFieldRow}
        disabled={
          isClientUser ||
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'max_numbers'
          )
        }
        {...form}
      />
      {isClientUser && (
        <InputRow
          label="TABLE.MOBILE_NUMBER"
          name="numbers"
          customStyles={CUSTOM_STYLES.textFieldRow}
          disabled={true}
          isMultiline={true}
          maxRows={3}
          {...form}
        />
      )}
      {!isClientUser && (
        <CheckboxRow
          label="DATA_LIMITS.NO_DATALIMIT"
          name="no_limit"
          customStyles={CUSTOM_STYLES.checkboxRow}
          disabled={
            !can(
              'update',
              createSubject('DataLimit', {
                client_id: Number(clientId)
              }),
              'no_limit'
            )
          }
          {...form}
        />
      )}
      {!isClientUser && (
        <CheckboxRow
          label="DATA_LIMITS.UPDATE_NOW"
          name="update_block_limit_mb_now"
          infoIcon={true}
          infoIconTooltipIntlKey="DATA_LIMITS.UPDATE_NOW_TOOLTIP"
          customStyles={CUSTOM_STYLES.checkboxRow}
          disabled={
            !can(
              'update',
              createSubject('DataLimit', {
                client_id: Number(clientId)
              }),
              'update_block_limit_mb_now'
            )
          }
          {...form}
        />
      )}
      <InputRow
        labelText={`${translate('DATA_LIMITS.DATA_LIMIT')} GB`}
        name="block_limit_mb"
        disabled={
          values.no_limit ||
          isClientUser ||
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'block_limit_mb'
          )
        }
        customStyles={CUSTOM_STYLES.textFieldRow}
        {...form}
      />
      <InputRow
        labelText={`${translate('DATA_LIMITS.FAIR_USAGE_LIMIT')} GB`}
        name="fair_usage_limit"
        disabled={
          values.no_limit ||
          isClientUser ||
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'fair_usage_limit'
          )
        }
        customStyles={CUSTOM_STYLES.textFieldRow}
        {...form}
      />
      <InputRow
        labelText={`${translate('DATA_LIMITS.FUTURE_LIMIT')} GB`}
        name="future_limit"
        disabled={true}
        customStyles={{
          ...CUSTOM_STYLES.textFieldRow,
          root: {
            ...CUSTOM_STYLES.textFieldRow.root,
            visibility: values.update_block_limit_mb_now ? 'hidden' : ''
          }
        }}
        {...form}
      />
      <InputRow
        label="DATA_LIMITS.FUTUR_LIMIT_AT"
        name="future_limit_at"
        disabled={true}
        customStyles={{
          ...CUSTOM_STYLES.textFieldRow,
          root: {
            ...CUSTOM_STYLES.textFieldRow.root,
            visibility: values.update_block_limit_mb_now ? 'hidden' : ''
          }
        }}
        {...form}
      />
    </div>
  );
}

export default flowRight(withUserCheck, withStyles(styles))(MainSection);
