import React from 'react';
import formatDate from '../../../utils/formatDate';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import searchNumbers from '../../../utils/searchNumbers';

import styles from '../styles';
import TableButtonsColumn from './TableButtonsColumn';
import CONST from '../../../consts/selectsOptions';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function tableColumns() {
  const columns = [
    {
      render: rowData => <TableButtonsColumn data={rowData} />,
      cellStyle: styles.actionCell,
      disableClick: true,
      width: '40px'
    },
    {
      title: 'TABLE.PARTNER_NAME',
      field: 'partner.name',
      //sort disabled because there is a issue on BE side
      sorting: false,
      filtering: false
    },
    {
      title: 'TABLE.NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: 'CLIENT.DISPLAY_NAME',
      field: 'metadata.display_name',
      sorting: false,
      filtering: false
    },
    {
      title: 'TABLE.NOTES',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.notes, term);
      },
      field: 'notes'
    },
    {
      title: 'TABLE.PRIMARY_CONTACT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.primary_contact, term);
      },
      field: 'primary_contact'
    },
    {
      title: 'EDIT.PLATFORM',
      render: rowData => mapRouting(rowData.csbc_routing),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.csbc_routing, term);
      },
      field: 'csbc_routing'
    },
    {
      title: 'TABLE.CONNECT30_DOMAIN',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.connect30_domain, term);
      },
      field: 'connect30_domain'
    },
    {
      title: 'TABLE.ADMIN_CONTACT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.admin_contact, term);
      },
      field: 'admin_contact'
    },
    {
      title: 'TABLE.ADMIN_EMAIL',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.admin_email, term);
      },
      field: 'admin_email'
    },
    {
      title: 'TABLE.ADMIN_PHONE',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.admin_phone, term);
      },
      field: 'admin_phone'
    },
    {
      title: 'TABLE.COMMENT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.comment, term);
      },
      field: 'comment'
    },
    {
      title: 'TABLE.VIP_STATUS',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.vip_status, term);
      },
      field: 'vip_status'
    },
    {
      title: 'UUID',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.uuid, term);
      },
      field: 'uuid',
      untranslatedTitle: true
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    },
    {
      title: 'ID',
      field: 'id',
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
}

function mapRouting(routing) {
  const mappedRouting = CONST.PLATFORM_OPTIONS.find(
    option => option.value === routing
  );

  if (isDefined(mappedRouting)) {
    return Optional(mappedRouting.label).or(routing);
  }

  return routing;
}
