import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import {
  mapTableOptions,
  setLocalization,
  TABLE_OPTIONS
} from '../../../consts/tableOptions';
import Optional from '../../../utils/optional';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import { setUser } from '../../../modals/editUser/actions/setUser';
import tableColumns from './tableColumnsBE';
import { ROLES_GROUPS } from '../../../consts/rolesIds';
import compareRolesInArray from '../../../utils/compareRolesInArray';
import {
  doesArrayHasLength,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import { lockUserAction } from '../actions/lockUser';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import { withRouter } from 'react-router-dom';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import fetchUsersPaginate from '../actions/fetchUsersPaginate';
import withSettings from '../../../utils/withSettings';

export function UsersTableBE({
  intl,
  openEditUser,
  client,
  isLoading,
  userRole,
  isDomainProvided,
  columns,
  setColumns,
  lockUserAction,
  clientName,
  customJobs,
  location,
  refresh,
  setRefresh,
  settings
}) {
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DEFAULT.pageSize);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ client, lockUserAction, settings });
  }, [client, lockUserAction, settings]);

  const tableRef = useRef();

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Users-Admin-1'
    });
  }, [setColumns, tableDefaultColumns, client]);

  useEffect(() => {
    if (refresh) {
      tableRef.current.onQueryChange();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    tableRef.current.onQueryChange();
    // eslint-disable-next-line
  }, [client]);

  const fetchData = useCallback(
    query => {
      setPageSize(query.pageSize);
      return fetchUsersPaginate({ query, client });
      // eslint-disable-next-line
    },
    [setPageSize, client]
  );

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Users');
  }, [customJobs]);

  const isAdminMenu = useMemo(() => isUndefined(getIdFromUrl(location)), [
    location
  ]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <AddButton buttonText={'BUTTON.NEW_USER'} />,
          onClick: () => openEditUser({ clientId: client, isDomainProvided }),
          isFreeAction: true,
          hidden: compareRolesInArray(userRole, ROLES_GROUPS.CLIENT_USERS)
            ? true
            : false
        }
      ];

      if (doesArrayHasLength(mappedJobs) && !isAdminMenu) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [client, isDomainProvided, mappedJobs]
  );

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ clientName, tableName: 'users', exportData: false }),
      pageSize,
      maxColumnSort: 0
    };
  }, [clientName, pageSize]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Users-Admin-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MaterialTable
      title={
        <CustomTableTitle
          text="MENU.USERS"
          icon={<i className="fas fa-users-cog"></i>}
          isLoading={isLoading}
        ></CustomTableTitle>
      }
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={fetchData}
      options={tableOptions}
      localization={localization}
      tableRef={tableRef}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ users, auth, selectClient, loader }) => {
  return {
    ...users,
    isLoading: loader.isSpinVisible,
    clientId: Optional(selectClient.selectedClient)
      .map(client => client.id)
      .or(auth.defaultClient.id),
    userRole: auth.role,
    clientName: selectClient.selectedClient.name,
    isDomainProvided: isDefined(selectClient.selectedClient.connect30_domain),
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns,
  setRefresh: reduxActions.setRefresh,
  openEditUser: setUser,
  lockUserAction
};

export default flowRight(
  withRouter,
  withSettings,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(UsersTableBE);
