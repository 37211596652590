import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import styles from '../styles/rangesDetails';
import usePagination from '../../../utils/usePagination';
import CustomFormControlLabel from '../../../partials/customFormControlLabel';
import NumberDetails from './NumberDetails';

const PAGINATION_SIZE = 10;

function RangesDetails({ data, classes, intl, mainSelected }) {
  const isMobile = useMemo(() => data.number_type === 'mobile', [
    data.number_type
  ]);
  const [foundedNumbers, setFoundedNumbers] = useState('true');

  const dataToShow = data.mitelData?.filteredNumber
    ? {
        ...data.mitelData,
        all: filterNumbers(data.mitelData.all, foundedNumbers),
        assigned: filterNumbers(data.mitelData.assigned, foundedNumbers),
        unassigned: filterNumbers(data.mitelData.unassigned, foundedNumbers)
      }
    : data.mitelData;

  const [selected, setSelected] = useState('all');

  useEffect(() => {
    setPage(0);
    setSelected(mainSelected);
  }, [mainSelected]);

  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_SIZE);
  const [page, setPage] = useState(0);
  const paginated = usePagination(
    Optional(dataToShow)
      .map(data => data[selected])
      .or([]),
    page,
    rowsPerPage
  );

  const onRadioButtonChange = e => {
    setPage(0);
    setSelected(e.target.value);
  };

  const onRadioButtonAllRangeChange = e => {
    setFoundedNumbers(e.target.value);
  };

  const onChangePage = useCallback((e, page) => setPage(page), [setPage]);
  const onChangeRowsPerPage = useCallback(e => setRowsPerPage(e.target.value), [
    setRowsPerPage
  ]);

  return isDefined(dataToShow) ? (
    <div className={classes.root}>
      {dataToShow.all.length > 1 && mainSelected === 'all' && (
        <div className={classes.radioContainer}>
          <RadioGroup
            row
            className={classes.radioButtons}
            value={selected}
            onChange={onRadioButtonChange}
          >
            <CustomFormControlLabel
              value="all"
              label={intl.formatMessage({
                id: 'BUTTON.ALL'
              })}
            />
            <CustomFormControlLabel
              value="assigned"
              label={intl.formatMessage({
                id: 'ASSIGNED'
              })}
            />
            <CustomFormControlLabel
              value="unassigned"
              label={intl.formatMessage({
                id: 'UNASSIGNED'
              })}
            />
          </RadioGroup>
        </div>
      )}
      {isDefined(data?.mitelData?.filteredNumber) && (
        <div className={classes.radioContainer}>
          <RadioGroup
            row
            className={classes.radioButtons}
            value={foundedNumbers}
            onChange={onRadioButtonAllRangeChange}
          >
            <CustomFormControlLabel
              value="true"
              label={intl.formatMessage({
                id: 'DEFAULT'
              })}
            />
            <CustomFormControlLabel
              value="false"
              label={intl.formatMessage({
                id: 'NUMBER_RANGES.FULL_RANGE'
              })}
            />
          </RadioGroup>
        </div>
      )}
      <Table
        className={clsx(classes.tableRoot, {
          [classes.tableRootMobile]: isMobile
        })}
      >
        <TableHead>
          <TableRow>
          <TableCell className={classes.expandCell}>
            </TableCell>
            <TableCell className={classes.numberCell}>
              {intl.formatMessage({
                id: 'TABLE.NUMBER'
              })}
            </TableCell>
            <TableCell className={classes.functionCell}>
              {intl.formatMessage({
                id: 'FUNCTION'
              })}
            </TableCell>
            <TableCell className={classes.nameCell}>
              {intl.formatMessage({
                id: 'NAME'
              })}
            </TableCell>
            {isMobile && (
              <TableCell className={classes.nameCell}>
                {intl.formatMessage({
                  id: 'DESCRIPTION'
                })}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginated.map((data, i) => (
            <NumberDetails data={data} isMobile={isMobile} key={i} />
          ))}
        </TableBody>
      </Table>
      {dataToShow.all.length > PAGINATION_SIZE ? (
        <TablePagination
          className={classes.pagination}
          component="div"
          count={dataToShow[selected].length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={intl.formatMessage({
            id: 'TABLE.ROWS'
          })}
        />
      ) : null}
    </div>
  ) : (
    <div className={classes.noData}>
      {intl.formatMessage({
        id: 'NUMBERS.NO_DATA_TO_SHOW'
      })}
    </div>
  );
}

const mapStatesToProps = ({ externalNoRanges }) => {
  return {
    mainSelected: externalNoRanges.mainSelected
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(RangesDetails);

function filterNumbers(numbers, foundedNumbers) {
  return numbers.filter(number =>
    foundedNumbers === 'true' ? number.founded : true
  );
}
