import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';

import styles from '../styles/dataBox';
import withSettings from '../../../utils/withSettings';
import { isDefined } from '../../../utils/isDefined';

const DataBox = ({
  classes,
  label,
  value,
  linkAction = () => {},
  icon,
  settings,
  isDisabled = false,
  linkComponent = void 0
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.valueContainer}>
        <div>{label}</div>
        <div className={classes.value}>{value}</div>
      </div>
      <div className={classes.iconContainer}>
        {isDefined(linkComponent) ? (
          linkComponent
        ) : (
          <i
            className={clsx(icon, classes.icon, {
              [classes.disablePointer]: isDisabled
            })}
            style={{ color: !isDisabled ? settings.colors.links : '#F2F3F8' }}
            onClick={!isDisabled ? linkAction : () => {}}
          ></i>
        )}
      </div>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(DataBox);
