import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import flowRight from 'lodash.flowright';

import { LayoutSplashScreen } from '../../../_metronic';
import compareIds from '../../utils/compareIds';
import Optional from '../../utils/optional';
import { actions as selectClientReduxActions } from '../../partials/selectClient/reducers';
import SimCards from '../simCards/SimCards';
import Clients from '../clients/Clients';
import Users from '../users/Users';
import ExternalNumber from '../externalNumberRange/ExternalNumber';
import Addresses from '../addresses/Addresses';
import Roles from '../roles/Roles';
import Permissions from '../permissions/Permissions';
import Partners from '../partners/Partners';
import Queues from '../queues/Queues';
import User from '../user/User';
import DataLimits from '../dataLimits/DataLimits';
import TelephonyUsers from '../telephonyUsers/TelephonyUsers';
import UserSettings from '../userSettings/UserSettings';
import { isDefined } from '../../utils/isDefined';
import ClientDashboard from '../clientDashboard/ClientDashboard';
import withUserCheck from '../../utils/withUserCheck';
import Schedules from '../schedules/Schedules';
import AppSettings from '../appSettings/AppSettings';
import CheckNumber from '../checkNumber/CheckNumber';
import can from '../../utils/can';
import createSubject from '../../utils/createSubject';
import UserContacts from '../userContacts/UserContacts';
import RightPanel from '../../partials/rightPanel/RightPanel';
import Invoices from '../invoices/Invoices';
import EditClient from '../../modals/editClient/EditClient';
import CustomSettings from '../customSettings/CustomSettings';
import EditSchema from '../editSchema/EditSchema';
import EditCustomJobs from '../editCustomJobs/EditCustomJobs';
import Groups from '../groups/Groups';
import Subscriptions from '../subscriptions/Subscriptions';
import DatalimitsUsage from '../datalimitsUsage/DatalimitsUsage';
import ChangeLog from '../changeLog/ChangeLog';
import EditSimcard from '../../modals/editSimcard/EditSimcard';
import EditRange from '../../modals/editRange/EditRange';
import EditUser from '../../modals/editUser/EditUser';
import Licenses from '../licenses/Licenses';
import Connections from '../connections/Connections';
import PhoneBookRegistration from '../phoneBookRegistration/PhoneBookRegistration';
import Vacations from '../vacations/Vacations';
import EditClientForClientAdmin from '../../modals/editClientForClientAdmin/EditClientForClientAdmin';
import CdrHooks from '../cdrHooks/CdrHooks';

export function HomePage({
  match,
  clientsList,
  selectedClientId,
  defaultClient,
  setSelectedClient,
  history,
  isConnectIdProvided,
  isNonClientAdminUser,
  userHasQueues
}) {
  useEffect(() => {
    (async function() {
      if (!compareIds(match.params.id, selectedClientId)) {
        const choosenClient = clientsList.find(client =>
          compareIds(client.id, match.params.id)
        );
        if (!choosenClient) {
          if (match.params.id === 'admin') {
            history.push(`/admin/${match.params.page}`);
          } else if (match.params.id === 'user') {
            history.push(`/user/${match.params.page}`);
          } else {
            history.push(
              `/${defaultClient.id}/${Optional(match.params.page).or(
                'dashboard'
              )}`
            );
            setSelectedClient(defaultClient);
          }
        }
      }
    })();
    //eslint-disable-next-line
  }, [
    match.params.page,
    match.params.id,
    clientsList,
    history,
    selectedClientId
  ]);

  if (isNonClientAdminUser) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact from="/:id?" to="/:id?/dashboard" />}
          <Route exact path="/:id?/partners" component={Partners} />
          <Route path="/:id?/partners/clients" component={Clients} />
          <Route path="/:id?/partners/settings" component={AppSettings} />
          <Route path="/:id?/settings" component={AppSettings} />
          <Route path="/:id?/clients" component={Clients} />
          <Route path="/:id?/permissions" component={Permissions} />
          <Route path="/:id?/roles" component={Roles} />
          <Route path="/:id?/numberLookup" component={CheckNumber} />
          <Route path="/:id?/dashboard" component={ClientDashboard} />
          <Route path="/:id?/userProfile" component={User} />
          <Route path="/:id?/userContacts" component={UserContacts} />
          <Route exact path="/:id?/simCards" component={SimCards} />
          <Route path="/:id?/queues" component={Queues} />
          <Route path="/:id?/schedules" component={Schedules} />
          <Route exact path="/:id?/dataLimits" component={DataLimits} />
          <Route
            exact
            path="/:id?/dataLimits/usageTable"
            component={DatalimitsUsage}
          />
          <Route path="/:id?/:page?/user" component={User} />
          <Route exact path="/:id?/telephonyUsers" component={TelephonyUsers} />
          <Route exact path="/:id?/users" component={Users} />
          <Route path="/:id?/externalNumber" component={ExternalNumber} />
          <Route path="/:id?/addresses" component={Addresses} />
          <Route path="/:id?/userSettings" component={UserSettings} />
          <Route path="/:id?/invoices" component={Invoices} />
          <Route path="/:id?/appSettings" component={CustomSettings} />
          <Route path="/:id?/editSchema" component={EditSchema} />
          <Route path="/:id?/customJobs" component={EditCustomJobs} />
          <Route path="/:id?/groups" component={Groups} />
          <Route path="/:id?/changelog" component={ChangeLog} />
          <Route
            path="/:id?/phonebookRegistration"
            component={PhoneBookRegistration}
          />
          <Route path="/:id?/network_connections" component={Connections} />
          <Route path="/:id?/subscriptions" component={Subscriptions} />
          <Route path="/:id?/vacations" component={Vacations} />

          {can(
            'read',
            createSubject('Mitel::License', {
              client_id: selectedClientId
            })
          ) && <Route exact path="/:id?/licenses" component={Licenses} />}
          {can(
            'read',
            createSubject('Mitel::CdrHook', {
              client_id: selectedClientId
            })
          ) && <Route path="/:id?/cdrHooks" component={CdrHooks} />}
          <Route render={() => <Redirect to="/:id?" />} />
        </Switch>
        <RightPanel />
        <EditClient />
        <EditSimcard />
        <EditRange />
        <EditUser />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/:id?" to="/user/userProfile" />}
        {can('read', createSubject('Client', { id: selectedClientId })) && (
          <Route path="/:id?/dashboard" component={ClientDashboard} />
        )}
        <Route path="/:id?/userContacts" component={UserContacts} />
        <Route path="/:id?/userProfile" component={User} />
        {can(
          'read',
          createSubject('SimCard', { client_id: selectedClientId })
        ) && <Route exact path="/:id?/simCards" component={SimCards} />}
        {userHasQueues && <Route path="/:id?/queues" component={Queues} />}
        {can(
          'read',
          createSubject('Mitel::Schedule', { client_id: selectedClientId })
        ) && <Route path="/:id?/schedules" component={Schedules} />}
        {can(
          'read',
          createSubject('DataLimit', { client_id: selectedClientId })
        ) && <Route exact path="/:id?/dataLimits" component={DataLimits} />}
        {can(
          'read',
          createSubject('DataLimit', { client_id: selectedClientId })
        ) && (
          <Route
            exact
            path="/:id?/dataLimits/usageTable"
            component={DatalimitsUsage}
          />
        )}
        <Route path="/:id?/:page?/user" component={User} />
        {isConnectIdProvided &&
        can('read', createSubject('User', { client_id: selectedClientId })) ? (
          <Route exact path="/:id?/telephonyUsers" component={TelephonyUsers} />
        ) : (
          <Route exact path="/:id?/users" component={Users} />
        )}
        {can(
          'read',
          createSubject('ExternalNumberRange', { client_id: selectedClientId })
        ) && <Route path="/:id?/externalNumber" component={ExternalNumber} />}
        {can(
          'read',
          createSubject('Address', { client_id: selectedClientId })
        ) && <Route path="/:id?/addresses" component={Addresses} />}
        <Route path="/:id?/userSettings" component={UserSettings} />
        {can(
          'read',
          createSubject('Quote::Invoice', {
            client_id: selectedClientId
          })
        ) && <Route exact path="/:id?/invoices" component={Invoices} />}
        {can(
          'update',
          createSubject('Config', {
            client_id: selectedClientId
          })
        ) && (
          <Route exact path="/:id?/appSettings" component={CustomSettings} />
        )}
        {can(
          'read',
          createSubject('Mitel::UserGroup', {
            client_id: selectedClientId
          })
        ) && <Route path="/:id?/groups" component={Groups} />}
        {can(
          'read',
          createSubject('Quote::Subscription', {
            client_id: selectedClientId
          })
        ) && (
          <Route exact path="/:id?/subscriptions" component={Subscriptions} />
        )}
        {can(
          'read',
          createSubject('Mitel::License', {
            client_id: selectedClientId
          })
        ) && <Route exact path="/:id?/licenses" component={Licenses} />}
        {can(
          'read',
          createSubject('Quote::Connection', {
            client_id: selectedClientId
          })
        ) && <Route path="/:id?/network_connections" component={Connections} />}
        <Route path="/:id?/numberLookup" component={CheckNumber} />
        {can(
          'read',
          createSubject('Mitel::CdrHook', {
            client_id: selectedClientId
          })
        ) && <Route path="/:id?/cdrHooks" component={CdrHooks} />}
        <Route
          path="/:id?/phonebookRegistration"
          component={PhoneBookRegistration}
        />
        <Route render={() => <Redirect to="/:id?" />} />
      </Switch>
      <RightPanel />
      <EditClient />
      <EditSimcard />
      <EditRange />
      <EditUser />
      <EditClientForClientAdmin />
    </Suspense>
  );
}

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    clientsList: Optional(selectClient.selectClientsList).or([]),
    selectedClientId: Number(selectClient.selectedClient.id),
    defaultClient: auth.defaultClient,
    isConnectIdProvided: isDefined(auth.user.connect30_user_id),
    userHasQueues: auth.haveQueues
  };
};

const mapDispatchToProps = {
  setSelectedClient: selectClientReduxActions.setSelectedClient
};

export default flowRight(
  withRouter,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps)
)(HomePage);
