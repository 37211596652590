import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import clsx from 'clsx';

import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import styles, { customSelectStyles } from '../styles/selectAccount';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

function SelectAccount({
  selectOptions,
  selectedAccount,
  setSelectedAccount,
  classes,
  settings
}) {
  const onAccountChange = useCallback(
    selected => {
      if (selected.value !== selectedAccount.value) {
        setSelectedAccount(selected);
      }
    },
    // eslint-disable-next-line
    [selectedAccount.value]
  );

  const ValueContainer = props => {
    const { children } = props;
    return (
      <components.ValueContainer {...props}>
        <div className={classes.labelContainer}>
          <div style={{ color: settings.colors.primary }}>
            {`${Optional(children[0].props.data.name).or('')} - ${Optional(
              children[0].props.data.accountNo
            ).or('')}`}
          </div>
        </div>
      </components.ValueContainer>
    );
  };

  return selectOptions.length > 1 && Object.keys(selectedAccount).length > 0 ? (
    <Select
      styles={reactSelectDefaultStyles}
      customStyles={customSelectStyles}
      options={selectOptions}
      value={selectedAccount}
      onChange={onAccountChange}
      isRtl
      components={{ ValueContainer }}
    />
  ) : (
    <div className={clsx(classes.labelContainer, classes.onlyAccount)}>
      <div
        style={{ color: settings.colors.primary }}
        className={classes.accountNo}
      >
        {`${Optional(selectedAccount.accountNo).or('')} - 
        ${Optional(selectedAccount.name).or('')}`}
      </div>
    </div>
  );
}

const mapStatesToProps = ({ subscriptions }) => {
  return {
    selectOptions: subscriptions.selectOptions,
    selectedAccount: subscriptions.selectedAccount
  };
};

const mapDispatchToProps = {
  setSelectedAccount: reduxActions.setSelectedAccount
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SelectAccount);
