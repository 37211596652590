import moment from 'moment';
import { OUTSIDE_API } from '../../../consts/endpoints';
import {
  doesArrayHasLength,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import mapScheduleForUpdate from '../../editSchedule/actions/mapScheduleForUpdate';
import { mapMonth } from '../../editSchedule/actions/setSystemVactionsValues';
import { postData } from '../../../utils/http';
import { getSchedules } from '../../../pages/schedules/actions/getSchedules';
import { actions as reduxActions } from '../reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { EMPTY_SCHEDULE } from '../../../consts/initialValues';
import { translate } from '../../../i18n/I18nProvider';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';

const addSchedules = ({ values, schedules, connectClientId }) => {
  return async dispatch => {
    dispatch(loaderReduxActions.startSpinLoader());
    let summaryInfo = {};
    const schedulesArray = createSchedulesArray({
      values,
      schedules,
      connectClientId
    });

    for (const schedule of schedulesArray) {
      const { url, data, number, isNameExist } = schedule;
      try {
        if (isNameExist) {
          summaryInfo = updateSummaryInfo({
            summaryInfo,
            number,
            newElement: {
              text: `${translate('MESSAGE.CREATE_ERROR', {
                name: data.name
              })} : ${translate('MESSAGE.ALREADY_EXIST')}`,
              isError: true
            }
          });
        } else {
          const response = await postData({
            url,
            data
          });

          if (response?.status === RESPONSE_CODES.CREATED) {
            summaryInfo = updateSummaryInfo({
              summaryInfo,
              number,
              newElement: {
                text: translate('MESSAGE.CREATED.SCHEDULE', {
                  name: data.name
                })
              }
            });
          }
        }
      } catch (e) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          number,
          newElement: {
            text: translate('MESSAGE.CREATE_ERROR', {
              name: data.name
            }),
            isError: true
          }
        });
      }
    }
    dispatch(loaderReduxActions.stopSpinLoader());
    dispatch(reduxActions.openSummaryDialog(summaryInfo));

    dispatch(reduxActions.closeDialog());
    dispatch(getSchedules(connectClientId));
  };
};

export default addSchedules;

const createSchedulesArray = ({ values, schedules, connectClientId }) => {
  let mappedSchedules = schedules.reduce((arr, schedule) => {
    const url = `${OUTSIDE_API.CLIENTS}/${connectClientId}/schedules/${schedule.number}`;
    const { vacations, ...restValues } = values;

    if (doesArrayHasLength(vacations)) {
      const mappedVacations = vacations.map(vacation => {
        const isNameExist = isExistCheck({
          schedule,
          name: `_system_${vacation.holiday}`
        });

        if (isNameExist) {
          return {
            number: schedule.number,
            data: { name: `_system_${vacation.holiday}` },
            isNameExist: true
          };
        }

        const mappedVacation = mapVacation({
          schedule,
          vacation,
          values: restValues
        });

        return {
          url,
          data: mapScheduleForUpdate({ schedule: mappedVacation }),
          number: schedule.number
        };
      });

      return [...arr, ...mappedVacations];
    } else {
      const isNameExist = isExistCheck({
        schedule,
        name: values.name
      });

      if (isNameExist) {
        return [
          ...arr,
          {
            number: schedule.number,
            data: { name: values.name },
            isNameExist: true
          }
        ];
      }
      return [
        ...arr,
        {
          url,
          data: mapScheduleForUpdate({ schedule: restValues }),
          number: schedule.number
        }
      ];
    }
  }, []);

  return mappedSchedules;
};

const mapVacation = ({ vacation, values }) => {
  const { prompt, forwardNumber, type } = values;

  const year = new Date().getFullYear();

  const startDate = Number(
    moment(
      `${year}${mapMonth(vacation.month)}${
        vacation.date < 10 ? `0${vacation.date}` : vacation.date
      }`
    ).format('x')
  );

  const endDate = Number(
    moment(startDate)
      .add(1, 'd')
      .format('x')
  );

  return {
    ...EMPTY_SCHEDULE,
    prompt,
    forward_number: forwardNumber,
    type,
    name: `_system_${vacation.holiday}`,
    recurrenceType: vacation.holiday_type === 'Single' ? 'NONE' : `YEARLY`,
    startDate,
    endDate,
    startTime: -3600000,
    endTime: -3600000
  };
};

const isExistCheck = ({ schedule, name }) => {
  const { schedules } = schedule;
  if (isUndefined(schedules)) {
    return false;
  }

  return isDefined(schedules.find(el => el.name === name));
};

const updateSummaryInfo = ({ summaryInfo, number, newElement }) => {
  if (isUndefined(summaryInfo[number])) {
    return {
      ...summaryInfo,
      [number]: [newElement]
    };
  } else {
    return {
      ...summaryInfo,
      [number]: [...summaryInfo[number], newElement]
    };
  }
};
