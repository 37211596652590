import React from 'react';

import CustomSwitch from '../../../partials/CustomSwitch';
import styles, { customStyles } from '../styles';
import Optional from '../../../utils/optional';
import { mapCellNumber } from '../actions/tableColumnsActions';
import CustomCopyToClipboard from '../../../partials/copyToClipboard.js/CustomCopyToClipboard';
import searchNumbers from '../../../utils/searchNumbers';
import TableActionButtons from './TableActionButtons';
import { isUndefined } from 'lodash';
import PresenceDot from '../../../partials/presenceDot/PresenceDot';
import AvailabilityBox from '../../../partials/AvailabilityBox';
import UserPhoto from '../../../partials/userPhoto/UserPhoto';

export default function tableColumns({
  logUserActions,
  isAdminUser,
  connectDomain,
  isCurrentUserSelectedClientUser,
  userMitelId,
  userView = false
}) {
  return [
    {
      field: 'loggedIn',
      type: 'boolean',
      render: rowData => {
        const isAgent = Optional(rowData.roles)
          .map(roles => roles.find(role => role.type === 'AGENT'))
          .or(false);

        const isSupervisor = Optional(rowData.roles)
          .map(roles => roles.find(role => role.type === 'SUPERVISOR'))
          .or(false);

        const shouldShowSwitch = userView ? isAgent || isSupervisor : isAgent;

        if (userView && isUndefined(rowData.parentId)) {
          return (
            <UserPhoto
              id={rowData.connectId}
              initials={rowData.initials}
              customStyles={customStyles.photo}
            />
          );
        }

        return (
          isCurrentUserSelectedClientUser &&
          rowData.showAgents &&
          shouldShowSwitch && (
            <CustomSwitch
              checked={rowData.isLoggedIn}
              handleChange={event =>
                logUserActions({
                  checked: event.target.checked,
                  groupId: rowData.id,
                  userMitelId,
                  isAdminUser,
                  connectDomain,
                  isCurrentUserSelectedClientUser,
                  otherUserId: userView ? rowData.mitelUserId : void 0
                })
              }
            />
          )
        );
      },
      width: '80px',
      disableClick: true,
      cellStyle: styles.switchCell,
      id: 0
    },
    {
      title: 'QUEUES.NAME',
      field: 'name',
      customSort: (a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      },
      cellStyle: {
        minWidth: userView ? '215px' : '',
        maxWidth: '230px',
        width: '215px'
      },
      render: rowData => (
        <div style={{ fontWeight: isUndefined(rowData.parentId) ? 600 : '' }}>
          {rowData.name}
        </div>
      ),
      id: 1
    },
    {
      title: 'QUEUES.NUMBER',
      render: rowData => {
        return userView && isUndefined(rowData.parentId) ? (
          <div style={{ display: 'flex' }}>
            <PresenceDot
              actualAvailability={rowData.isAvailable}
              inCall={rowData.incall}
              customStyles={customStyles.presenceDot}
            />
            <AvailabilityBox
              available={rowData.isAvailable}
              text={rowData.activity}
              incall={rowData.incall}
            />
          </div>
        ) : (
          <CustomCopyToClipboard value={mapCellNumber(rowData.number)} />
        );
      },
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.number, term);
      },
      customSort: (a, b) => {
        return a.number > b.number ? 1 : -1;
      },
      cellStyle: { ...styles.centered, minWidth: '160px', width: '160px' },
      headerStyle: styles.centered,
      id: 2
    },
    {
      title: 'QUEUES.AVAILABLE',
      field: 'availableAgents',
      width: '110px',
      cellStyle: styles.centered,
      headerStyle: styles.centered,
      searchable: false,
      id: 3
    },
    {
      title: 'QUEUES.ENROLLED',
      field: 'loggedInAgents',
      width: '110px',
      cellStyle: styles.centered,
      headerStyle: styles.centered,
      searchable: false,
      id: 4
    },
    {
      title: 'QUEUES.QUEUE_LENGTH',
      field: 'queueLength',
      width: '110px',
      cellStyle: styles.centered,
      headerStyle: styles.centered,
      searchable: false,
      id: 5
    },
    {
      title: 'QUEUES.LAST_WAIT',
      field: 'lastWait',
      width: '110px',
      cellStyle: styles.centered,
      headerStyle: styles.centered,
      searchable: false,
      id: 6
    },
    {
      title: 'QUEUES.AVG_WAIT',
      field: 'averageWait',
      width: '110px',
      cellStyle: styles.centered,
      headerStyle: styles.lowerHeader,
      searchable: false,
      id: 7
    },
    {
      title: 'QUEUES.LONGEST_QUEUE',
      field: 'longestWaiting',
      width: '110px',
      cellStyle: styles.centered,
      headerStyle: styles.lowerHeader,
      searchable: false,
      id: 8
    },
    {
      title: 'QUEUES.DROPPED_TO_TOTAL',
      field: 'droppedOnTotalCalls',
      width: '140px',
      cellStyle: styles.centered,
      headerStyle: styles.centered,
      searchable: false,
      id: 9
    },
    {
      title: 'QUEUES.STATS',
      render: rowData => (
        <div
          style={{
            visibility:
              userView && isUndefined(rowData.parentId) ? 'hidden' : ''
          }}
        >
          <TableActionButtons data={rowData} />
        </div>
      ),
      cellStyle: styles.actionCell,
      disableClick: true,
      sorting: false,
      filtering: false,
      searchable: false,
      align: 'center',
      width: '40px',
      id: 10
    }
  ];
}
