import moment from "moment";
import { isDefined } from "../../../utils/isDefined";

const setSystemVactionsValues = ({ vacations, values, setFieldValue }) => {
  if (isDefined(values.vacations)) {
    const founded = vacations.find(
      vacation => vacation.id === values.vacations
    );
    setFieldValue('name', `_system_${founded.holiday}`);
    setFieldValue(
      'recurrenceType',
      founded.holiday_type === 'Single' ? 'NONE' : `YEARLY`
    );
    const year = new Date().getFullYear();

    const startDate = Number(
      moment(
        `${year}${mapMonth(founded.month)}${
          founded.date < 10 ? `0${founded.date}` : founded.date
        }`
      ).format('x')
    );

    const endDate = Number(
      moment(startDate)
        .add(1, 'd')
        .format('x')
    );

    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
    setFieldValue('endTime', -3600000);
    setFieldValue('startTime', -3600000);
  }
};

export default setSystemVactionsValues;

export const mapMonth = month => {
    const months = {
      jan: '01',
      feb: '02',
      mar: '03',
      apr: '04',
      may: '05',
      jun: '06',
      jul: '07',
      aug: '08',
      sep: '09',
      oct: '10',
      nov: '11',
      dec: '12'
    };
  
    return months[month];
  };
  