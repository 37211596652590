import store from '../store/store';
import { actions as changeClientReduxActions } from '../partials/selectClient/reducers';
import checkIfSimcardsShouldBeShown from './checkIfSimcardsShouldBeShown';

const changeClient = async ({ client }) => {
 const hasCientSimcards = await checkIfSimcardsShouldBeShown(client.id)
  return store.dispatch(
    changeClientReduxActions.setSelectedClient({
      ...client,
      hasCientSimcards
    })
  );
};

export default changeClient;

