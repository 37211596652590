import { translate } from '../i18n/I18nProvider';

export default function mapProvidersOptions() {
  return [
    { value: '', label: '' },
    {
      value: 'TDC NET',
      label: `TDC ${translate('TABLE.NETWORK').toLowerCase()}`
    },
    {
      value: 'Telenor net',
      label: `Telenor ${translate('TABLE.NETWORK').toLowerCase()}`
    }
  ];
}
