import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import DatepickerRow from '../../partials/dataRows/DatepickerRow';
import SelectRow from '../../partials/dataRows/SelectRow';
import ActionsButtons from './components/ActionsButtons';
import { updateOnSubmit } from './actions/updateRange';
import validateSchema from './actions/validateSchema';
import InputRow from '../../partials/dataRows/InputRow';
import { isDefined } from '../../utils/isDefined';
import createSubject from '../../utils/createSubject';
import can from '../../utils/can';
import Optional from '../../utils/optional';

const EditRange = ({
  classes,
  open,
  closeEditRange,
  intl,
  range = {},
  selectOptions,
  updateOnSubmit,
  resetValues,
  isEdit,
  clientId,
  lookupNumber,
  fromRightPanel,
  fromLookup,
  rangeId,
  isBeTable,
  fromPhoneBook
}) => {
  const onSubmit = useCallback(
    values => {
      updateOnSubmit({
        range: values,
        oldAddress: range.address,
        resetValues,
        isEdit,
        lookupNumber,
        fromRightPanel,
        fromLookup,
        rangeId,
        isBeTable,
        fromPhoneBook
      });
    },
    // eslint-disable-next-line
    [updateOnSubmit, range, isBeTable]
  );

  const title = useMemo(() => {
    const { range_up_to_incl, range_from } = range;

    return isEdit
      ? `${intl.formatMessage({
          id: 'TOOLTIP.EDIT_RANGE'
        })} : ${range_from} ${
          range_up_to_incl !== range_from ? `- ${range_up_to_incl}` : ''
        }`
      : intl.formatMessage({
          id: 'EDIT_RANGE.ADD'
        });
    // eslint-disable-next-line
  }, [range, isEdit]);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEditRange();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeEditRange}>
        {title}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            ...range,
            address: isDefined(range.address) ? range.address : '',
            och_active_at: isEdit ? range.och_active_at : new Date()
          }}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={validateSchema({ isEdit })}
        >
          {({
            handleSubmit,
            setFieldValue,
            errors,
            values,
            handleBlur,
            handleChange,
            dirty
          }) => {
            const fieldProps = {
              errors,
              values,
              handleBlur,
              handleChange,
              setFieldValue
            };
            return (
              <Form onSubmit={handleSubmit}>
                {!isEdit && (
                  <InputRow
                    name="range_from"
                    label="TABLE.RANGE_FROM"
                    disabled={
                      !can(
                        'update',
                        createSubject('ExternalNumberRange', {
                          client_id: Number(clientId)
                        }),
                        'range_from'
                      )
                    }
                    {...fieldProps}
                  />
                )}
                {!isEdit && (
                  <InputRow
                    name="range_up_to_incl"
                    label="TABLE.RANGE_TO"
                    disabled={
                      !can(
                        'update',
                        createSubject('ExternalNumberRange', {
                          client_id: Number(clientId)
                        }),
                        'range_up_to_incl'
                      )
                    }
                    {...fieldProps}
                  />
                )}
                {!isEdit && (
                  <SelectRow
                    name="number_type"
                    label="TABLE.TYPE"
                    isEditModal={false}
                    options={selectOptions.numberType}
                    disabled={
                      !can(
                        'update',
                        createSubject('ExternalNumberRange', {
                          client_id: Number(clientId)
                        }),
                        'number_type'
                      )
                    }
                    {...fieldProps}
                  />
                )}
                <DatepickerRow
                  label="EDIT_RANGE.ACTIVE_AT"
                  name="och_active_at"
                  disablePast={isEdit ? false : true}
                  showOnlyAvailable={false}
                  disabled={
                    !can(
                      'update',
                      createSubject('ExternalNumberRange', {
                        client_id: Number(clientId)
                      }),
                      'och_active_at'
                    )
                  }
                  {...fieldProps}
                />
                <DatepickerRow
                  label="EDIT_RANGE.INACTIVE_AT"
                  name="och_inactive_at"
                  disablePast={isEdit ? false : true}
                  showOnlyAvailable={false}
                  disabled={
                    !can(
                      'update',
                      createSubject('ExternalNumberRange', {
                        client_id: Number(clientId)
                      }),
                      'och_inactive_at'
                    )
                  }
                  {...fieldProps}
                />
                <SelectRow
                  name="network"
                  label="TABLE.NETWORK"
                  isEditModal={false}
                  options={selectOptions.providers}
                  isDisabled={
                    !can(
                      'update',
                      createSubject('ExternalNumberRange', {
                        client_id: Number(clientId)
                      }),
                      'network'
                    )
                  }
                  {...fieldProps}
                />
                <SelectRow
                  name="csbc_routing"
                  label="EDIT.PLATFORM"
                  isEditModal={false}
                  options={selectOptions.platform}
                  isDisabled={
                    !can(
                      'update',
                      createSubject('ExternalNumberRange', {
                        client_id: Number(clientId)
                      }),
                      'csbc_routing'
                    )
                  }
                  {...fieldProps}
                />
                <SelectRow
                  name="status"
                  label="STATUS"
                  isEditModal={false}
                  options={selectOptions.status}
                  isDisabled={
                    !can(
                      'update',
                      createSubject('ExternalNumberRange', {
                        client_id: Number(clientId)
                      }),
                      'status'
                    )
                  }
                  {...fieldProps}
                />
                {isEdit && (
                  <SelectRow
                    name="future_status"
                    label="FUTURE_STATUS"
                    isEditModal={false}
                    options={selectOptions.futureStatus}
                    isDisabled={
                      !can(
                        'update',
                        createSubject('ExternalNumberRange', {
                          client_id: Number(clientId)
                        }),
                        'future_status'
                      )
                    }
                    {...fieldProps}
                  />
                )}
                <InputRow
                  name="description"
                  label="DESCRIPTION"
                  disabled={
                    !can(
                      'update',
                      createSubject('ExternalNumberRange', {
                        client_id: Number(clientId)
                      }),
                      'description'
                    )
                  }
                  {...fieldProps}
                />
                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={closeEditRange}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ editRange, selectClient }) => {
  return {
    ...editRange,
    clientId: Optional(editRange.clientId).or(selectClient.selectedClient.id)
  };
};

const mapDispatchToProps = {
  closeEditRange: reduxActions.closeEditRange,
  updateOnSubmit
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditRange);
