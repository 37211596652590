import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_VACATIONS = 'SET_VACATIONS';
const RESET_VACATIONS = 'RESET_VACATIONS';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('VACATIONS', [
  SET_VACATIONS,
  RESET_VACATIONS,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  vacations: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_VACATIONS]: {
      return { ...state, vacations: [...action.vacations] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_VACATIONS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setVacations: vacations => ({
    type: actionTypes[SET_VACATIONS],
    vacations
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetVacations: () => ({ type: actionTypes[RESET_VACATIONS] })
};
