import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { JSONAPI_URLS, OUTSIDE_API } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_EXTERNAL_NO_RANGES } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import createNetworkOptions from './createNetworkOptions';
import mapMitelData from './mapMitelData';
import { isDefined } from '../../../utils/isDefined';
import getFullTableData from '../../../utils/getFullTableData';

export function getExternalNoRanges({
  id,
  getServiceOperator,
  getConnectRanges,
  connectClientId
}) {
  return {
    type: sagaActions[GET_EXTERNAL_NO_RANGES],
    id,
    getServiceOperator,
    getConnectRanges,
    connectClientId
  };
}

export function* fetchExternalNoRanges({
  id,
  getConnectRanges = false,
  getServiceOperator = false,
  connectClientId
}) {
  yield put(loaderReducActions.startSpinLoader());
  const QUERY_PARAMS = yield call(mapQueryParams, {
    id,
    getServiceOperator,
    getConnectRanges
  });

  const mitelQueryParam = {
    include_usage: true,
    paginate: false
  };

  let externalNo = yield call(
    getFullTableData,
    JSONAPI_URLS.EXTERNAL_NO_RANGES,
    QUERY_PARAMS
  );

  const simCardsQueryParams = {
    include: ['owner', 'primary_card', 'primary_card.owner'],
    fields: {
      sim_cards: ['card_type', 'msisdn', 'description'],
      users: 'name'
    },
    filter: { client_id: isDefined(id) ? { eq: id } : { gt: 0 } }
  };

  const simCards = yield call(
    getFullTableData,
    JSONAPI_URLS.SIM_CARDS,
    simCardsQueryParams
  );

  try {
    const response =
      isDefined(connectClientId) && isDefined(id)
        ? yield call(
            getData,
            urlCreator(
              `${OUTSIDE_API.CLIENTS}/${connectClientId}/number_ranges`,
              mitelQueryParam
            )
          )
        : [];

    const mappedNumberRanges = yield call(mapMitelData, {
      response,
      externalNo,
      simCards
    });

    externalNo = mappedNumberRanges;
  } catch {}

  const networkOptions = yield call(createNetworkOptions, externalNo);

  yield put(reduxActions.setExternalNoRanges(externalNo, networkOptions));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetExternalNoRanges() {
  yield takeLatest(sagaActions[GET_EXTERNAL_NO_RANGES], fetchExternalNoRanges);
}

export default watchGetExternalNoRanges();

export function mapQueryParams({ id, getServiceOperator, getConnectRanges }) {
  let external_number_ranges = [];

  if (getServiceOperator) {
    external_number_ranges = [...external_number_ranges, 'service_operator'];
  }
  if (getConnectRanges) {
    external_number_ranges = [
      'connect30_number_ranges',
      ...external_number_ranges
    ];
  }
  return {
    include: ['client', 'provider', 'address', 'phone_book_registrations'],
    fields: { client: 'name' },
    extra_fields: {
      external_number_ranges
    },
    filter: { client_id: isDefined(id) ? { eq: id } : { gt: 0 } }
  };
}
