import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { getUsers } from './actions/getUsers';
import UsersTable from './components/UsersTable';
import getIdFromUrl from '../../utils/getIdfromUrl';
import RequestUserWizard from '../../modals/requestUserWizard/RequestUserWizard';
import { actions as reduxActions } from './reducers';
import getCustomJobs from '../../utils/getCustomJobs';

function TelephonyUsers({ getUsers, location, connectClientId, resetUsers }) {
  const clientId = getIdFromUrl(location);

  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();
    getUsers(connectClientId, clientId);
    return resetUsers;
    // eslint-disable-next-line
  }, [connectClientId]);

  return (
    <>
      <UsersTable client={clientId} />
      <RequestUserWizard />
      
    </>
  );
}

const mapStatesToProps = ({ auth, selectClient }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  getUsers,
  resetUsers: reduxActions.resetUsers
};

export default flowRight(
  withRouter,
  connect(mapStatesToProps, mapDispatchToProps)
)(TelephonyUsers);
