import React, { useEffect } from 'react';
import LicensesTable from './components/LicensesTable';
import { getLicenses } from './actions/getLicenses';
import { connect } from 'react-redux';

const Licenses = ({ connectClientId, getLicenses }) => {
  useEffect(() => {
    getLicenses(connectClientId);
    // eslint-disable-next-line
  }, [connectClientId]);

  return (
    <div>
      <LicensesTable />
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  getLicenses
};

export default connect(mapStatesToProps, mapDispatchToProps)(Licenses);
